import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string }, currentTarget: { name: string; value: string } }) => void;
  name: string;
}

export const Telephone = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+000 000 000 000"
        definitions={{ '#': /[1-9]/ }}
        inputRef={ref as React.RefCallback<HTMLTextAreaElement | HTMLInputElement>}
        onAccept={(value: any) => onChange({
          currentTarget: {
            name: props.name, value,
          },
          target: {
            name: props.name, value,
          },
        })}
        overwrite
      />
    );
  },
);