import {
  CustomPaging,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  AddRounded, Check, Clear, Close, Search,
} from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  IconButton,
  InputAdornment,
  Link,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {
  Table as ResponsiveTable, Tbody, Td, Th, Thead, Tr,
} from 'react-super-responsive-table';
import { ActionFormatter, ActionTypeProvider } from '../../../Grid/ActionTypeProvider';
import { BoolTypeProvider } from '../../../Grid/BoolTypeProvider';
import { DateFormatter, DateTypeProvider } from '../../../Grid/DateTypeProvider';
import { pagingPanelMessages, tableHeaderMessages } from '../../../Grid/Localization';
import { PriceFormatter, PriceTypeProvider } from '../../../Grid/PriceTypeProvider';
import { useKeycloak } from '@react-keycloak/web';
import theme from '../../../theme';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Helmet } from 'react-helmet';
import useOrganizations from '../../../Hooks/useOrganizations';
import useSupplies from '../../../Hooks/useSupplies';
import { PlaceTypeProvider } from '../../../Grid/PlaceTypeProvider';
import { PlaceIdToAddress } from '../../../PlaceIdToAddress';
import { PromiseValue } from '../../../PromiseValue';

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ search, setSearch ] = React.useState('');
  const { keycloak } = useKeycloak();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(isMobile ? 5 : 10);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  const pageSizes = [5, 10, 15, 25, 50];

  const filters = React.useMemo(() => {
    return search.length > 0 ? ['title='+search, 'public=all'] : ['public=all'];
  }, [search]);
  const { data: auctions, loading, total  } = useSupplies({
    page, pageSize, filters, ownOrganizationOnly: true,
  });
  const { data: partners } = useOrganizations({
    pageSize: 100, page: null,
  });

  const columns = React.useMemo(() => {
    const list = [
      {
        name: 'title', title: 'Název', width: 300,
      },
      {
        name: 'streetCode', title: 'Adresa', width: 295,
      },
      {
        name: 'objectType', title: 'Typ', width: 125, getCellValue: (_row: any) => _row.objectType.label,
      },
      {
        name:         'startingAmount',
        title:        'Počáteční cena',
        width:        130,
        getCellValue: (_row: any) => {
          return {
            amount:   _row.auction.startingAmount,
            currency: _row.currency.label,
          };
        },
      },
      {
        name:         'currentAmount',
        title:        'Aktuální cena',
        width:        130,
        getCellValue: (_row: any) => {
          return {
            amount:   _row.auction.currentAmount,
            currency: _row.currency.label,
          };
        },
      },
      {
        name:         'startTimestamp',
        title:        'Začátek',
        width:        100,
        getCellValue: (_row: any) => {
          return _row.auction.startTimestamp;
        },
      },
      {
        name:         'endTimestamp',
        title:        'Konec',
        width:        100,
        getCellValue: (_row: any) => {
          return _row.auction.endTimestamp;
        },
      },
      {
        name: 'isPublic', title: 'Veřejná aukce', width: 90, align: 'center',
      },
      // {
      //   name: 'contractType', title: 'Typ obchodní nabídky', width: 110, getCellValue: (_row: any) => _row.contractType.label,
      // },
      {
        name: 'actions', title: 'Akce', width: 80, getCellValue: (_row: any) => _row,
      },
    ];

    if (keycloak.hasResourceRole('modifySupplyOrganization')) {
      list.splice(0, 1, {
        name: 'title', title: 'Název', width: 180,
      });
      list.splice(1, 0, {
        name: 'organizationUuid', title: 'Zadavatel', width: 120, getCellValue: (_row: any) => partners.find(o => o.uuid === _row.organizationUuid)?.title,
      });
    }
    return list;
  }, [keycloak.authenticated, partners]);

  const GenericFormatter = React.useCallback((props: { attr: string, value: string | boolean | string[], item: typeof auctions[number] }) => {
    if (['isPublic'].includes(props.attr)) {
      return props.value ? <Check sx={{ fontSize: '3vw' }}/> : <Clear sx={{ fontSize: '3vw' }}/>;
    } else if (['startTimestamp', 'endTimestamp'].includes(props.attr)) {
      return DateFormatter({ value: props.item.auction[props.attr as keyof typeof props.item.auction] as string });
    } else if (['objectType'].includes(props.attr)) {
      return <>{props.item.objectType.label}</>;
    } else if (['contractType'].includes(props.attr)) {
      return <>{props.item.contractType.label}</>;
    } else if (['organizationUuid'].includes(props.attr)) {
      return <>{partners.find(o => o.uuid === props.value)?.title || 'neexistuje'}</>;
    } else if (['streetCode'].includes(props.attr)) {
      if (String(props.value).length > 0) {
        return <PromiseValue promise={PlaceIdToAddress(props.value as string)} key={String(props.value)} error='Došlo k chybě při překladu adresy'/>;
      }
      return <>&nbsp;</>;
    } else if (['actions'].includes(props.attr)) {
      return ActionFormatter({ value: props.item });
    } else if (['startingAmount'].includes(props.attr)) {
      return PriceFormatter({
        value: {
          amount:   props.item.auction.startingAmount,
          currency: props.item.currency.label,
        },
      });
    } else {
      return <>{props.value}</>;
    }
  }, [ partners ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Administrace - aukce - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1">
      { keycloak.hasResourceRole('showAdministrator')
        ? <>Administrace - <span>aukce</span></>
        : 'Správa nabídek'
      }
    </Typography>
    { loading === null
      ? <Alert severity='error' sx={{ mt: 5 }} variant='filled'>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
      : <>
        <Box sx={{ pt: 5 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={isMobile ? 2 : 0}>
            <TextField
              variant='outlined'
              value={search}
              sx={{ width: '400px' }}
              InputProps={{
                sx:             { '.MuiInputBase-input': { p: '7px 7px 7px 0px !important' } },
                startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                endAdornment:   <InputAdornment position="end">
                  <Fade in={search.length > 0}>
                    <IconButton onClick={() => {
                      setSearch('');
                      setPage(0);
                    }}>
                      <Close/>
                    </IconButton>
                  </Fade>
                </InputAdornment>,
              }}
              onChange={(ev) => {
                setSearch(ev.currentTarget.value);
                setPage(0);
              }}
            />
            <Link component={RouterLink} to='/administrace/aukce/create'>
              <Button variant='contained' color='secondary' sx={{
                borderRadius: '90px !important', height: isMobile ? 'inherit' : '50px', fontSize: '18px !important',
              }}>Přidat <AddRounded sx={{
                  fontSize: isMobile ? 'inherit' : '40px', ml: 2,
                }}/></Button>
            </Link>
          </Stack>
        </Box>
        <Box sx= {{ position: 'relative' }}>
          { isMobile
            ? <Box>
              <ResponsiveTable key={columns.length}>
                <Thead>
                  <Tr>
                    {columns.map(o => <Th key={o.title}>{o.title}</Th>)}
                  </Tr>
                </Thead>
                <Tbody>
                  {auctions.map(o => <Tr key={o.uuid}>
                    {columns.map(c => <Td aria-label={c.title} key={`${o.uuid}-${c.name}`}>
                      <GenericFormatter item={o} attr={c.name} value={o[c.name as keyof typeof o] as any}/>
                    </Td>)}
                  </Tr>
                  )}
                </Tbody>
              </ResponsiveTable>

              <Pagination sx={{
                pt:                    2,
                '& .MuiPagination-ul': {
                  p: '0 !important', justifyContent: 'center',
                },
                '& .MuiPaginationItem-page': { fontSize: '3vw !important' },
              }}  siblingCount={1} count={Math.ceil(Math.max(1, total / pageSize))} page={page + 1} onChange={handleChange} />
            </Box>
            : <Grid
              key={columns.length /* to forcefully refresh whole table dom */}
              rows={auctions}
              columns={columns}
            >
              <BoolTypeProvider
                for={['isPublic']}
              />
              <DateTypeProvider
                for={['startTimestamp', 'endTimestamp']}
              />
              <PlaceTypeProvider
                for={['streetCode']}
              />
              <PriceTypeProvider
                for={['startingAmount', 'currentAmount']}
              />
              <ActionTypeProvider
                for={['actions']}
              />
              <SortingState
                columnExtensions={[{
                  columnName: 'actions', sortingEnabled: false,
                }]}
                defaultSorting={[{
                  columnName: 'startTimestamp', direction: 'desc',
                }]}
              />
              <IntegratedSorting/>
              <IntegratedFiltering />
              <PagingState
                currentPage={page}
                pageSize={pageSize}
                onCurrentPageChange={setPage}
                onPageSizeChange={setPageSize}
                defaultPageSize={10}
                defaultCurrentPage={0}
              />
              <CustomPaging
                totalCount={total}
              />
              <Table
                messages={{ noData: 'Žádné aukce' }}
                columnExtensions={columns.map(o => ({
                  columnName: o.name, wordWrapEnabled: true, align: (o.align as 'left' | 'right' | 'center') ?? 'left', getCellValue: o.getCellValue,
                }))}/>
              <TableColumnResizing defaultColumnWidths={columns.map(o => ({
                columnName: o.name, width: o.width,
              }))} />
              <TableHeaderRow showSortingControls  messages={tableHeaderMessages} />
              <PagingPanel
                messages={pagingPanelMessages}
                pageSizes={pageSizes}
              />
            </Grid>}
          <Backdrop open={loading}  sx={{
            position: 'absolute', backgroundColor: '#ffffffaa',
          }}>
            <CircularProgress/>
          </Backdrop>
        </Box>
      </>
    }
  </Container>
  );
}