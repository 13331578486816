import {
  Box, Container, Typography, useMediaQuery,
} from '@mui/material';

import KdoZaTimStoji from './KdoZaTimStoji';
import React from 'react';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

export default function PagesONas() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>O nás - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h2" component="h2"><span>O nás</span></Typography>

    <Box sx={{ textAlign: 'justify' }}>
      <Typography sx={{
        py: 2, fontSize: '12px',
      }}>Jsme česká startupová společnost INBIDO s.r.o., která se svým vlastním zkušeným IT a právním týmem vyvinula online aukční nástroj INBIDO.</Typography>
      <Typography sx={{
        py: 2, fontSize: '12px',
      }}>INBIDO je jednoduchý a účinný způsob pro prodej a pronájem nemovitostí určený realitkám, makléřům, developerům a obcím (krajům). Hlavním cílem je přinést transparentnost a férovost v oblasti prodeje a pronájmu nemovitostí a co nejvíce tak zjednodušit prodej a pronájem nemovitostí pomocí moderních technologií.</Typography>
      <Typography sx={{
        py: 2, fontSize: '12px',
      }}>INBIDO umožňuje velmi jednoduchým způsobem soutěžit mezi zájemci o nemovitost o to, kdo bude mít možnost vyjednávat o uzavření kupní či nájemní smlouvy nebo smlouvy o smlouvě budoucí. Nejedná se tedy o dražbu nemovitostí dle zákona o dražbách, ale pouze o nástroj pro nalezení toho, kdo má největší zájem o konkrétní nemovitost.</Typography>
    </Box>

    <KdoZaTimStoji/>
  </Container>
  );
}
