import {
  Container,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import React from 'react';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

export default function PagesKontakty() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
    px:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Kontakty - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Grid container sx={{ pb: 10 }}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2" component="h2" sx={{ pb: 4 }}><span>Kontakt</span></Typography>
        <Typography variant="h2" component="h2" sx={{ pb: 4 }}>INBIDO s.r.o.</Typography>

        <Typography>IČO 10667172</Typography>
        <Typography>Jurečkova 1811/18, Moravská Ostrava, 702 00 Ostrava</Typography>
        <Typography>Zapsaná u krajského soudu v Ostravě oddíl C, vl. 85115</Typography>

        <Typography sx={{ pt: 4 }}>E-mail: <Link href="mailto:info@inbido.cz" component='a'>info@inbido.cz</Link></Typography>
        <Typography >Telefon: <Link href="tel:+420 777 799 645" component='a'>+420 777 799 645</Link></Typography>
      </Grid>

      <Grid item xs={12} md={5} sx={{ pt: isMobile ? 3 : 0 }}>
        <iframe
          frameBorder={0}
          title='Jurečkova 1811/18, 702 00 Moravská Ostrava a Přívoz, Czechia'
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2573.401341911368!2d18.287097000000003!3d49.834916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713e33a7a23389f%3A0x4eda87851f2a1cd7!2sJure%C4%8Dkova%201811%2F18%2C%20702%2000%20Moravsk%C3%A1%20Ostrava%20a%20P%C5%99%C3%ADvoz%2C%20Czechia!5e0!3m2!1sen!2sus!4v1671629487850!5m2!1sen!2sus"
          width={isMobile ? '100%' : '520'}
          height="400"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </Grid>
    </Grid>
  </Container>
  );
}
