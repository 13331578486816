import useAPI, { OrganizationLite } from './useAPI';
import React from 'react';

type Props = {
  pageSize: number;
  page: number | null;
  filters?: string[]
};

const useOrganizations = (props: Props) => {
  const { getOrganizations } = useAPI();

  const [ data, setData ] = React.useState<OrganizationLite[]>([]);
  const [ pageCount, setPageCount ] = React.useState(1);
  const [ loading, setLoading ] = React.useState<boolean | null>(true);

  React.useEffect(() => {
    getOrganizations(props.pageSize, props.page ? props.page - 1 : null, props.filters ?? [])
      .then(v => {
        setPageCount(v.pageCount);
        setData(v.organizations);
        setLoading(false);
      });
  }, [props.pageSize, props.page, props.filters]);

  return {
    data, loading, pageCount,
  };
};

export default useOrganizations;
