import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import React from 'react';
import useContractTypes from '../../../Hooks/useContractTypes';
import useObjectTypes from '../../../Hooks/useObjectTypes';
import GoogleMapsInput from '../../../GoogleMapsInput';

type Props = {
  insolvency?: boolean,
  onFilterChange: (value: any) => void
};

export default function Filters(props: Props) {
  const { onFilterChange } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const contractTypes = useContractTypes();
  const [contractsTypesFilter, setContractsTypesFilter] = React.useState<number[]>([]);

  const objectTypes = useObjectTypes();
  const [objectsTypesFilter, setObjectsTypesFilter] = React.useState<number[]>([]);

  const [ mapCodes, setMapCodes ] = React.useState({
    zip:      '',
    city:     '',
    state:    '',
    street:   '',
    route:    '',
    province: '',
  });

  React.useEffect(() => {
    onFilterChange({
      'contract-type': contractsTypesFilter.sort(),
      'object-type':   objectsTypesFilter.sort(),
      ...mapCodes,
    });

  }, [ contractsTypesFilter, objectsTypesFilter, mapCodes ]);

  const handleChange
    = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const resetFilter = () => {
    setContractsTypesFilter([]);
    setObjectsTypesFilter([]);
    setMapCodes({
      zip:      '',
      city:     '',
      state:    '',
      street:   '',
      route:    '',
      province: '',
    });
  };

  return (<PopupState variant="popover" popupId="demo-popup-menu">
    {(popupState) => (
      <>
        <IconButton sx={{ my: 4 }}  {...bindTrigger(popupState)}>
          <TuneIcon sx={{ fontSize: '40px' }}/>
        </IconButton>
        <Popover
          {...bindPopover(popupState)}
          sx={{ '& .MuiPaper-root': { maxHeight: '75%' } }}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical:   'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{
            p: 2, minWidth: '400px',
          }}>
            {!props.insolvency && <Accordion expanded={expanded === 'offerType'} onChange={handleChange('offerType')}
              disableGutters
              elevation={0}
              square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="offerType-content"
                id="offerType-header"
              >
                <Typography>Typ obchodní nabídky</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <FormGroup>
                    {contractTypes.filter(o => o.id !== 3 /* hide insolvency */).map(o => <FormControlLabel
                      key={o.id}
                      control={
                        <Checkbox name={String(o.id)} checked={contractsTypesFilter.includes(o.id)} onChange={(_, checked) => setContractsTypesFilter(val => {
                          if (checked) {
                            return [...val, o.id];
                          } else {
                            return val.filter(v => v !== o.id);
                          }
                        })}/>
                      }
                      label={o.label}
                    />)}
                  </FormGroup>
                  <Button onClick={resetFilter}>Zrušit filtrování</Button>
                </Stack>
              </AccordionDetails>
            </Accordion>}
            <Accordion expanded={expanded === 'object-type'} onChange={handleChange('object-type')}
              disableGutters
              elevation={0}
              square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="object-type-content"
                id="object-type-header"
              >
                <Typography>Typ {props.insolvency ? 'majetku' : 'nemovitosti'}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <FormGroup>
                    {objectTypes
                      .filter(o => props.insolvency ? o.id >= 10 : o.id < 10)
                      .map(o => <FormControlLabel
                        key={o.id}
                        control={
                          <Checkbox name={String(o.id)} checked={objectsTypesFilter.includes(o.id)} onChange={(_, checked) => setObjectsTypesFilter(val => {
                            if (checked) {
                              return [...val, o.id];
                            } else {
                              return val.filter(v => v !== o.id);
                            }
                          })}/>
                        }
                        label={o.label}
                      />)}
                  </FormGroup>
                  <Button onClick={resetFilter}>Zrušit filtrování</Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
            {!props.insolvency && <Accordion expanded={expanded === 'city'} onChange={handleChange('city')}
              disableGutters
              elevation={0}
              square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="city-content"
                id="city-header"
              >
                <Typography>Adresa</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <GoogleMapsInput
                    types={[]}
                    search
                    label="Adresa"
                    onChange={(placeId, plusCode, description, address) => {
                      setMapCodes({
                        zip:      address.zip ?? '',
                        city:     address.city ?? '',
                        state:    address.country ?? '',
                        street:   address.street_address ?? '',
                        route:    address.street ?? '',
                        province: address.province ?? '',
                      });
                    }}
                  />
                  <Button onClick={resetFilter} sx={{ pt: 2 }}>Zrušit filtrování</Button>
                </Stack>
              </AccordionDetails>
            </Accordion>}
          </Box>
        </Popover>
      </>
    )}
  </PopupState>);
}