import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import React from 'react';
import { ReactNode } from 'react';
import { PlaceIdToAddress } from '../PlaceIdToAddress';
import { PromiseValue } from '../PromiseValue';

export const PlaceFormatter = ({ value }: { value: string }) => {
  if (value.length > 0) {
    return <PromiseValue promise={PlaceIdToAddress(value as string)} key={value} error='Došlo k chybě při překladu adresy'/>;
  } else {
    return <></>;
  }
};
export const PlaceTypeProvider = (props: JSX.IntrinsicAttributes & DataTypeProviderProps & { children?: ReactNode; }) => {
  return <DataTypeProvider
    formatterComponent={PlaceFormatter}
    {...props}
  />;
};