import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import React from 'react';
import { ReactNode } from 'react';
import { parseCurrencyAmount } from '../parseCurrencyAmount';

export const PriceFormatter = ({ value }: { value: {
  amount: number,
  currency: string;
} }) => {
  return <>{parseCurrencyAmount(value.amount, value.currency) }</>;
};
export const PriceTypeProvider = (props: JSX.IntrinsicAttributes & DataTypeProviderProps & { children?: ReactNode; }) => (
  <DataTypeProvider
    formatterComponent={PriceFormatter}
    {...props}
  />
);