import {
  Box,
  Button,
  Container, IconButton, Link, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import styles from './Header.module.css';
import Klokan from './Klokan.png';
import KlokanMobile from './KlokanMobile.png';
import Monitor from './Monitor.png';
import React, { useRef } from 'react';
import theme from '../../../theme';
import {
  PlayArrow, VolumeMute, VolumeUp,
} from '@mui/icons-material';

export default function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const video = useRef<HTMLVideoElement>(null);

  const [ muted, setMuted ] = React.useState(true);
  const [ play, setPlay ] = React.useState(false);

  return (
    <Container sx={{
      marginTop:     isMobile ? 0 : 1,
      paddingBottom: 1,
    }}>

      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid item xs={12} md={6} zIndex={2} className={styles.header}>
          <Typography variant="h1" component="h1">První aukční portál realit a podílů v České republice{' '}
            <span>sdružující</span> realitní kanceláře, developery a obce!</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} justifyContent='space-between'>
        <Grid item xs={9} sm={12} md={6} zIndex={2}sx={{ textAlign: 'justify' }}>
          <Typography variant="body1" sx={{
            pt: '20px', fontSize: '20px',
          }}>Připojte se k aukcím od našich prověřených zadavatelů a přihazujte ještě dnes.</Typography>
          <Typography variant="body1" sx={{
            pt: '20px', fontSize: '20px',
          }}>Stačí se <Link href='/registrace-ucastnika-aukce'>zaregistrovat</Link> a pak se odkudkoliv připojit k aukci nemovitosti o niž máte zájem.</Typography>
        </Grid>
        <Grid item xs={3} md={5} sx={{
          pt: '10px', position: 'relative',  minHeight: isMobile ? undefined : '240px',
        }}>
          {isMobile
            ? <img src={KlokanMobile} alt="" className={styles.klokanImage}/>
            : <Grid container sx={{
              height: '150px', marginTop: '-180px',
            }}>
              <Grid item xs={1} sx={{ position: 'relative' }}>
                <img src={Klokan} alt="" className={styles.klokanImage}/>
              </Grid>
              <Grid item xs={11} sx={{ position: 'relative' }}>
                <img src={Monitor} alt="" className={styles.monitorImage}/>
                <Box className={styles.bannerImage} sx={{ '& video': { width: '100%' } }}>
                  <video
                    ref={video}
                    autoPlay={true}
                    muted={true}
                    loop
                    controls={false}>
                    <source src={`/INBIDO_B_fp2.mp4`} type="video/mp4"/>
                  Your browser does not support the video tag.
                  </video>
                  <Button color='dark' variant='contained' sx={{
                    position: 'absolute',
                    right:    5,
                    opacity:  0.5,
                    bottom:   10,
                    px:       '5px !important',
                    minWidth: 'unset',
                  }} onClick={() => {
                    setMuted(!video.current!.muted);
                    video.current!.muted = !video.current!.muted;
                  }}>
                    {muted ? <VolumeMute/> : <VolumeUp/>}
                  </Button>
                </Box>
              </Grid>
            </Grid>}
        </Grid>
        {isMobile && <Grid item xs={12} sx={{
          pb: 2, textAlign: 'center', position: 'relative',
        }}>
          <Box className={styles.bannerImage} sx={{
            '& video': {
              width: '100%', height: '100%',
            },
          }}>
            <video
              preload="metadata"
              onClick={() => {
                if (video.current!.paused) {
                  video.current!.currentTime = 0;
                  video.current!.play();
                  setPlay(true);
                } else {
                  video.current!.pause();
                  setPlay(false);
                }
              }}
              ref={video}
              autoPlay={false}
              muted={true}
              loop
              controls={false}>
              <source src={`/INBIDO_B_fp2.mp4#t=3`} type="video/mp4"/>
          Your browser does not support the video tag.
            </video>
            { !play && <IconButton sx={{
              position:  'absolute',
              right:     '50%',
              transform: 'translate(50%, 50%)',
              bottom:    '50%',
              '& svg':   { fontSize: 'clamp(20px, 40vw, 200px)' },
            }} onClick={() => {
              setPlay(true);
              if (video.current!.paused) {
                video.current!.currentTime = 0;
                video.current!.play();
              }
            }}>
              <PlayArrow/>
            </IconButton> }

            <Button color='dark' variant='contained' sx={{
              position: 'absolute',
              right:    5,
              opacity:  0.5,
              bottom:   30,
              px:       '5px !important',
              minWidth: 'unset',
            }} onClick={() => {
              setMuted(!video.current!.muted);
              video.current!.muted = !video.current!.muted;
            }}>
              {muted ? <VolumeMute/> : <VolumeUp/>}
            </Button>
          </Box>
        </Grid>}
      </Grid>
    </Container>);
}