import {
  Button, Container, Link, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Header.module.css';
import Klokan from './Klokan.jpg';
import React from 'react';
import theme from '../../../theme';

export default function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container sx={{
      marginTop:     isMobile ? 0 : 1,
      paddingBottom: 1,
    }}>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid md={7} xs={12} zIndex={2}>
          <Typography variant="h1" component="h1" className={styles.header}>Co dělá klokan Bido na <strong>inbido?</strong></Typography>
          <Typography variant="h2" component="h2" className={styles.header}>Pomáhá Vám najít vysněné bydlení!</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} justifyContent='space-between' >
        <Grid xs={7} zIndex={2} md={6}>
          <Typography variant="body1" sx={{
            pt: isMobile ? '25px' : '50px', textAlign: 'justify',
          }}>Buďte <strong>největší zájemce</strong> ze všech a vyhrajte aukci <strong>posledním příhozem</strong>! Stačí si najit nemovitost k pronájmu, <strong>registrovat se</strong> a&nbsp;<strong>VYHRÁT</strong>!</Typography>

          <Link component={RouterLink} to={`/registrace-ucastnika-aukce`}>
            <Button variant="contained" color="secondary" sx={{
              width: '125px', height: isMobile ? '30px' : '40px',  mt: isMobile ? 3 : 5,
            }}>Registrace</Button>
          </Link>
        </Grid>
        <Grid xs={4} sx={{
          pt: '10px', position: 'relative', transform: 'scaleX(-1)',
        }}>
          <img src={Klokan} alt="" className={styles.klokan}/>
        </Grid>
      </Grid>
    </Container>);
}