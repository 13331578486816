import { Outlet } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import TopMenu from '../../TopMenu/TopMenu';
import CookieBar from '../../CookieBar/CookieBar';
import React from 'react';
import FooterMobile from '../../FooterMobile/FooterMobile';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';
import ScrollToTop from '../ScrollToTop';
import { useQuery } from '../../Hooks/useQuery';
import { useKeycloak } from '@react-keycloak/web';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';

export default function Root() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const params = useQuery();

  const keycloak = useKeycloak();

  // apply color customizations
  React.useEffect(() => {
    let bgcolor = params.get('bgcolor');
    let cardcolor = params.get('cardcolor');
    // we can apply background color directly
    if (bgcolor) {
      bgcolor = `#${bgcolor}`;
      document.getElementById('root')!.style.backgroundColor = bgcolor;
    }

    // we need to create new styling for this
    if (cardcolor) {
      cardcolor = `#${cardcolor}`;

      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      const css = `.AuctionCard_iframe a button {
        background: ${cardcolor} !important;
        background: linear-gradient(to bottom, ${cardcolor} 18%,${cardcolor} 18%,#ffffff 19%) !important;
        border: 2px solid ${cardcolor} !important;
        color: ${cardcolor} !important;
      }

      .AuctionDetail_iframe {
        background: white !important;
        border: 2px solid ${cardcolor} !important;
      }
      `;
      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  }, [ params ]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff' }}
        open={!keycloak.initialized}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {keycloak.initialized && <Fade>
        {params.get('iframe')
          ? <Outlet />
          : <>
            <ScrollToTop/>
            <TopMenu/>
            <div style={{ minHeight: isMobile ? 'calc(100vh - 49px - 67px)' : undefined }}>
              <Outlet />
            </div>
            { isMobile
              ? <FooterMobile/>
              : <Footer/>
            }
          </>}
      </Fade>
      }
      <CookieBar/>
    </>);
}