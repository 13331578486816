import {
  Box, Card, CardActionArea, CardMedia, Divider, Grid, Link, Skeleton, Typography
  ,
} from '@mui/material';

import DateIcon from './DateIcon.png';
import TimeIcon from './TimeIcon.png';
import parse from 'html-react-parser';

import useAPI, { SupplyLite } from '../Hooks/useAPI';
import styles from './AuctionCard.module.css';
import React from 'react';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '../Hooks/useQuery';
import useAuction from '../Hooks/useAuction';
import { PlaceIdToAddress } from '../PlaceIdToAddress';
import { PromiseValue } from '../PromiseValue';
import useOrganization from '../Hooks/useOrganization';
import Chip from '../Chips/Chip';
import { parseCurrencyAmount } from '../parseCurrencyAmount';

const AuctionCard: React.FC<{
  supply: SupplyLite, customerId?: string, address?: string, disableHover?: boolean, hideRemainingTime?: boolean,
}> = ({ supply, customerId, disableHover, hideRemainingTime }) => {
  const { getSupplyMultimedia } = useAPI();
  const params = useQuery();

  const { data: auction, loaded } = useAuction(supply.uuid, supply.currentAuctionUuid);
  const { data: organization } = useOrganization(supply.organizationUuid);

  /* Taken from original EndingAuctions.vue */
  const remainingTime = (ending_iso: string, start_iso: string) => {
    const ending_milisecs = (new Date(ending_iso)).getTime();
    const now_milisecs = (new Date()).getTime();
    const start_milisecs = (new Date(start_iso)).getTime();

    let remaining = Math.round( (ending_milisecs - now_milisecs) / 1000 );

    let days = Math.floor( remaining / (60 * 60 * 24) );
    let hours = Math.floor( (remaining / (60 * 60)) - 24*days );
    let minutes = Math.floor( (remaining / 60) - 60*hours - 24*60*days);
    // IMPROVED REMAINING TIME FORMAT
    let remainingFormatted = undefined;
    if (start_milisecs<=now_milisecs) {
      if ( (days<=0) && (hours<=0) && (minutes<=0) ) {
        remainingFormatted  = `Končí za <strong>méně než 1 minutu</strong>`;
      } else if ( (days<=0) && (hours<=0) ) {
        remainingFormatted = `Končí za <strong>${minutes}m</strong>`;
      } else if ( days<=0 ) {
        remainingFormatted = `Končí za <strong>${hours}h ${minutes}m</strong>`;
      } else {
        remainingFormatted = `Končí za <strong>${days}d ${hours}h ${minutes}m</strong>`;
      }

      if (remaining<=0) {
        remainingFormatted = 'Aukce skončila';
      }
    } else {
      remaining = Math.round( (start_milisecs - now_milisecs) / 1000 );
      days = Math.floor( remaining / (60 * 60 * 24) );
      hours = Math.floor( (remaining / (60 * 60)) - 24*days );
      minutes = Math.floor( (remaining / 60) - 60*hours - 24*60*days);
      if ( (days<=0) && (hours<=0) && (minutes<=0) ) {
        remainingFormatted  = `Začne za <strong>méně než 1 minutu</strong>`;
      } else if ( (days<=0) && (hours<=0) ) {
        remainingFormatted = `Začne za <strong>${minutes}m<strong>`;
      } else if ( days<=0 ) {
        remainingFormatted = `Začne za <strong>${hours}h ${minutes}m</strong>`;
      } else {
        remainingFormatted = `Začne za <strong>${days}d ${hours}h ${minutes}m</strong>`;
      }
    }
    return remainingFormatted;
  };

  const generatedParams = React.useMemo(() => {
    const out: string[] = [];

    if (params.get('iframe')) {
      out.push('iframe=1');
    }
    if (params.get('bgcolor')) {
      out.push('bgcolor=' + params.get('bgcolor'));
    }
    if (params.get('cardcolor')) {
      out.push('cardcolor=' + params.get('cardcolor'));
    }

    if (out.length > 0) {
      return `?${out.join('&')}`;
    } else {
      return '';
    }
  }, [ params ]);

  return <Card className={`${styles.card} AuctionCard_iframe`} variant="outlined">
    <Link component={RouterLink} to={`/partner/${customerId ?? supply.organizationUuid}/aukce/${supply.uuid}${generatedParams}`}>
      <CardActionArea sx={{
        height:        '100%',
        paddingBottom: '120px',
        position:      'relative',
      }} className={`${disableHover ? styles.carouselItemNoHover : styles.carouselItem}`}>
        <Box className={styles.item}>
          {!hideRemainingTime && <Typography sx={{
            textAlign: 'center',
            fontSize:  '12px !important',
            py:        '3px',
          }}>
            {(!auction || !loaded) ? <Skeleton sx={{
              margin: 'auto',
              width:  '20%',
            }}/> : parse(remainingTime(auction.endTimestamp, auction.startTimestamp)) }
          </Typography>}
          <Box
            sx={{
              borderRadius:    '35px',
              overflow:        'hidden',
              height:          '150px',
              position:        'relative',
              backgroundColor: 'white',
            }}
          >
            <CardMedia
              sx={{
                position:                'absolute',
                borderBottomLeftRadius:  '35px',
                borderBottomRightRadius: '35px',
                maxHeight:               '150px',
              }}
              component="img"
              image={
                supply.previewMultimediaUuid && supply.previewMultimediaUuid.length > 0
                  ? getSupplyMultimedia(supply.uuid!, supply.previewMultimediaUuid)
                  : 'https://placehold.co/600x400?text=Obr%C3%A1zek+nenalezen'}
              alt=""
            />
          </Box>

          <Box sx={{
            p: 1, pt: 0.5,
          }}>
            {organization && <Chip label={organization.form.label}/>}
            <Chip label={supply.contractType.label}/>
          </Box>

          <Typography sx={{
            p:  1,
            pt: 0,
          }} className={styles.description}>{ supply.title }</Typography>
          {supply.streetCode.length > 0 && <Typography sx={{
            p: 1, pt: 0,
          }} className={styles.place}>
            <PromiseValue promise={PlaceIdToAddress(supply.streetCode)} key={supply.streetCode} error='Došlo k chybě při překladu adresy'/>
          </Typography>}

          <Box className={styles.currentPricePos} textAlign='center'>
            <Divider sx={{
              borderStyle: 'dotted', marginBottom: '10px', borderColor: 'rgba(0, 0, 0, 1)',
            }}/>

            <Typography sx={{ px: 1 }} className={styles.place}>Konec aukce:</Typography>

            <Grid container spacing={2} sx={{ py: '10px' }}>
              <Grid item xs={6} textAlign='right'>
                <img src={DateIcon} width={10} height={10} alt="" className={styles.icon}/>
                <Typography className={styles.time}>
                  {(!auction || !loaded) ? <Skeleton sx={{ width: '3vw' }}/> : dayjs(auction?.endTimestamp).format('D. M. YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign='left'>
                <img src={TimeIcon} width={10} height={10} alt="" className={styles.icon}/>
                <Typography className={styles.time} >
                  {(!auction || !loaded) ? <Skeleton sx={{ width: '3vw' }}/> : dayjs(auction.startTimestamp).format('HH:mm:ss')}
                </Typography>
              </Grid>
            </Grid>

            <Typography className={styles.currentPriceHeader}>Aktuální cena</Typography>
            <Typography className={styles.currentPrice}>
              {(!auction || !loaded) ? <Skeleton sx={{
                width: '3.5vw', m: 'auto',
              }}/> : parseCurrencyAmount(auction.currentAmount, supply.currency.label)}</Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Link>
  </Card>;
};

export default AuctionCard;