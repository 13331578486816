import { Chip as MaterialChip } from '@mui/material';

import styles from './Chip.module.css';
import React from 'react';

export const colors = {
  Developer:             '#9b4e3e',
  Insolvence:            '#d1bd00',
  Obec:                  '#a4000a',
  Prodej:                '#9e04a6',
  Nájem:                 '#009de3',
  Realitka:              '#e02804',
  'Insolvenční správce': '#e06704',
};

export type ChipProps = {
  xl?: boolean,
  label: string,
};

export function generateChipSx(isXL?: boolean) {
  if (isXL) {
    return {
      fontSize:           'clamp(0.5rem, 3vw + 0.1rem, 1rem) !important',
      height:             'clamp(0.5rem, 10vw + 0.1rem, 2.1rem) !important',
      '& .MuiChip-label': { px: 'clamp(10px, 5vw + 0.1rem, 30px) !important' },
    };
  } else {
    return {};
  }
}

export default function Chip({ xl, label }: ChipProps) {
  return (<MaterialChip label={label} className={styles.chip} sx={{
    backgroundColor: label in colors ? colors[label as keyof typeof colors] : '#fff',
    ...generateChipSx(xl),
  }}/>);
}