import { LoadingButton } from '@mui/lab';
import {
  Container,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack, TextField, Typography, useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink } from 'react-router-dom';

import { Telephone } from '../../InputMask/Telephone';
import {
  isEmail,
  isTelephoneNumber,
  maxLength,
  minLength, required, ToSAgreed,
} from '../../InputValidator';
import ObchodniPodminkyDialog from '../../ObchodniPodminky/ObchodniPodminkyDialog';
import Klokan from './Klokan.jpg';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

export interface Data {
  subjectType: string;
  subjectName: null | string;
  IC: null | string;
  DIC: null | string;
  address: null | string;
  city: null | string;
  PSC: null | string;
  firstName: null | string;
  lastName: null | string;
  username: null | string;
  telephone: null | string;
  message: string;
  ToS: null | boolean;
}

const rules = {
  subjectType: [required],
  region:      [required],
  message:     [maxLength(500)],
  subjectName: [required, minLength(2), maxLength(80)],
  IC:          [required],
  DIC:         [],
  address:     [required, minLength(2), maxLength(50)],
  city:        [required, minLength(2), maxLength(50)],
  PSC:         [required],
  firstName:   [required, minLength(3)],
  lastName:    [required, minLength(3)],
  telephone:   [required, isTelephoneNumber],
  username:    [required, isEmail],
  ToS:         [ToSAgreed],
} as { [x in keyof Data]: (typeof required)[]};

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ captcha, setCaptcha ] = React.useState('');

  const [ data, setData ] = React.useState<Data>({
    subjectType: 'Realitní kancelář',
    subjectName: null,
    IC:          null,
    DIC:         null,
    address:     null,
    city:        null,
    PSC:         null,
    firstName:   null,
    lastName:    null,
    telephone:   null,
    username:    null,
    message:     '',
    ToS:         null,
  });
  const [ loading, setLoading ] = React.useState(false);

  const onInputChange = <K extends keyof Data>(field: K, value: Data[K]) => setData((d) => ({
    ...d, [field]: value,
  }));

  const validateField = React.useCallback((field: keyof Data): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    const value = data[field];
    if (value === null) {
      return {}; // untouched
    } else {
      for (const rule of rules[field]) {
        const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
        if (result === true) {
          continue;
        }
        return result;
      }
    }

    return {};
  }, [ data ]);

  const isFormValid = React.useCallback(() => {
    for (const [field, validators] of Object.entries(rules)) {
      const value = data[field as keyof Data];
      if (value === null) {
        continue; // skip non dirty
      }
      for (const rule of validators) {
        const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
        if (result === true) {
          continue;
        }
        return false;
      }
    }
    return true;
  }, [ data ]);

  const dirtifyAllInputs = React.useCallback(() => {
    setData(v => {
      for (const [attr, value] of Object.entries(v)) {
        if (value === null) {
          if (attr === 'ToS') {
            v.ToS = false;
          } else {
            v[attr as Exclude<keyof Data, 'ToS'>] = '';
          }
        }
      }
      return { ...v };
    });
  }, []);

  const registerUser = React.useCallback(() => {
    console.debug({ captcha });

    // dirtify
    dirtifyAllInputs();

    // recheck validation
    if (isFormValid()) {
      setLoading(true);
      const body = `Dobrý den,%0D%0A
zasíláme Vám naší žádost o přidělení přístupu k portálu INBIDO.%0D%0A%0D%0A
Typ subjektu: ${data.subjectType}%0D%0A
Název subjektu: ${data.subjectName}%0D%0A
IČ: ${data.IC}%0D%0A
DIČ: ${data.DIC}%0D%0A
Ulice: ${data.address}%0D%0A
Město: ${data.city}%0D%0A
PSČ: ${data.PSC}%0D%0A
Jméno: ${data.firstName}%0D%0A
Příjmení: ${data.lastName}%0D%0A
Telefon: ${data.telephone}%0D%0A
Email: ${data.username}%0D%0A%0D%0A
Zpráva: ${data.message}%0D%0A%0D%0A
Děkujeme za zpracování.%0D%0A
S pozdravem%0D%0A
${data.firstName} ${data.lastName}`;
      window.location.href = `mailto:info@inbido.cz?subject=Žádost o registraci - ${data.subjectType}&body=${body}`;
      setLoading(false);
    }
  }, [ isFormValid, data, dirtifyAllInputs, captcha ]);

  return (<Container sx={{
    p: '30px !important', mt: 1,
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Registrace realitky, developera, obce - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1">Odesílání žádosti o přístup do administrace a spolupráci s <strong>INBIDO</strong>.</Typography>
    <Typography sx={{ py: 3 }}>
        Tato  žádost je určena pouze pro Realitní kanceláře, Developery a Obce. Pro registraci účtu pro účast na aukcích pokračujte {' '}
      <Link component={RouterLink} to='/registrace-ucastnika-aukce'>
          ZDE
      </Link>.
    </Typography>

    <Stack spacing={1} component='form'>
      <FormControl fullWidth variant='filled'>
        <InputLabel id="demo-simple-select-label" shrink>Typ subjektu</InputLabel>
        <Select
          value={data.subjectType}
          onChange={(ev) => onInputChange('subjectType', ev.target.value)}
          label="Typ subjektu">
          <MenuItem value="Realitní kancelář">Realitní kancelář</MenuItem>
          <MenuItem value="Developer">Developer</MenuItem>
          <MenuItem value="Obec">Obec</MenuItem>
        </Select>
      </FormControl>

      <TextField
        variant='filled'
        required
        fullWidth
        label="Název subjektu (obchodní název"
        {...validateField('subjectName')}
        onChange={(ev) => onInputChange('subjectName', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="IČ"
        {...validateField('IC')}
        onChange={(ev) => onInputChange('IC', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        fullWidth
        label="DIČ"
        {...validateField('DIC')}
        onChange={(ev) => onInputChange('DIC', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="Ulice"
        {...validateField('address')}
        onChange={(ev) => onInputChange('address', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="Město"
        {...validateField('city')}
        onChange={(ev) => onInputChange('city', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="PSČ"
        {...validateField('PSC')}
        onChange={(ev) => onInputChange('PSC', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="Jméno"
        {...validateField('firstName')}
        onChange={(ev) => onInputChange('firstName', ev.currentTarget.value)}
        autoComplete='given-name'
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="Příjmení"
        {...validateField('lastName')}
        onChange={(ev) => onInputChange('lastName', ev.currentTarget.value)}
        autoComplete='family-name'
      />

      <TextField
        label="E-mail"
        variant='filled'
        required
        fullWidth
        type='username'
        autoComplete='username'
        {...validateField('username')}
        onChange={(ev) => onInputChange('username', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        value={data.telephone || ''}
        fullWidth
        label='Telefon'
        type='tel'
        autoComplete='tel'
        {...validateField('telephone')}
        onChange={(ev) => onInputChange('telephone', ev.target.value)}
        InputProps={{ inputComponent: Telephone as any }}
      />

      <TextField
        variant='filled'
        required
        multiline
        minRows={10}
        maxRows={10}
        value={data.message || ''}
        fullWidth
        label='Zpráva'
        {...validateField('message')}
        onChange={(ev) => onInputChange('message', ev.target.value)}
      />

      <FormControl
        required
        {...validateField('ToS')}
        component="fieldset"
        sx={{
          m: 3, py: 3,
        }}
        variant="standard"
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={data.ToS ?? false} onChange={(_, checked) => onInputChange('ToS', checked)} name="ToS" />
            }
            label={<Typography>
            Souhlasím s <ObchodniPodminkyDialog>obchodními podmínkami</ObchodniPodminkyDialog> *
            </Typography>}
          />
        </FormGroup>
        { ('helperText' in (validateField('ToS') ?? {})) && <FormHelperText>{validateField('ToS')!.helperText}</FormHelperText>}
      </FormControl>
    </Stack>

    {process.env.REACT_APP_RECAPTCHA &&  <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA}
      onChange={(value: any) => setCaptcha(value ?? '')}
    />}

    <Box sx={{
      py: 2, textAlign: 'center',
    }}>
      <LoadingButton loading={loading} disabled={!isFormValid()} onClick={registerUser} variant='contained' sx={{ height: '50px' }}>Odeslat žádost o přístup</LoadingButton>
    </Box>

    {!isMobile && <Box sx={{
      position: 'absolute', right: '15%', bottom: '235px', zIndex: -1,
    }}>
      <img width="250" src={Klokan} alt=""/>
    </Box>}
  </Container>
  );
}
