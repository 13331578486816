import Timeline from '@mui/lab/Timeline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import React from 'react';
import { Auction, Supply } from '../../../Hooks/useAPI';
import dayjs from 'dayjs';
import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';
import { parseCurrencyAmount } from '../../../parseCurrencyAmount';

type Props = {
  auction: Auction;
  supply: Supply;
};

export default function HistoryDialog(props: Props) {
  const { auction, supply } = props;

  const [ open, setOpen ] = useState(false);

  return (<>
    <Button variant='contained' color='dark' onClick={() => setOpen(true)}>Přihazujících: {auction.currentCustomerCount}</Button>
    <Dialog open={open} fullWidth>
      <DialogTitle component='span'>
        <Typography variant={'h6'}>Historie příhozů</Typography>

        { auction.bids.length > 0 && <Typography variant={'body2'}>
          Poslední příhoz: {dayjs(auction.bids[0].createTimestamp).format('D. M. YYYY - HH:mm:ss')}
        </Typography>}
      </DialogTitle>
      <DialogContent>
        <Timeline  position="alternate">
          {auction.bids.map((bid) => <TimelineItem key={bid.uuid}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography>{
                bid.isSelf
                  ? `Já`
                  : bid.customerName
              }</Typography>
              <Typography>
                {parseCurrencyAmount(bid.amount, supply.currency.label)}
              </Typography>
              <Typography>{dayjs(bid.createTimestamp).format('D. M. YYYY - HH:mm:ss')}</Typography>
            </TimelineContent>
          </TimelineItem>)}
        </Timeline>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Zavřít</Button>
      </DialogActions>
    </Dialog>
  </>);
}