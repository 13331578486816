import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import {
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useAPI, { Auction, SupplyLite } from '../Hooks/useAPI';
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import {
  ContentCopy,
  Delete, Edit, People,
} from '@mui/icons-material';
import { ConfirmDialog } from '../Dialog/Confirm';
import dayjs from 'dayjs';

export const ActionFormatter = ({ value }: { value: (SupplyLite & { auction: Auction }) }) => {
  const { removeSupply } = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ confirm, setConfirm ] = useState(false);
  const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);

  const isAuctionActive = useMemo(() => {
    if (value.auction) {
      return dayjs(value.auction.startTimestamp).isBefore(Date.now()) && dayjs(value.auction.endTimestamp).isAfter(Date.now());
    } else {
      return false;
    }
  }, [value.auction]);

  const isAuctionDone = useMemo(() => {
    if (value.auction) {
      return dayjs(value.auction.endTimestamp).isBefore(Date.now());
    } else {
      return false;
    }
  }, [value.auction]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Odkaz na aukci zkopírován.');
    copy(`${window.location.origin}/partner/${value.organizationUuid}/aukce/${value.uuid}`, { format: 'text/plain' });
  }, [ value, enqueueSnackbar ]);

  const deleteItem = useCallback(() => {
    removeSupply(value.uuid!).then(() => window.location.reload());
    setShowDeleteConfirm(false);
  }, [ value, removeSupply ]);

  return <>
    <ConfirmDialog
      text='Skutečně si přejete upravit právě probíhající / ukončenou aukci?'
      onClose={() => setConfirm(false)}
      open={confirm}
      onSubmit={() => navigate(`/administrace/aukce/${value.uuid}`)}
    />
    <ConfirmDialog
      text='Opravdu si přejete smazat tuto aukci?'
      yes='Ano, smazat'
      onClose={() => setShowDeleteConfirm(false)}
      open={showDeleteConfirm}
      onSubmit={deleteItem}/>

    { isMobile
      ? <>
        {keycloak.hasResourceRole('modifyRunningAuction')
          ? <>
            <IconButton href={`/administrace/aukce/${value.uuid}`} onClick={() => {
              if (isAuctionActive) {
                setConfirm(true);
              } else {
                navigate(`/administrace/aukce/${value.uuid}`);
              }
            }}><Edit/></IconButton>
            <IconButton href={`/administrace/aukce/${value.uuid}`}><Edit/></IconButton>
            <IconButton onClick={() => setShowDeleteConfirm(true)}><Delete/></IconButton>
          </>
          : !isAuctionActive && <>
            <IconButton href={`/administrace/aukce/${value.uuid}`}><Edit/></IconButton>
            <IconButton onClick={() => setShowDeleteConfirm(true)}><Delete/></IconButton>
          </> }
        <IconButton href={`/administrace/aukce/${value.uuid}/bids`}><People/></IconButton>
        <IconButton onClick={handleCopy}><ContentCopy/></IconButton>
      </>
      : <>
        <Tooltip title="Akce">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon/>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx:        {
              overflow:            'visible',
              filter:              'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt:                  1.5,
              '& .MuiAvatar-root': {
                width:  32,
                height: 32,
                ml:     -0.5,
                mr:     1,
              },
              '&:before': {
                content:   '""',
                display:   'block',
                position:  'absolute',
                top:       0,
                right:     14,
                width:     10,
                height:    10,
                bgcolor:   'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex:    0,
              },
            },
          }}
          transformOrigin={{
            horizontal: 'right', vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right', vertical: 'bottom',
          }}
        >
          <Link component={RouterLink} to={`/administrace/aukce/${value.uuid}/bids`}>
            <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }}>Přihazující</MenuItem>
          </Link>
          {(keycloak.hasResourceRole('modifyRunningAuction')) ? <>
            {/* ADMIN */}
            <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }} onClick={() => {
              if (isAuctionActive || isAuctionDone) {
                setConfirm(true);
              } else {
                navigate(`/administrace/aukce/${value.uuid}`);
              }
            }}>Editovat</MenuItem>
            <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }} onClick={() => setShowDeleteConfirm(true)}>Smazat</MenuItem>
          </>
            : (!isAuctionActive && !isAuctionDone) && <>
              <Link component={RouterLink} to={`/administrace/aukce/${value.uuid}`}>
                <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }}>Editovat</MenuItem>
              </Link>
              <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }} onClick={() => setShowDeleteConfirm(true)}>Smazat</MenuItem>
            </>
          }
          <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }} onClick={handleCopy}>Kopírovat link</MenuItem>
        </Menu>
      </>}
  </>;
};
export const ActionTypeProvider = (props: JSX.IntrinsicAttributes & DataTypeProviderProps & { children?: ReactNode; }) => (
  <DataTypeProvider
    formatterComponent={ActionFormatter}
    {...props}
  />
);