import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Telephone } from '../../../InputMask/Telephone';
import {
  isEmail,
  isTelephoneNumber,
  maxLength,
  minLength, required,
} from '../../../InputValidator';
import ObchodniPodminkyDialog from '../../../ObchodniPodminky/ObchodniPodminkyDialog';
import styles from './JakNaTo.module.css';
import React from 'react';
import theme from '../../../theme';
import { useLocation, useNavigate } from 'react-router';
import GoogleMapsInput from '../../../GoogleMapsInput';

export interface Data {
  name: null | string;
  email: null | string;
  telephone: null | string;
  address: null | string;
  message: string;
  ToS: null | boolean;
}

const rules = {
  name:      [required, minLength(3)],
  message:   [maxLength(500)],
  address:   [required, minLength(3)],
  telephone: [required, isTelephoneNumber],
  email:     [required, isEmail],
} as { [x in keyof Data]: (typeof required)[]};

export default function JakNaTo() {
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.hash.includes('#formular')) {
      document.getElementById('formular')?.scrollIntoView({
        behavior: 'smooth',
        block:    'start',
      });
      navigate('/chci-aukci'); // remove hash
    }
  }, [location, navigate]);

  const [ captcha, setCaptcha ] = useState('');
  const [ checked, setChecked ] = useState(false);

  const [ data, setData ] = useState<Data>({
    address:   null,
    name:      null,
    email:     null,
    telephone: null,
    message:   '',
    ToS:       null,
  });

  const onInputChange = <K extends keyof Data>(field: K, value: Data[K]) => setData((d) => ({
    ...d, [field]: value,
  }));

  const validateField = useCallback((field: keyof Data): {
    error?: boolean;
    helperText: string;
  } => {
    const value = data[field];
    if (value === null) {
      return { helperText: ' ' }; // untouched
    } else {
      for (const rule of rules[field]) {
        const result = typeof rule === 'function'
          ? rule(typeof value === 'string' ? value : JSON.stringify(value))
          : rule;
        if (result === true) {
          continue;
        }
        return result;
      }
    }

    return { helperText: ' ' };
  }, [ data ]);

  const isFormValid = useCallback(() => {
    for (const [field, validators] of Object.entries(rules)) {
      const value = data[field as keyof Data];
      if (value === null) {
        continue; // skip non dirty
      }
      for (const rule of validators) {
        const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
        if (result === true) {
          continue;
        }
        return false;
      }
    }
    return true;
  }, [ data ]);

  const dirtifyAllInputs = useCallback(() => {
    setData(v => {
      for (const [attr, value] of Object.entries(v)) {
        if (value === null) {
          if (attr === 'ToS') {
            v.ToS = false;
          } else {
            v[attr as Exclude<keyof Data, 'ToS'>] = '';
          }
        }
      }
      return { ...v };
    });
  }, []);

  const sendEmail = useCallback(() => {
    // dirtify
    dirtifyAllInputs();

    // recheck validation
    if (isFormValid()) {
      const body = `Dobrý den,%0D%0A
zasílám Vám žádost o vložení nové aukce.%0D%0A%0D%0A
Jméno: ${data.name}%0D%0A
E-mail: ${data.email}%0D%0A
Telefon: ${data.telephone}%0D%0A
Adresa nemovitosti: ${data.address}%0D%0A
Zpráva: ${data.message}%0D%0A%0D%0A
Děkujeme za zpracování.%0D%0A
S pozdravem%0D%0A
${data.name}`;
      window.location.href = `mailto:info@inbido.cz?subject=Chci aukci - ${data.name}&body=${body}`;
    }
  }, [ isFormValid, data, dirtifyAllInputs ]);

  return (
    <Container sx={{ pt: isMobile ? 5 : undefined }}>
      <Typography variant="h2" component="h2">Jak na to?</Typography>

      <Box sx={{
        my: 4, px: isMobile ? 0 : 2, mr: isMobileBreakpoint ? '-10vw' : undefined,
      }}>
        <Grid container sx={{
          backgroundColor: isMobile ? 'transparent' : '#e02804', borderRadius: '30px',
        }}>
          <Grid item xs={12} md={6} sx={{
            backgroundColor: '#ededed', borderRadius: '30px', p: 4, marginLeft: '-1px',
          }}>
            <Typography variant="h5" component="h5">4 kroky nabídnutí nemovitosti</Typography>

            <ol className={styles.list}>
              <li><span><strong>Vyplňte formulář</strong> o vaši nemovitosti, kterou chcete zveřejnit v aukci. Naši pracovníci najdou pro Vás nejlepší partnerskou realitní kancelář, která Vás ve zveřejnění inzerátu zastoupí.</span></li>
              <li><span>Na uvedený email Vám <strong>zašleme</strong> všechny informace, týkající se zveřejnění inzerátu.</span></li>
              <li><span><strong>Dokončíte</strong> potřebné kroky, které vám dorazí emailem.</span></li>
              <li><span>Vaše aukce nemovitosti se spustí a můžete její vývoj sledovat po celou dobu v <strong>reálném čase</strong>. Po ukončení aukce můžete <strong>okamžitě svůj obchod uzavřít</strong>.</span></li>
            </ol>
          </Grid>
          <Grid item xs={12} md={6} sx={{
            color: 'white', p: 4, pb: 2, backgroundColor: isMobile ? '#e02804' : 'transparent', borderRadius: isMobile ? '30px' : 'inherit',
          }}>
            <Typography variant="h5" component="h5" id="formular">Formulář</Typography>

            <Box sx={{ mt: 2 }} className={styles.form}>
              <TextField
                required
                size='small'
                color='light'
                fullWidth
                variant="standard"
                label="Vaše jméno a příjmení"
                {...validateField('name')}
                onChange={(ev) => onInputChange('name', ev.currentTarget.value)}
              />
              <Stack direction='row' spacing={2}>
                <TextField
                  required
                  size='small'
                  color='light'
                  fullWidth
                  variant="standard"
                  label="Váš e-mail"
                  {...validateField('email')}
                  onChange={(ev) => onInputChange('email', ev.currentTarget.value)}
                />
                <TextField
                  required
                  size='small'
                  color='light'
                  fullWidth
                  variant="standard"
                  value={data.telephone || ''}
                  label="Vaše telefonní číslo"
                  {...validateField('telephone')}
                  onChange={(ev) => onInputChange('telephone', ev.target.value)}
                  InputProps={{ inputComponent: Telephone as any }}
                />
              </Stack>

              <GoogleMapsInput
                label="Adresa nemovitosti"
                color='light'
                onChange={(placeId, plusCode, description) => {
                  onInputChange('address', description);
                }}
                {...(validateField('address'))}
              />

              <TextField
                required
                size='small'
                multiline
                color='light'
                fullWidth
                minRows={4}
                variant="standard"
                label="Popis nemovitosti"
                {...validateField('message')}
                onChange={(ev) => onInputChange('message', ev.currentTarget.value)}
              />
            </Box>

            <FormControlLabel
              control={<Checkbox color='light' onChange={(_, v) => setChecked(v)} sx={{ '& .MuiSvgIcon-root': { color: 'white' } }}/>}
              label={
                <Typography className={styles.checkboxLabel}>
                Odesláním emailu souhlasíte se zpracováním <ObchodniPodminkyDialog sx={{
                    color: 'white !important', fontSize: '14px !important', textDecoration: 'underline !important',
                  }}>osobních údajů</ObchodniPodminkyDialog>
                </Typography>
              } />
            <Box justifyContent="right" display='flex' sx={{ pt: 1 }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={6} sx={{ justifyContent: isMobile ? 'center' : undefined }}>
                  {process.env.REACT_APP_RECAPTCHA &&  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={(value: any) => setCaptcha(value ?? '')}
                  />}
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{
                  justifyContent: isMobile ? 'center' : 'right', display: 'flex', alignItems: 'center', pt: isMobile ? 2 : undefined,
                }}>
                  <Button
                    disabled={captcha.length === 0  || !checked || !isFormValid()}
                    color="light"
                    variant='contained'
                    className={styles.button}
                    onClick={sendEmail}
                    sx={{
                      paddingLeft: '20px !important', width: '125px', height: '50px',
                    }}>
                    Odeslat
                    <ChevronRight htmlColor="#e02804"/>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>);
}