import { Stack, useMediaQuery } from '@mui/material/';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import theme from '../theme';
import { useIntervalWhen } from 'rooks';

type Props = {
  open: boolean;
  onClose?: () => void;
  onSubmit: () => void;
  timeout?: number;
  text: string;
  yes?: string;
};

export const ConfirmDialog: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ seconds, setSeconds ] = React.useState((props.timeout ?? 0) / 1000);

  React.useEffect(() => {
    setTimeout(() => {
      setSeconds((props.timeout ?? 0) / 1000);
    }, props.open ? 0 : 1000);
  }, [props.open, props.timeout]);

  useIntervalWhen(() => {
    if ((props.timeout ?? 0) > 0) {
      if (seconds === 0) {
        props.onSubmit();
      } else {
        setSeconds(s => s - 1);
      }
    }
  }, 1000, props.open, true);

  return <Dialog open={props.open} hideBackdrop sx={{
    '& .MuiPaper-root': {
      backgroundColor: '#e02804', borderRadius: '40px', color: 'white',
    },
  }}>
    <DialogContent sx={{
      m: isMobile ? 'inherit' : '50px', p: 0,
    }}>
      {props.text}
    </DialogContent>
    <DialogActions sx= {{
      justifyContent: 'center', mb: '40px',
    }}>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
        {props.onClose && <Button autoFocus onClick={props.onClose} color='dark' variant='contained' sx={{
          textTransform: 'uppercase !important', height: '50px', width: isMobile ? '100%': '150px',
        }}>
        Ne, zpět
        </Button>}
        <Button onClick={props.onSubmit}  color='light' variant='contained' sx={{
          textTransform: 'uppercase !important', height: '50px', width: isMobile ? '100%': '175px',
        }}>
          {props.yes || 'Ano, editovat'}
          {(props.timeout ?? -1) > 0 && ` (${seconds})`}
        </Button>
      </Stack>
    </DialogActions>
  </Dialog>;
};