import {
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import {
  Table as ResponsiveTable, Tbody, Td, Th, Thead, Tr,
} from 'react-super-responsive-table';
import { tableHeaderMessages } from '../../../Grid/Localization';
import useAPI, { Customer, CustomerLite } from '../../../Hooks/useAPI';
import { useKeycloak } from '@react-keycloak/web';
import theme from '../../../theme';
import { ActionFormatter, CustomerActionProvider } from '../../../Grid/CustomerActionProvider';

import { chunk } from 'lodash';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Helmet } from 'react-helmet';

export default function PagesAdministracePrihazujici() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getCustomers, getCustomersHaveNextPage } = useAPI();
  const [ loading, setLoading ] = React.useState<boolean | null>(true);
  const [ bidders, setCustomers ] = React.useState<CustomerLite[]>([]);
  const [ haveAnotherPage, setHaveAnotherPage ] = React.useState(false);
  const { keycloak } = useKeycloak();

  // mobile pagination
  const [page, setPage] = React.useState(1);

  const columns = [
    {
      title: 'Email', name: 'email', width: 690,
    },
    {
      title: 'Jméno ', name: 'firstName', width: 290,
    },
    {
      title: 'Příjmení ', name: 'lastName', width: 290,
    },
    {
      name: 'actions', title: 'Akce', width: 80, getCellValue: (_row: any) => _row,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    if (keycloak.authenticated) {
      Promise.all([
        getCustomers(100, page - 1)
          .catch((e) => {
            console.error(e);
            setCustomers([]);
            setLoading(null);
          }),
        getCustomersHaveNextPage(100, page - 1),
      ]).then(response => {
        if (response[0]) {
          setCustomers(response[0]);
          setHaveAnotherPage(response[1]);
          setLoading(false);
        }
      });
    }
  }, [ keycloak.authenticated, page ]);

  const GenericFormatter = React.useCallback((props: { attr: string, value: any, item: Customer | CustomerLite }) => {
    if (['actions'].includes(props.attr)) {
      return ActionFormatter({ value: props.item });
    } else {
      return <>{props.value}</>;
    }
  }, [ ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Administrace - přihazující - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1">
    Administrace - <span>přihazující</span>
    </Typography>
    { loading === null
      ? <Alert severity='error' sx={{ mt: 5 }} variant='filled'>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
      : <Box sx={{
        position: 'relative', pt: 5,
      }}>
        { isMobile
          ? <Box>
            <ResponsiveTable key={columns.length}>
              <Thead>
                <Tr>
                  {columns.map(o => <Th key={o.title}>{o.title}</Th>)}
                </Tr>
              </Thead>
              <Tbody>
                {chunk(bidders, 5)[page - 1]?.map(o => <Tr key={o.uuid}>
                  {columns.map(c => <Td aria-label={c.title} key={`${o.uuid}-${c.name}`}>
                    <GenericFormatter item={o} attr={c.name} value={o[c.name as keyof typeof o]}/>
                  </Td>)}
                </Tr>
                )}
              </Tbody>
            </ResponsiveTable>
          </Box>
          : <Grid
            key={columns.length /* to forcefully refresh whole table dom */}
            rows={bidders}
            columns={columns}
          >
            <CustomerActionProvider
              for={['actions']}
            />
            <SortingState
              columnExtensions={[{
                columnName: 'actions', sortingEnabled: false,
              }]}
              defaultSorting={[{
                columnName: 'created_at', direction: 'desc',
              }]}
            />
            <IntegratedSorting/>
            <IntegratedFiltering />
            <Table
              messages={{ noData: 'Žádný přihazující' }}
              columnExtensions={columns.map(o => ({
                columnName: o.name, wordWrapEnabled: true, getCellValue: o.getCellValue,
              }))}/>
            <TableColumnResizing defaultColumnWidths={columns.map(o => ({
              columnName: o.name, width: o.width,
            }))} />
            <TableHeaderRow showSortingControls  messages={tableHeaderMessages} />
          </Grid>}

        <Box sx={{
          textAlign: isMobile ? 'center' : 'right', pt: 2,
        }}>
          <IconButton disabled={page === 1} onClick={() => setPage(p => p - 1)}>
            <KeyboardArrowLeft/>
          </IconButton>
          <IconButton disabled={haveAnotherPage} onClick={() => setPage(p => p + 1)}>
            <KeyboardArrowRight/>
          </IconButton>
        </Box>

        <Backdrop open={loading}  sx={{
          position: 'absolute', backgroundColor: '#ffffffaa',
        }}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    }
  </Container>
  );
}