import { useDispatch } from 'react-redux';
import { maxLength, required } from '../InputValidator';
import { setAuctionProcessingDone } from '../store/apiSlice';
import useAPI, { Auction, Supply } from './useAPI';
import React from 'react';

const rules = {
  startTimestamp: [required],
  startingAmount: [required, maxLength(13)],
} as { [x in keyof (Auction)]: (typeof required)[]};

// this should handle issue when refresh is called several time
let refreshMutex = false;
const refreshMutexDelay = 100;

const useAuction = (supplyId: string | undefined, auctionId: string | undefined) => {
  const { getAuction, createAuction, updateAuction } = useAPI();
  const dispatch = useDispatch();

  const [ data, setAuction ] = React.useState<Auction | null>(null);
  const [ loaded, setLoaded ] = React.useState<boolean | null>(false);
  const [ dirty, setDirty ] = React.useState<string[]>([]);

  const update = <K extends keyof Auction>(field: K, value: Auction[K]) => {
    setDirty(v => Array.from(new Set([...v, field])));
    setAuction((d) => d ? ({
      ...d, [field]: value,
    }) : null);
  };

  React.useEffect(() => {
    if (!supplyId) {
      return;
    }

    if (supplyId === 'create') {
      setAuction({
        bids:                 [],
        currentAmount:        0,
        startingAmount:       0,
        currentCustomerCount: 0,
        startTimestamp:       new Date().toISOString(),
        endTimestamp:         new Date().toISOString(),
        createTimestamp:      new Date().toISOString(),
        updateTimestamp:      new Date().toISOString(),
      });
      setLoaded(true);
    } else {
      if (!auctionId) {
        return;
      }
      getAuction(supplyId, auctionId)
        .then(v => {
          setAuction(v);
          setLoaded(true);
        })
        .catch(() => setLoaded(null));
    }
  }, [supplyId, auctionId]);

  const validate = React.useCallback((field: keyof Auction, all = false): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    if ((!all &&!dirty.includes(field)) || !data) {
      return {}; // untouched
    }
    const value = data[field];
    for (const rule of rules[field] ?? []) {
      const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
      if (result === true) {
        continue;
      }
      return result;
    }

    return {};
  }, [ dirty, data ]);

  const isValid = Object.keys(rules ?? {}).map(field => {
    return validate(field as keyof Auction).error ?? false;
  }).filter(value => value).length === 0;

  const dirtifyAndValidate = () => Object.keys(rules ?? {}).map(field => {
    setDirty(v => Array.from(new Set([...v, field])));
    return validate(field as keyof Auction, true).error ?? false;
  }).filter(value => value).length === 0;

  const save = React.useCallback((supply: Required<Supply>) => {
    return new Promise<string>((resolve, reject) => {
      if (!data) {
        return reject();
      }
      if (!auctionId) {
        createAuction(supply.uuid, data)
          .then(resolve);
      } else {
        updateAuction(supply.uuid, auctionId, data)
          .then(() => {
            resolve(auctionId);
          });
      }
    });
  }, [ data, auctionId ]);

  const refresh = () => {
    if (!supplyId || !auctionId) {
      return;
    }

    if (refreshMutex) {
      return;
    }
    refreshMutex = true;

    setTimeout(() => {
      refreshMutex = false;

      getAuction(supplyId, auctionId)
        .then(v => {
          setAuction(v);
          setLoaded(true);
          dispatch(setAuctionProcessingDone());
        })
        .catch(() => setLoaded(null));
    }, refreshMutexDelay);
  };

  return {
    data, loaded, update, validate, isValid, dirtifyAndValidate, save, refresh,
  };
};

export default useAuction;
