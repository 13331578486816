import {
  PagingPanel, Table, TableHeaderRow,
} from '@devexpress/dx-react-grid';

export const pagingPanelMessages: PagingPanel.LocalizationMessages = {
  showAll:     'Zobrazit všechny',
  rowsPerPage: 'Řádků na stránku',
  info:        (parameters) => {
    return `${parameters.from} až ${parameters.to}`;
  },
};

export const tableMessages: Table.LocalizationMessages = { noData: 'Žádné data' };

export const tableHeaderMessages: TableHeaderRow.LocalizationMessages = { sortingHint: 'Seřadit' };