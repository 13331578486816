import {
  Container, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Arrow from './Arrow.svg';
import styles from './Header.module.css';
import Klokan from './Klokan.jpg';
import React from 'react';
import theme from '../../../theme';

export default function Header() {
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container sx={{
      marginTop:     isMobileBreakpoint ? 0 : 1,
      paddingBottom: 1,
    }}>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid xs={8} xsOffset={isMobileBreakpoint ? 4 : 0} md={7} sx={{
          textAlign: isMobileBreakpoint ? 'right' : 'left', zIndex: 2,
        }}>
          <Typography variant="h1" component="h1" className={styles.header}>Nechte si od nás <span>pomoci</span>!</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} justifyContent='space-between' flexDirection={isMobileBreakpoint ? 'row-reverse' : 'row'}>
        <Grid xs={6} md={4.85} sx={{
          textAlign: 'justify', zIndex: 2,
        }}>
          <Typography variant="body1" sx={{ pt: '40px' }}>Náš tým Vám dokáže pomoct i s dalšími potřebnými <strong>službami</strong>, které se pojí s pořízením nemovitosti.</Typography>
          <Typography variant="body1" sx={{ pt: '20px' }}>Nabízíme Vám možnost využití našich doplňkových služeb.</Typography>
          {!isMobileBreakpoint && <img width="80" src={Arrow} alt="" className={styles.image}/>}
        </Grid>
        <Grid md={4} xs={6} sx={{
          pt: '10px', position: 'relative',
        }}>
          <img src={Klokan} alt="" className={styles.klokan}/>
        </Grid>
      </Grid>

    </Container>);
}