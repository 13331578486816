import { Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';

import styles from './ONas.module.css';
import React from 'react';
import theme from '../../theme';

export default function KdoZaTimStoji() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography sx={{
        pt: isMobile ? 1 : 8, pb: isMobile ? 3 : 5,
      }} variant="h2" component="h2"><span>Kdo za tím stojí?</span></Typography>
      <Grid container sx= {{ textAlign: isMobile ? 'left' : 'center' }} spacing={isMobile ? 3 : 0} rowSpacing={4} >
        <Grid item xs={12} md={3}>
          <Typography variant="h2" component="h3" className={styles.name}>Bruno Kulčák</Typography>
          <Typography component="h4" className={styles.title}>Výkonný ředitel</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h2" component="h3" className={styles.name}>Lukáš Jansa</Typography>
          <Typography component="h4" className={styles.title}>Právník</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h2" component="h3" className={styles.name}>Adam Motyka</Typography>
          <Typography component="h4" className={styles.title}>Projektový manažer</Typography>
        </Grid>
      </Grid>
    </>);
}
