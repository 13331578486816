import 'react-quill/dist/quill.snow.css';
import {
  Alert, Box, ButtonBase, Card, CircularProgress, Container, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import useOrganization from '../../../Hooks/useOrganization';
import {
  AddRounded, Delete, PhotoCamera, Upload,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import { Telephone } from '../../../InputMask/Telephone';
import { Members } from '../../../Members/Members';
import theme from '../../../theme';
import useOrganizationForms from '../../../Hooks/useOrganizationForms';
import GoogleMapsInput from '../../../GoogleMapsInput';
import { decode } from 'pluscodes';
import useAPI from '../../../Hooks/useAPI';
import { CreateMemberDialog } from '../../../Dialog/CreateMember';

const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [ { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'], /* "video" */
    ['clean'], // remove formatting button
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image',
];

export default function PagesAdministraceZadavateleEdit() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ memberToUpdateByEmail, setMemberToUpdateByEmail ] = React.useState<string | null>(null);
  const editOperatorByEmail = (memberEmail: string | null) => {
    setMemberToUpdateByEmail(memberEmail);
  };

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { partnerId } = useParams();
  const { data: partner, loaded, validate, update, dirtifyAndValidate, save, updateImage, assignOperator, removeOperatorByEmail } = useOrganization(partnerId);

  const { getOrganizationMultimedia, uploadOrganizationMultimedia, removeOrganizationMultimedia } = useAPI();

  const organizationForms = useOrganizationForms();

  const [ image, setImage ] = React.useState<(string | { file: File, url: string })>('');
  const [ imageError, setImageError ] = React.useState(false);
  const [ imageUUIDToDelete, setImageUUIDToDelete ] = React.useState<string | null>(null);
  React.useEffect(() => {
    setImage(partner?.logoMultimediaUuid ?? '');
  }, [ partner?.logoMultimediaUuid ]);

  const fileInput = React.useRef<HTMLInputElement>(null);
  const uploadFileTrigger = React.useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, [fileInput]);
  const handleUpload = (files: FileList | null) => {
    if (files) {
      setImageError(false);

      for (const file of Array.from(files)) {
        if (!['image/png', 'image/jpeg'].includes(file.type) || file.size / 1024 > 2048) {
          setImageError(true);
        }
        setImage({
          file,
          url: URL.createObjectURL(file),
        });
      }
    }
  };

  const handleImageUpload = async (uploadImage: { file: File, url: string } | string, orgId: string) => {
    if (typeof uploadImage === 'string') {
      return;
    }
    const uuid = await uploadOrganizationMultimedia(orgId, uploadImage.file);
    console.log('before');
    await updateImage(uuid); // resave for images
    console.log('after');
    update('logoMultimediaUuid', uuid);
  };

  const [ saving, setSaving ] = React.useState<boolean>(false);
  const [ error, setError ] = React.useState<any>(null);

  const handleSave = React.useCallback(async () => {
    if (!partner) {
      return;
    }

    const isOrganizationValid = dirtifyAndValidate();

    setSaving(true);
    console.log({ isOrganizationValid });
    if (isOrganizationValid) {
      try {
        if (partnerId === 'create') {
          const saved = await save();
          await handleImageUpload(image, saved.uuid!);
          enqueueSnackbar('Zadavatel byl vytvořena.', { variant: 'success' });
          navigate(`/administrace/zadavatele`);

        } else {
          console.log('Updating', partnerId);
          await handleImageUpload(image, partnerId!);
          await save();
          if (imageUUIDToDelete) {
            await removeOrganizationMultimedia(partnerId!, imageUUIDToDelete);
            setImageUUIDToDelete(null);
          }
          enqueueSnackbar('Zadavatel byl editován.', { variant: 'success' });
        }
      } catch (e) {
        console.error(e);
        setError(e);
      }
    }
    setSaving(false);

  }, [ partner, image, navigate, enqueueSnackbar, imageUUIDToDelete ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Box>
      <Helmet>
        <link rel='canonical' href={location.origin}/>
        <title>Administrace - zadavatelé - {partnerId === 'create' ? 'vytvoření' : 'editace'} - { process.env.REACT_APP_TITLE }</title>
      </Helmet>
      <Typography variant="h1" component="h1">
        {partnerId === 'create'
          ? <>Administrace - Zadavatelé - <span>vytvoření</span></>
          : <>Administrace - Zadavatelé - <span>editace</span></>
        }
      </Typography>
      { loaded === null
        ? <Alert severity='error' variant='filled' sx={{ mt: 5 }}>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
        : <>
          { !loaded || !partner
            ? <Box sx={{
              height:       '420px',
              display:      'flex',
              alignItems:   'center',
              placeContent: 'center',
            }}>
              <CircularProgress/>
            </Box>
            : <Box>
              {error && <Alert severity='error' variant='filled' sx={{ my: 2 }}>Něco se pokazilo, opakujte později. { String(error) }</Alert>}

              <Box sx={{ pt: isMobile ? 2 : 8 }}/>
              <Stack spacing={2}>
                <FormControl fullWidth variant='standard'>
                  <InputLabel error={!!validate('form').helperText} id="demo-simple-select-label" shrink>Typ zadavatele</InputLabel>
                  <Select
                    value={JSON.stringify(partner.form)}
                    {...validate('form')}
                    onChange={(ev) => update('form', JSON.parse(ev.target.value))}
                    label="Typ subjektu">
                    {organizationForms.map(o => <MenuItem key={o.id} value={JSON.stringify(o)}>{o.label}</MenuItem>)}
                  </Select>
                  {validate('form').helperText && <FormHelperText error>{validate('form').helperText}</FormHelperText>}
                </FormControl>

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={partner?.title || ''}
                  label="Název zadavatele (viditelný)"
                  {...validate('title')}
                  onChange={(ev) => update('title', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={partner?.name || ''}
                  label="Obchodní název zadavatele"
                  {...validate('name')}
                  onChange={(ev) => update('name', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={partner?.companyCode || ''}
                  label="IČO"
                  {...validate('companyCode')}
                  onChange={(ev) => update('companyCode', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  fullWidth
                  value={partner?.companyVatCode || ''}
                  label="DIČ"
                  {...validate('companyVatCode')}
                  onChange={(ev) => update('companyVatCode', ev.currentTarget.value)}
                />

                <GoogleMapsInput
                  label="Adresa"
                  value={partner.streetCode}
                  onChange={(placeId, plusCode, description, address) => {
                    update('zipCode', address.zip ?? '');
                    update('cityCode', address.city ?? '');
                    update('stateCode', address.country ?? '');
                    update('streetCode', address.street_address ?? '');
                    update('routeCode', address.street ?? '');
                    update('provinceCode', address.province ?? '');

                    const decoded = decode(plusCode);
                    update('latitude', decoded?.latitude ?? 0);
                    update('longitude', decoded?.longitude ?? 0);
                  }}
                  error={
                    validate('zipCode').error
                    || validate('cityCode').error
                    || validate('stateCode').error
                    || validate('streetCode').error
                    || validate('routeCode').error
                    || validate('provinceCode').error
                  }
                  helperText={
                    (validate('zipCode').error
                    || validate('cityCode').error
                    || validate('stateCode').error
                    || validate('streetCode').error
                    || validate('routeCode').error
                    || validate('provinceCode').error)
                      ? 'Tato položka je povinná'
                      : undefined
                  }
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={partner?.email || ''}
                  label="E-mail"
                  {...validate('email')}
                  onChange={(ev) => update('email', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  fullWidth
                  value={partner?.url || ''}
                  label="Odkaz na webové stránky (https://priklad.cz)"
                  {...validate('url')}
                  onChange={(ev) => update('url', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={partner?.phoneNumber || ''}
                  label="Telefon"
                  {...validate('phoneNumber')}
                  onChange={(ev) => update('phoneNumber', ev.currentTarget.value)}
                  InputProps={{ inputComponent: Telephone as any }}
                />

                <Box sx={{ '.ql-editor': { minHeight: '200px' } }}>
                  <FormLabel  error={!!validate('description').helperText} sx={{
                    fontWeight: 900, fontSize: '12px !important',
                  }}>Popis</FormLabel>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={partner?.description}
                    onChange={(val: string) =>  update('description', val as any)} />
                  {validate('description').helperText
                    ? <FormHelperText error>{validate('description').helperText}</FormHelperText>
                    : <FormHelperText sx={{ textAlign: 'right' }}>{(partner?.description || '').length}/500</FormHelperText>
                  }
                </Box>

                <Grid container sx={{ pt: 4 }} spacing={4}>
                  <Grid item sx={{ pl: '0 !important' }}>
                    <Stack direction='row' spacing={2} alignItems={'center'}>
                      <PhotoCamera htmlColor='#e02804' sx={{ fontSize: '30px' }}/>
                      <FormLabel sx={{ fontWeight: 500 }}>Logo</FormLabel>
                    </Stack>
                  </Grid>

                  {((typeof image === 'string' && image.length > 0) || typeof image !== 'string')
                    ? <Grid item xs={2} key={typeof image === 'string' ? image : image.file.name} alignSelf='center' sx={{ position: 'relative' }}>
                      <Box sx={{
                        position: 'absolute', right: '30px', top: '30px', p: '8px', display: typeof image === 'string' ? 'none' : undefined,
                      }}>
                        <Upload htmlColor='white'/>
                      </Box>
                      <IconButton
                        sx={{
                          position: 'absolute', right: 0, top: '30px',
                        }}
                        onClick={() => {
                          if (typeof image === 'string') {
                            setImageUUIDToDelete(image);
                          }
                          setImage('');
                        }}>
                        <Delete htmlColor='#e02804'/>
                      </IconButton>
                      <picture>
                        <img style={{ width: '100%' }} src={typeof image !== 'string' ? image.url : getOrganizationMultimedia(partnerId!, image)} alt=''/>
                      </picture>
                    </Grid>
                    :  <Grid item xs={2}>
                      <ButtonBase sx={{
                        border:       '4px dashed grey',
                        borderRadius: '30px',
                      }} onClick={uploadFileTrigger}>
                        <input hidden accept="image/*" type="file" id="uploadInput" ref={fileInput} onChange={(ev) => handleUpload(ev.currentTarget.files)} />
                        <Card sx={{
                          justifyContent:    'center',
                          width:             '140px',
                          height:            '140px',
                          display:           'flex',
                          backgroundColor:   'transparent',
                          '&.MuiPaper-root': {
                            border: '0 !important', boxShadow: 'none !important',
                          },
                        }}
                        >
                          <AddRounded sx={{
                            fontSize: '100px', alignSelf: 'center',
                          }} htmlColor='#e02804'/>
                        </Card>
                      </ButtonBase>
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <Typography variant='body2'>*Logo nahrajte s bílým, nebo průhledným pozadím v dostatečné kvalitě.</Typography>
                    <Typography variant='body2'>Podpora: jpeg/png do velikosti 2 MB.</Typography>
                    {imageError && <Typography variant='body2' sx={{ color: 'red' }}>Nahrajte soubor do 2 MB (2048 kB) ve formátu jpeg/png.</Typography>}
                  </Grid>
                </Grid>

                <Grid container sx={{ pt: 4 }}>
                  <Grid item xs>
                    <FormLabel sx={{
                      fontWeight: 500,
                      color:      validate('operators').helperText ? theme.palette.error.main : undefined,
                    }}>
                    Členové
                      {validate('operators').helperText && <FormHelperText sx={{
                        display: 'inline-block', pl: 2,
                      }} error>{validate('operators').helperText}</FormHelperText>}
                    </FormLabel>
                  </Grid>
                  <Grid item>
                    <CreateMemberDialog
                      members={partner.operators}
                      onConfirm={assignOperator}
                      updateMemberEmail={memberToUpdateByEmail}
                      onClosed={() => {
                        setTimeout(() => {
                          editOperatorByEmail(null);
                        }, 100);
                      }}/>
                  </Grid>
                </Grid>
                <Members
                  members={partner.operators}
                  removeOperatorByEmail={removeOperatorByEmail}
                  editOperatorByEmail={editOperatorByEmail}
                />
              </Stack>

              <Box sx={{
                margin: 'auto', pt: 4,  textAlign: 'center',
              }}>
                <LoadingButton loading={saving} onClick={handleSave} color='secondary' variant='contained' sx={{
                  height: '50px', width: '150px', borderRadius: '90px !important',
                }}>Uložit</LoadingButton>
              </Box>
            </Box>
          }
        </>
      }
    </Box>
  </Container>
  );
}
