export const errorAttributes = (text: string) => ({
  error:      true,
  helperText: text,
});

export const validUglyDate = (v?: string | number | any[]) => {
  v ??= '';
  return String(v).length > 0 || errorAttributes('Vložte datum ve formátu 31.12.2022');
};

export const required = (v?: string | number | any[]) => {
  v ??= '';
  return String(v).length > 0 || errorAttributes('Tato položka je povinná');
};

export const ToSAgreed = (v: string | number | any[] | null) => {
  if (v === null) {
    return true;
  }
  return (JSON.parse(String(v) ?? 'false') as boolean) || errorAttributes('Musíte souhlasit s obchodními podmínkami');
};

export const minLength = (min: number) => {
  return (v?: string | number | any[]) => {
    if (Array.isArray(v)) {
      return v.length >= min || errorAttributes(`Toto pole musí mít alespoň ${min} položek`);
    }
    v ??= '';
    return String(v).length >= min || errorAttributes(`Tato položka musí mít alespoň ${min} znaky`);
  };
};

export const maxLength = (max: number) => {
  return (v?: string | number | any[]) => {
    if (Array.isArray(v)) {
      return v.length <= max || errorAttributes(`Toto pole musí mít maximálně ${max} položek`);
    }
    v ??= '';
    return String(v).length <= max || errorAttributes(`Tato položka musí mít maximálně ${max} znaků`);
  };
};

export const isEmail = (v?: string | number | any[]) => {
  v ??= '';
  return (/.+@.+\..+/.test(String(v))) || errorAttributes('E-mail není správně zadán');
};

export const isTelephoneNumber = (v?: string | number | any[]) => {
  v ??= '';
  if (String(v).length === 0) {
    return true;
  }
  return (/^\+(420|421)\s?(\d{3}\s?){2}\d{3}$/.test(String(v))) || errorAttributes('Telefon není správně zadán. Formát: +420 123 456 789');
};

export const isURLOptional = (v?: string | number | any[]) => {
  v ??= '';
  return ((!v || (typeof v === 'string' && String(v).length === 0)) || (!!v && !!String(v).match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/))) || errorAttributes('Nesprávný formát odkazu');
};