import {
  Button, Dialog, DialogActions, DialogContent, SxProps, Theme, Typography, useMediaQuery,
} from '@mui/material';
import { useState } from 'react';

import ObchodniPodminky from './ObchodniPodminky';
import React from 'react';
import theme from '../theme';

type Props ={
  children: string;
  sx?: SxProps<Theme> | undefined
};

export default function ObchodniPodminkyDialog({ children, sx }: Props) {
  const [ openToS, setOpenToS ] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (<>
    <Typography component={'span'} onClick={(ev) => {
      ev.preventDefault(); setOpenToS(true);
    }} sx={{
      fontWeight: 'bold',
      color:      '#e02804 !important',
      ...sx,
    }}>{children}</Typography>
    <Dialog open={openToS} fullWidth maxWidth='lg' fullScreen={isMobile}>
      <DialogContent>
        <ObchodniPodminky dialog/>
      </DialogContent>
      <DialogActions>
        <Button size='large' onClick={() => setOpenToS(false)}>Zavřít</Button>
      </DialogActions>
    </Dialog>
  </>);
}