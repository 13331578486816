import CookieIcon from '@mui/icons-material/Cookie';
import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, FormGroup, Paper, Popper, Stack, Typography, useMediaQuery,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { Helmet } from 'react-helmet';
import {
  useCallback, useEffect, useState,
} from 'react';

import { useLocalStorage } from '../Hooks/useLocalStorage';
import React from 'react';
import { isEqual } from 'lodash';
import { useWindowScrollPosition } from 'rooks';
import theme from '../theme';
import { useQuery } from '../Hooks/useQuery';

export default function CookieBar() {
  const [ open, setOpen ] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const query = useQuery();
  const { scrollY } = useWindowScrollPosition();

  const [ bottom, setBottom ] = React.useState(isMobile ? '0': '10px');
  const [ cookieControlConsent, setCookieControlConsent ] = useLocalStorage('cookie_control_consent', 0);
  const [ cookieControlEnabledCookies, setCookieControlEnabled ] = useLocalStorage('cookie_control_enabled_cookies', ['mandatory']);

  React.useEffect(() => {
    if (isMobile && !query.get('iframe')) {
      const height = document.documentElement.scrollHeight - window.innerHeight;
      if (height) {
        if (height - scrollY < 60) {
          setBottom(`${(height - scrollY - 60) * -1}px`);
        } else {
          setBottom('0');
        }
      }
    }
  }, [ scrollY, isMobile, query ]);

  const acceptAll = () => {
    setCookieControlConsent(Date.now() + 3.154e+10); // add year consent
    setCookieControlEnabled(['mandatory', 'analytics', 'functional']);
  };

  const isEverythingEnabled = React.useMemo(() => isEqual(cookieControlEnabledCookies, ['mandatory', 'analytics', 'functional']), [cookieControlEnabledCookies]);

  const revokeAll = () => {
    setCookieControlConsent(Date.now() + 3.154e+10); // add year consent
    setCookieControlEnabled(['mandatory']);
  };

  useEffect(() => {
    if (cookieControlConsent && cookieControlConsent < Date.now()) {
      setCookieControlConsent(0);
    }
  }, [cookieControlConsent, setCookieControlConsent]);

  const updateEnabledCookies = useCallback((key: string, checked: boolean) => {
    if (checked) {
      setCookieControlEnabled(Array.from(new Set([...cookieControlEnabledCookies ?? [], key])));
    } else {
      setCookieControlEnabled((cookieControlEnabledCookies ?? []).filter(val => val !== key));
    }
    setCookieControlConsent(Date.now() + 3.154e+10); // add year consent
  }, [ cookieControlEnabledCookies, setCookieControlConsent, setCookieControlEnabled ]);

  return (<>
    {!isEverythingEnabled && <Popper open={true} placement="right-end" sx={{
      zIndex:    10,
      minHeight: 'inherit !important',
      right:     isMobile ? '0': '10px',
      bottom:    bottom,
      left:      'inherit !important',
      top:       'inherit !important',
    }}>
      { cookieControlConsent === 0 ? <Box component={Paper} sx={{
        width: isMobile ? '100%' : '500px', p: 2,
      }}>
        <Typography variant={'h5'} sx={{
          fontWeight: 'bold', pb: 2,
        }}>Cookies</Typography>
        <Typography>Používáme vlastní soubory cookie a soubory cookie třetích stran, abychom vám mohli ukázat tyto webové stránky a lépe porozumět tomu, jak je používáte, s cílem zlepšit služby, které nabízíme. Pokud budete pokračovat v prohlížení, máme za to, že jste cookies přijali.</Typography>

        <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ pt: 2 }}>
          <Button variant='contained' onClick={() => setOpen(true)}>Upřesnit nastavení cookies</Button>
          <Button variant='contained' onClick={acceptAll}>Přijmout vše</Button>
        </Stack>
      </Box>
        : <Button variant='contained' onClick={() => setOpen(true)} size='small' sx={{
          borderRadius: '10px !important',
          height:       isMobile ? '40px' : '50px',
          width:        isMobile ? '40px' : '50px',
          minWidth:     0,
          transform:    isMobile ? 'translate(-10px, -10px)' : 'inherit',
        }}><CookieIcon sx={{ fontSize: '30px' }}/></Button>
      }
    </Popper>
    }
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          <Typography variant={'h5'} sx={{
            fontWeight: 'bold', pb: 2,
          }}>Povinná cookies</Typography>
          <FormGroup>
            <FormControlLabel disabled control={<Checkbox defaultChecked/>} label={<Typography>
              <Typography component='span' sx ={{ fontWeight: 'bold' }}>POVINNÁ COOKIES</Typography> - Používá se pro kontrolu souborů cookie.
              <Typography variant='caption' component='div'>cookie_control_consent</Typography>
              <Typography variant='caption' component='div'>cookie_control_enabled_cookies</Typography>
            </Typography>} />
          </FormGroup>

          <Typography variant={'h5'} sx={{
            fontWeight: 'bold', py: 2,
          }}>Volitelná cookies</Typography>
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(_, checked) => updateEnabledCookies('analytics', checked)} checked={cookieControlEnabledCookies?.includes('analytics')}/>} label={<Typography>
              <Typography component='span' sx ={{ fontWeight: 'bold' }}>GOOGLE ANALYTICS</Typography> - Používá se pro kontrolu souborů cookie.
              <Typography variant='caption' component='div'>ga_</Typography>
              <Typography variant='caption' component='div'>ga_{process.env.REACT_APP_GA}</Typography>
            </Typography>} />
          </FormGroup>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack spacing={1} direction={isMobile ? 'column' : 'row'} sx={{
          width: '100%', justifyContent: isMobile ? 'space-between' : 'inherit',
        }}>
          <Button fullWidth={isMobile} variant='contained' onClick={() => {
            setOpen(false); setCookieControlConsent(Date.now() + 3.154e+10);
          }}>Uložit</Button>
          <Button fullWidth={isMobile} variant='contained' onClick={() => {
            acceptAll(); setOpen(false);
          }}>Přijmout vše</Button>
          <Button fullWidth={isMobile} variant='contained' onClick={() => {
            revokeAll(); setOpen(false);
          }}>Odmítnout vše</Button>
        </Stack>
      </DialogActions>
    </Dialog>
    {cookieControlEnabledCookies?.includes('analytics') && <Helmet>
      <script
        src={'https://www.googletagmanager.com/gtag/js?id=' + process.env.REACT_APP_GA}

      />
      <script id="google-analytics" >
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${process.env.REACT_APP_GA}');
            `}
      </script>
    </Helmet>
    }
  </>);
}