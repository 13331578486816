import { ArrowBack } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntervalWhen } from 'rooks';
import { ConfirmDialog } from '../../Dialog/Confirm';
import { useQuery } from '../../Hooks/useQuery';
import { selectBidProcessed, setAuctionProcessing } from '../../store/apiSlice';
import theme from '../../theme';
import Nemovitost from './Nemovitost/Nemovitost';
import Stav from './Stav/Stav';
import useSupply from '../../Hooks/useSupply';
import useOrganization from '../../Hooks/useOrganization';
import useAuction from '../../Hooks/useAuction';
import styles from './Detail.module.css';
import { PromiseValue } from '../../PromiseValue';
import { PlaceIdToAddress } from '../../PlaceIdToAddress';
import Chip from '../../Chips/Chip';

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const query = useQuery();

  const { aukceId, partnerId } = useParams();
  const { data: supply } = useSupply(aukceId);
  const { data: auction, refresh } = useAuction(supply?.uuid, supply?.currentAuctionUuid);
  const { data: customer } = useOrganization(partnerId);
  const dispatch = useDispatch();

  const [ afterBidErrorOpen, setAfterBidErrorOpen ] = useState(false);

  const bidProcessed = useSelector(selectBidProcessed); // used to track user changes

  const [refreshRate, setRefreshRate] = React.useState(0);
  useIntervalWhen(() => {
    setRefreshRate(v => v + 1);
    let triggerAfterEnd = false;
    if (auction) {
      const x = dayjs(auction.endTimestamp);
      // we want to trigger in 100ms interval to trigger only once after auction end
      // as we have 100ms interval
      triggerAfterEnd = x.isAfter(new Date(Date.now())) && x.isBefore(Date.now() + 100);
      if (triggerAfterEnd) {
        console.debug(new Date().toISOString(), 'Auction _possibly_ ended, forcing refresh.');
        dispatch(setAuctionProcessing());
      }
    }
    // usually refresh every 10 seconds or after end of auction
    if (refreshRate === 100 || triggerAfterEnd) {
      setRefreshRate(0);
      refresh();
    }
  }, 100, true, false);

  useEffect(() => {
    refresh();
  }, [ bidProcessed ]);

  return (<>
    <ConfirmDialog
      open={afterBidErrorOpen}
      onSubmit={() => setAfterBidErrorOpen(false)}
      yes="Ano, rozumím"
      text="Váš příhoz již není možné započítat. Aukce již skončila."
      timeout={10000}/>
    {(!customer || !auction || !supply)
      ? <Box sx={{
        height:       '420px',
        display:      'flex',
        alignItems:   'center',
        placeContent: 'center',
      }}>
        <CircularProgress/>
      </Box>
      : <Container sx={{
        marginTop:     isMobile ? 0 : 1,
        paddingBottom: 1,
      }}>
        <Helmet>
          <link rel='canonical' href={location.origin}/>
          <title>Aukce - { supply.title } - { process.env.REACT_APP_TITLE }</title>
          <meta property="og:title" content={`Aukce - ${ supply.title } - ${ process.env.REACT_APP_TITLE }`} />
          {/* {auction.images.length > 0 && <meta property="og:image" content={getAuctionImage(auction.id, auction.images[0])} />} */}
        </Helmet>
        <Grid container spacing={0} justifyContent='space-between' sx={{
          pb: 1, pt: '20px',
        }}>
          <Grid item md={12} sx={{ zIndex: 99 }}>
            <Typography variant="h1" component="h1" className={styles.header}>
              {query.get('iframe') && <IconButton onClick={() => window.history.back()} sx={{ pr: 2 }}><ArrowBack/></IconButton>}
              {supply.title}
            </Typography>

            <Stack direction={isTablet ? 'column' : 'row'} spacing={isTablet ? '10px' : '20px'}
              sx={isMobile ? {
                pt: 1, pl: query.get('iframe') ? 6 : 0,
              } : { pl: query.get('iframe') ? 6 : 0 }}>
              {supply.streetCode.length > 0 && <Typography variant="h2" component="h2" sx={{ fontWeight: '500 !important' }}>
                <PromiseValue promise={PlaceIdToAddress(supply.streetCode)} key={supply.streetCode} error='Došlo k chybě při překladu adresy'/>
              </Typography>}
              <Stack direction='row' alignItems='center' spacing={'2vw'}>
                <Chip xl label={supply.contractType.label}/>
                <Chip xl label={customer.form.label}/>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Box className='AuctionDetail_iframe' sx={{
          mt: query.get('iframe') ? 0 : 4,
          mb: query.get('iframe')
            ? isMobile ? 2 : 1
            : 8,
          px:           isMobile ? 'inherit' : '8% !important',
          borderRadius: '40px',
          border:       isMobile ? 'inherit' : '4px solid #dfdcdc',
          padding:      '10px',
        }}>
          <Grid container spacing={isMobile ? 5 : 20}>
            <Grid item xs={12} md={6}>
              <Nemovitost supply={supply} customer={customer}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stav auction={auction} supply={supply} onBidError={() => setAfterBidErrorOpen(true)}/>
            </Grid>
          </Grid>
        </Box>
      </Container>
    }
  </>
  );
}
