import { Loader } from '@googlemaps/js-api-loader';

const language = 'cs';

const loader = new Loader({
  apiKey:             process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  version:            'weekly',
  libraries:          ['geocoding', 'places'],
  authReferrerPolicy: 'origin',
  language,
});

const geocoding = loader.importLibrary('geocoding');
export const places = loader.importLibrary('places');

export async function PlaceIdToAddress(placeId: string): Promise<string> {
  if (sessionStorage[`address-${placeId}-${language}`]) {
    return sessionStorage[`address-${placeId}-${language}`];
  }

  return new Promise(async (resolve, reject) => {
    const geocoder = new (await geocoding).Geocoder;
    geocoder
      .geocode({ placeId })
      .then(({ results }) => {
        resolve(results[0].formatted_address);
        sessionStorage[`address-${placeId}-${language}`] = results[0].formatted_address;
      })
      .catch(reject);
  });
}