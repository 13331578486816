import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';

import useAPI from '../../Hooks/useAPI';
import theme from '../../theme';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import useCustomer from '../../Hooks/useCustomer';
import { Telephone } from '../../InputMask/Telephone';

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const { partnerId, bidderId } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { PasswordChange } = useAPI();

  const user = useCustomer(bidderId);

  const [ saving, setSaving ] = React.useState<boolean>(false);
  const [ error, setError ] = React.useState<any>(null);

  const triggerPasswordChange = React.useCallback(async () => {
    PasswordChange();
  }, [PasswordChange]);

  const handleSave = React.useCallback(async () => {
    if (!user.data) {
      return;
    }

    if (user.dirtifyAndValidate()) {
      setSaving(true);

      try {
        await user.save();
        enqueueSnackbar('Data aktualizována.', { variant: 'success' });
      } catch (e) {
        setError(e);
      }

      setSaving(false);
    }
  }, [ user, enqueueSnackbar ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Box>
      <Typography variant="h1" component="h1">
        {partnerId
          ?<>Administrace - Zadavatele - Člen - <span>Editace</span></>
          : (bidderId
            ? <>Administrace - Přihazujícího - <span>Editace</span></>
            : 'Změna údajů')
        }
      </Typography>
      <Helmet>
        <link rel='canonical' href={location.origin}/>
        <title>{partnerId
          ? 'Administrace - Zadavatele - Člen - Editace'
          : (bidderId
            ? 'Administrace - Přihazujícího - Editace'
            : 'Změna údajů')
        } - { process.env.REACT_APP_TITLE }</title>
      </Helmet>

      { user.loaded === null
        ? <Alert severity='error' variant='filled' sx={{ mt: 5 }}>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
        : <>
          { !user.loaded || !user.data
            ? <Box sx={{
              height:       '420px',
              display:      'flex',
              alignItems:   'center',
              placeContent: 'center',
            }}>
              <CircularProgress/>
            </Box>
            : <Box>
              {error && <Alert severity='error' variant='filled' sx={{ my: 2 }}>Něco se pokazilo, opakujte později. { String(error) }</Alert>}
              {bidderId === undefined && <Alert severity='error' variant='filled' sx={{
                my: 2, width: 'fit-content', borderRadius: '20px', margin: '20px auto -20px auto',
              }}>Tyto údaje slouží pouze pro náhled. Pro změnu údajů nás <Link component='a' href="mailto:info@inbido.cz?subject=Inbido.cz%20-%20Po%C5%BEadavek%20na%20zm%C4%9Bnu%20%C3%BAdaj%C5%AF%20%C3%BA%C4%8Dastn%C3%ADka%20aukce&body=Dobr%C3%BD%20den%2C%0D%0A%0D%0A%C5%BE%C3%A1d%C3%A1m%20o%20zm%C4%9Bnu%20%C3%BAdaj%C5%AF%3A%0D%0A%0D%0AJm%C3%A9no%3A%0D%0AP%C5%99%C3%ADjmen%C3%AD%3A%0D%0AEmail%3A%20%20%0D%0ATelefon%3A%0D%0A%0D%0APamatujete%20si%20na%20n%C3%A1zev%20posledn%C3%AD%20aukce%2C%20kter%C3%A9%20jste%20se%20%C3%BA%C4%8Dastnil%20nebo%20v%C3%BD%C5%A1e%20posledn%C3%ADho%20p%C5%99%C3%ADhozu%20u%20t%C3%A9to%20aukce%3F%20%0D%0A%0D%0APros%C3%ADme%20o%20dopln%C4%9Bn%C3%AD%20pro%20ov%C4%9B%C5%99en%C3%AD%2C%20%C5%BEe%20V%C3%A1m%20tento%20%C3%BA%C4%8Det%20skute%C4%8Dn%C4%9B%20pat%C5%99%C3%AD%3A%0D%0A%0D%0APozn%C3%A1mka%3A%20%C5%BD%C3%A1dost%20mus%C3%AD%20b%C3%BDt%20odesl%C3%A1na%20z%20emailu%2C%20kter%C3%BD%20je%20evidov%C3%A1n%20p%C5%99ed%20po%C5%BEadovanou%20zm%C4%9Bnou.%0D%0A" sx={{
                  color: 'white !important', textDecoration: 'underline !important',
                }}>kontaktujte</Link>. Děkujeme.</Alert>}

              <Box sx={{ pt: isMobile ? 5 : 8 }}/>
              <Stack spacing={2}>
                <TextField
                  variant='standard'
                  required
                  disabled={bidderId === undefined}
                  fullWidth
                  value={user.data.firstName}
                  label="Jméno"
                  {...user.validate('firstName')}
                  onChange={(ev) => user.update('firstName', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  disabled={bidderId === undefined}
                  value={user.data.lastName}
                  label="Příjmení"
                  {...user.validate('lastName')}
                  onChange={(ev) => user.update('lastName', ev.currentTarget.value)}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  disabled={bidderId === undefined}
                  value={user.data.phoneNumber}
                  label="Telefon"
                  {...user.validate('phoneNumber')}
                  onChange={(ev) => user.update('phoneNumber', ev.currentTarget.value)}
                  InputProps={{ inputComponent: Telephone as any }}
                />

                <TextField
                  variant='standard'
                  required
                  fullWidth
                  disabled={bidderId === undefined}
                  value={user.data.email}
                  label="E-mail"
                  {...user.validate('email')}
                  onChange={(ev) => user.update('email', ev.currentTarget.value)}
                />
              </Stack>

              {!bidderId && <Box sx={{ pt: 4 }}>
                <Button onClick={triggerPasswordChange} variant='contained' sx={{
                  height: '50px', width: '150px', borderRadius: '90px !important',
                }}>ZMĚNA HESLA</Button>
              </Box>}

              {bidderId && <Box sx={{
                margin: 'auto', pt: 4,  textAlign: 'center',
              }}>
                <LoadingButton loading={saving} onClick={handleSave} color='secondary' variant='contained' sx={{
                  height: '50px', width: '150px', borderRadius: '90px !important',
                }}>Uložit</LoadingButton>
              </Box>}
            </Box>
          }
        </>
      }
    </Box>
  </Container>
  );
}
