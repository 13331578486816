import {
  CustomPaging,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  AddRounded, Close, Search,
} from '@mui/icons-material';
import { PlaceTypeProvider } from '../../../Grid/PlaceTypeProvider';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  IconButton,
  InputAdornment,
  Link,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {
  Table as ResponsiveTable, Tbody, Td, Th, Thead, Tr,
} from 'react-super-responsive-table';
import { ActionFormatter } from '../../../Grid/PartnerAuctionProvider';
import { pagingPanelMessages, tableHeaderMessages } from '../../../Grid/Localization';
import theme from '../../../theme';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Helmet } from 'react-helmet';
import useOrganizations from '../../../Hooks/useOrganizations';
import { PartnerActionProvider } from '../../../Grid/PartnerAuctionProvider';
import { OrganizationLite } from '../../../Hooks/useAPI';
import { PlaceIdToAddress } from '../../../PlaceIdToAddress';
import { PromiseValue } from '../../../PromiseValue';

export default function PagesAdministraceZadavatele() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ search, setSearch ] = React.useState('');
  const [pageSize, setPageSize] = React.useState(isMobile ? 5 : 10);

  // mobile pagination
  const [page, setPage] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const [pageSizes] = React.useState([1, 5, 10, 15, 25, 50, 0]);

  const filters = React.useMemo(() => {
    return search.length > 0 ? ['title='+search] : [];
  }, [search]);

  const { data: partners, loading, pageCount } = useOrganizations({
    pageSize, page, filters,
  });

  const columns = [
    {
      title: 'Typ', name: 'type', width: 100,  getCellValue: (_row: any) => _row.form.label,
    },
    {
      title: 'IČ', name: 'companyVatCode', width: 100,
    },
    {
      title: 'Jméno', name: 'title', width: 200,
    },
    {
      title: 'Telefon', name: 'phoneNumber', width: 110,
    },
    {
      title: 'Email', name: 'email', width: 200,
    },
    {
      title: 'WWW', name: 'url', width: 200,
    },
    {
      title: 'Adresa', name: 'streetCode', width: 360,
    }, {
      name: 'actions', title: 'Akce', width: 80, getCellValue: (_row: any) => _row,
    },
  ];

  const GenericFormatter = React.useCallback((props: { attr: string, value: any, item: OrganizationLite }) => {
    if (['actions'].includes(props.attr)) {
      return ActionFormatter({ value: props.item });
    } else if (['streetCode'].includes(props.attr)) {
      return <PromiseValue promise={PlaceIdToAddress(props.value as string)} key={props.value as string} error='Došlo k chybě při překladu adresy'/>;
    } else {
      return <>{!props.value || props.value.length === 0 ? ' ' : props.value}</>;
    }
  }, [  ]);

  return (<Container sx={{
    // marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Administrace - zadavatelé - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1">
    Administrace - <span>zadavatelé</span>
    </Typography>
    { loading === null
      ? <Alert severity='error' sx={{ mt: 5 }} variant='filled'>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
      : <>
        <Box sx={{ pt: 5 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={isMobile ? 2 : 0}>
            <TextField
              variant='outlined'
              value={search}
              sx={{ width: '400px' }}

              InputProps={{
                sx:             { '.MuiInputBase-input': { p: '7px 7px 7px 0px !important' } },
                startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                endAdornment:   <InputAdornment position="end">
                  <Fade in={search.length > 0}>
                    <IconButton onClick={() => {
                      setSearch('');
                      setPage(0);
                    }}>
                      <Close/>
                    </IconButton>
                  </Fade>
                </InputAdornment>,
              }}
              onChange={(ev) => {
                setSearch(ev.currentTarget.value);
                setPage(0);
              }}
            />
            <Link component={RouterLink} to='/administrace/zadavatele/create'>
              <Button variant='contained' color='secondary' sx={{
                borderRadius: '90px !important', height: isMobile ? 'inherit' : '50px', fontSize: '18px !important',
              }}>Přidat <AddRounded sx={{
                  fontSize: isMobile ? 'inherit' : '40px', ml: 2,
                }}/></Button>
            </Link>
          </Stack>
        </Box>
        <Box sx= {{ position: 'relative' }}>
          { isMobile
            ? <Box>
              <ResponsiveTable key={columns.length}>
                <Thead>
                  <Tr>
                    {columns.map(o => <Th key={o.title}>{o.title}</Th>)}
                  </Tr>
                </Thead>
                <Tbody>
                  {partners.map(o => <Tr key={o.uuid}>
                    {columns.map(c => <Td aria-label={c.title} key={`${o.uuid}-${c.name}`}>
                      <GenericFormatter item={o} attr={c.name} value={o[c.name as keyof typeof o]}/>
                    </Td>)}
                  </Tr>
                  )}
                </Tbody>
              </ResponsiveTable>

              <Pagination sx={{
                pt:                    2,
                '& .MuiPagination-ul': {
                  p: '0 !important', justifyContent: 'center',
                },
                '& .MuiPaginationItem-page': { fontSize: '3vw !important' },
              }}  siblingCount={1} count={pageCount} page={page + 1} onChange={handleChange} />
            </Box>
            : <Grid
              key={columns.length /* to forcefully refresh whole table dom */}
              rows={partners}
              columns={columns}
            >
              <PartnerActionProvider
                for={['actions']}
              />
              <PlaceTypeProvider
                for={['streetCode']}
              />
              <SortingState
                columnExtensions={[{
                  columnName: 'actions', sortingEnabled: false,
                }]}
                defaultSorting={[{
                  columnName: 'created_at', direction: 'desc',
                }]}
              />
              <IntegratedSorting/>
              <IntegratedFiltering />
              <PagingState
                currentPage={page}
                pageSize={pageSize}
                onCurrentPageChange={setPage}
                onPageSizeChange={setPageSize}
                defaultPageSize={10}
                defaultCurrentPage={0}
              />

              <CustomPaging
                totalCount={pageCount * pageSize}
              />
              <Table
                messages={{ noData: 'Žádný zadavatel' }}
                columnExtensions={columns.map(o => ({
                  columnName: o.name, wordWrapEnabled: true,
                }))}/>
              <TableColumnResizing defaultColumnWidths={columns.map(o => ({
                columnName: o.name, width: o.width,
              }))} />
              <TableHeaderRow showSortingControls  messages={tableHeaderMessages} />
              <PagingPanel
                messages={pagingPanelMessages}
                pageSizes={pageSizes}
              />
            </Grid>}
          <Backdrop open={loading}  sx={{
            position: 'absolute', backgroundColor: '#ffffffaa',
          }}>
            <CircularProgress/>
          </Backdrop>
        </Box>
      </>
    }
  </Container>
  );
}
