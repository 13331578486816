import {
  Box, Container, Grid, Stack, Typography, useMediaQuery,
} from '@mui/material';

import Bullet from './Bullet';
import Home from './Home.png';
import styles from './WhyAuctions.module.css';
import React from 'react';
import theme from '../../../theme';

export default function WhyAuctions() {
  const isTabletBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{
      backgroundColor: '#1c1c1e', mt: isTabletBreakpoint ? 5 : 0,
    }}>
      <Container sx={!isTabletBreakpoint ? {
        px: '0px !important', mt: '45px',
      } : { pb: '10px' }}>
        <Grid container sx={!isTabletBreakpoint ? {
          py: '45px', px: isTabletBreakpoint ? '4vw' : '80px',
        } : undefined}>
          <Grid item xs={12} md={4}>
            <Box sx={!isTabletBreakpoint
              ? { pb: '60px' }
              : { pt: '10px' }
            }>
              <div className={styles.buttonLikeHeader}>Proč INBIDO?</div>
            </Box>
            {!isTabletBreakpoint && <img src={Home} width={'90%'} alt="" style={{ maxWidth: '280px' }}/>}
          </Grid>
          <Grid item xs={12} md={8} sx={{ pt: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>ÚSPORA ČASU</Typography>
                </Stack>
                <Typography className={styles.body}>Vytvoření aukce je rychlé a jednoduché, stejně jako účast v již probíhající aukci.</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>VŠE POD VAŠÍ KONTROLOU</Typography>
                </Stack>
                <Typography className={styles.body}>Výběr aukce i průběh přihazovaní je ve Vaší režii, stačí si vybrat a přihazovat podle potřeby.</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={!isTabletBreakpoint ? { pt: '40px' } : { pt: '20px' }}>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>ANONYMITA</Typography>
                </Stack>
                <Typography className={styles.body}>V probíhající aukci nejsou vidět Vaše osobní údaje, každý z účastníků je tak anonymní.</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>FÉROVOST</Typography>
                </Stack>
                <Typography className={styles.body}>Princip aukce dovoluje účastníkům soutěžit o vyjednávací práva férově a transparentně.</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={!isTabletBreakpoint ? { pt: '40px' } : { pt: '20px' }}>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>NA MÍSTĚ NEZÁLEŽÍ</Typography>
                </Stack>
                <Typography className={styles.body}>Zapojit se do aukce můžete z pohodlí domova, cestou do práce nebo z druhého konce světa.</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction='row' className={styles.header}>
                  <Bullet width={20} height={20} className={styles.bullet}/>
                  <Typography className={styles.headerText}>PROVĚŘENÍ ZADAVATELÉ</Typography>
                </Stack>
                <Typography className={styles.body}>Naši zadavatelé jsou experti v oblasti prodeje realit.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>);
}