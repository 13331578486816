import { useKeycloak } from '@react-keycloak/web';
import useAPI, { Auction, SupplyLite } from './useAPI';
import React from 'react';

type Props = {
  pageSize: number;
  page: number;
  filters?: string[]
  ownOrganizationOnly?: boolean;
};

const useSupplies = (props: Props) => {
  const { getSupplies, getAuction, getOrganization } = useAPI();
  const { keycloak } = useKeycloak();

  const [ data, setData ] = React.useState<(SupplyLite & { auction: Auction })[]>([]);
  const [ total, setTotal ] = React.useState(0);
  const [ loading, setLoading ] = React.useState<boolean | null>(true);

  const refresh = async () => {
    setLoading(true);
    const filters: string[] = props.filters ?? [];
    if (!keycloak.hasResourceRole('showAdministrator') && props.ownOrganizationOnly) {
      const organization = await getOrganization();
      filters.push(`organization=${organization.uuid}`);
    }
    getSupplies(props.pageSize, props.page, filters)
      .then(async v => {
        setTotal(v.supplyCount);

        const _data = await Promise.all(
          v.supplies.map(supply => new Promise<typeof data[number]>((resolve, reject) => {
            getAuction(supply.uuid!, supply.currentAuctionUuid).then(auction => {
              if (!auction) {
                reject('No auction assigned to supply');
              }
              resolve({
                ...supply,
                auction: auction!,
              });
            });
          }))
        );
        setData(_data);
        setLoading(false);
      }) .catch((e) => {
        console.error(e);
        setData([]);
        setLoading(null);
      });
  };

  React.useEffect(() => {
    refresh();
  }, [props.pageSize, props.page, props.filters]);

  return {
    data, loading, total,
  };
};

export default useSupplies;
