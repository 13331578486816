import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import React from 'react';
import { ReactNode } from 'react';

export const DateFormatter = ({ value }: { value: string }) => {
  return <>{new Date(value).toLocaleString()}</>;
};
export const DateTypeProvider = (props: JSX.IntrinsicAttributes & DataTypeProviderProps & { children?: ReactNode; }) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
);