import {
  Box, Container, Grid as Grid, Link, Typography,
} from '@mui/material';

import styles from './Footer.module.css';
import Facebook from './Icons/Facebook.png';
import Instagram from './Icons/Instagram.png';
import LinkedIn from './Icons/LinkedIn.png';
import WhiteLogo from './WhiteLogo.png';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function Footer() {
  return (<>
    <Container sx={{ height: '300px' /* height of footer */ }}/>
    <Box sx= {{
      width: '100%', height: '300px', position: 'absolute', left: 0, bottom: 0,
    }} className={styles.footerImage}/>
    <Box className={styles.footer}>
      <Container>
        <Grid container sx={{ px: '20px !important' }}>
          <Grid className={styles.lowerGrid} xs={4} item>
            <Link component={RouterLink} to="/chci-aukci" className={styles.link}>Chci aukci</Link>
            <Link component={RouterLink} to="/chci-koupit" className={styles.link}>Chci koupit</Link>
            <Link component={RouterLink} to="/chci-najem" className={styles.link}>Chci nájem</Link>
            <Link component={RouterLink} to="/zadavatele" className={styles.mutedLink}>Zadavatelé</Link>
            <Link component={RouterLink} to="/vsechny-insolvencni-aukce" className={styles.mutedLink}>Insolvenční a průmyslové aukce</Link>
            <Link component={RouterLink} to="/doplnkove-sluzby" className={styles.mutedLink}>Doplňkové služby</Link>
          </Grid>
          <Grid className={styles.middleGrid} xs={4} item>
            <Box sx={{ width: '160px'  }}>
              <Link component={RouterLink} to="/o-nas" className={styles.link}>O nás</Link>
              <Link href="https://blog.inbido.cz/" className={styles.link}>Blog</Link>
              <Link component={RouterLink} to="/kontakty" className={styles.link}>Kontakty</Link>
              <Link component={RouterLink} to="/caste-dotazy" className={styles.mutedLink}>Časté dotazy</Link>
              <Link component={RouterLink} to="/obchodni-podminky" className={styles.mutedLink}>Obchodní podmínky</Link>
            </Box>
          </Grid>
          <Grid className={styles.higherGrid} xs={4} item>
            <img src={WhiteLogo} alt="INBIDO.CZ" width='180'/>
            <Typography>2022 &copy; inbido s.r.o.</Typography>
            <Typography><Link href="mailto:info@inbido.cz" component='a'>info@inbido.cz</Link></Typography>
            <Typography><Link href="tel:+420 777 799 645" component='a'>+420 777 799 645</Link></Typography>

            <Link href="https://www.facebook.com/inbidoaukce/"><img src={Facebook} alt="Facebook"/></Link>
            <Link href="https://www.instagram.com/inbido.cz/"><img src={Instagram} alt="Instagram"/></Link>
            <Link href="https://www.linkedin.com/company/inbido-cz/"><img src={LinkedIn} alt="LinkedIn"/></Link>
          </Grid>
        </Grid>

        <Box sx={{
          textAlign: 'right', py: '10px',
        }}>
          <Typography className={styles.company}>
          Designed by <strong>Inflee Solutions s.r.o</strong>
          </Typography>
        </Box>
      </Container>
    </Box>
  </>);
}