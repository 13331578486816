import useAPI, { Form } from './useAPI';
import React from 'react';

const useCurrencyTypes = () => {
  const { getCurrencyTypes } = useAPI();

  const [ currencyTypes, setCurrencyTypes ] = React.useState<Form[]>([]);

  React.useEffect(() => {
    const cache = sessionStorage.currencyTypes;
    if (cache) {
      setCurrencyTypes(JSON.parse(cache));
    } else {
      getCurrencyTypes().then((data) => {
        setCurrencyTypes(data);
        sessionStorage.currencyTypes = JSON.stringify(data);
      });
    }
  }, []);

  return currencyTypes;
};

export default useCurrencyTypes;
