import {
  Box, Button, Container, Grid, Link, Paper, Stack, Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import { NavLink, Link as RouterLink } from 'react-router-dom';

import Logo from './Logo.svg';
import styles from './TopMenu.module.css';
import PasswordButton from './PasswordButton';
import useAPI from '../Hooks/useAPI';

export default function TopBar() {
  const { keycloak } = useKeycloak();
  const { getOrganization } = useAPI();

  const [ partnerId, setPartnerId ] = React.useState<null | string>(null);

  React.useEffect(() => {
    if (keycloak.authenticated && keycloak.hasResourceRole('showOrganizationOperator')) {
      getOrganization().then(data => setPartnerId(data.uuid!));
    }
  }, [ keycloak.authenticated ]);

  return (<Container>
    <Grid container alignItems='center' height="100px" spacing={0} sx={{
      paddingLeft: '0', position: 'relative', /* relative position for black bar */
    }}>
      <Grid item xs='auto' sx={{ pr: '44px' }}>
        <Link component={NavLink} to="/">
          <img width="161" src={Logo} alt="INBIDO logo"/>
        </Link>
      </Grid>
      <Grid item xs='auto' sx={{
        flexGrow: '1 !important', maxWidth: 'calc(100% - 540px) !important',
      }}>
        <Stack direction='row' justifyContent='space-around'>
          <Link component={NavLink} to="/chci-aukci" className={`${styles.button} ${styles.buttonPosition}`}>Chci aukci</Link>
          <Link component={NavLink} to="/chci-koupit" className={`${styles.button} ${styles.buttonPosition}`}>Chci koupit</Link>
          <Link component={NavLink} to="/chci-najem" className={`${styles.button} ${styles.buttonPosition}`}>Chci nájem</Link>
          <Link component={NavLink} to="/zadavatele" className={`${styles.button} ${styles.buttonPosition}`}>Zadavatelé</Link>
          <Link component={NavLink} to="/vsechny-insolvencni-aukce" className={`${styles.button} ${styles.buttonPosition}`}>Insolvenční a průmyslové aukce</Link>
        </Stack>
      </Grid>
      <Grid item>
        <Box sx={{
          position: 'absolute', right: 0, top: 0, display: 'flex',
        }}>
          <Box className={styles.blackBar} sx={{ right: keycloak.authenticated ? '-60px' : 'inherit' }}>
            <Grid container alignItems='center' textAlign='center'>
              <Grid item xs='auto'>
                <Link component={NavLink} to="/o-nas" className={`${styles.button}`}>O nás</Link>
              </Grid>
              <Grid item xs='auto' sx={{ pl: '15px' }}>
                <Link component={NavLink} to="https://blog.inbido.cz/" className={`${styles.button}`}>Blog</Link>
              </Grid>
              <Grid item xs='auto' sx={{ pl: '15px' }}>
                <Link component={NavLink} to="/kontakty" className={`${styles.button}`}>Kontakty</Link>
              </Grid>
            </Grid>
          </Box>

          { keycloak.authenticated && <Box className={styles.redBar}>
            <Grid container alignItems='center' textAlign='center'>
              {keycloak.hasResourceRole('showCustomer') && <Grid item xs='auto'>
                <Link component={NavLink} to="/zmena-udaju" className={`${styles.button}`}>Změna údajů</Link>
              </Grid>}

              {keycloak.hasResourceRole('showOrganizationOperator') && <>
                <Grid item xs='auto'>
                  <Link component={NavLink} to="/administrace/aukce" className={`${styles.button}`}>Aukce</Link>
                </Grid>
                <Grid item xs='auto' sx={{ pl: '15px' }}>
                  <PasswordButton/>
                </Grid>
                <Grid item xs='auto' sx={{ pl: '15px' }}>
                  <Link component={NavLink} to={`/administrace/zadavatele/${partnerId}/preview`} className={`${styles.button}`}>Externí náhled</Link>
                </Grid>
              </>}

              {keycloak.hasResourceRole('showAdministrator') && <>
                <Grid item xs='auto' sx={{ pl: '15px' }}>
                  <Link component={NavLink} to="/administrace/aukce" className={`${styles.button}`}>Aukce</Link>
                </Grid>
                <Grid item xs='auto' sx={{ pl: '15px' }}>
                  <Link component={NavLink} to="/administrace/zadavatele" className={`${styles.button}`}>Zadavatelé</Link>
                </Grid>
                <Grid item xs='auto' sx={{ pl: '15px' }}>
                  <Link component={NavLink} to="/administrace/prihazujici" className={`${styles.button}`}>Přihazující</Link>
                </Grid>
              </>}
            </Grid>
          </Box>}
        </Box>

        <Box sx={{
          position: 'absolute', right: '10px', top: '32px', display: 'flex',
        }}>
          <Box sx={{
            paddingRight: '30px',
            transform:    'translateY(15px)',
            width:        '100%',
            '& *':        { fontSize: '11px !important' },
          }}>
            {keycloak.authenticated
              ? <Paper sx={{
                borderRadius:    '50px',
                backgroundColor: '#1c1c1e',
                p:               0.5,
                width:           'fit-content',
                marginLeft:      'auto',
                display:         'flex',
                alignItems:      'center',
              }} elevation={0}>
                <Typography component='span' variant='body2' sx={{
                  color: 'white !important', mx: 4, ml: 7,
                }}>{(keycloak.idTokenParsed as any)?.name}</Typography>
                <Button onClick={() => keycloak.logout()} variant="contained" color="primary">Odhlásit</Button>
              </Paper>
              : <>
                <Button onClick={() => {
                  history.pushState({}, '', new URL(window.location.href));
                  keycloak.login();
                }} variant="contained" color="primary" sx={{
                  marginRight: '25px !important', width: '125px', height: '40px',
                }}>Přihlášení</Button>
                <Link component={RouterLink} to="/registrace-ucastnika-aukce">
                  <Button variant="contained" color="secondary" sx={{
                    width: '125px', height: '40px',
                  }}>Registrace</Button>
                </Link>
              </>
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Container>
  );
}