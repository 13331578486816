import { AddRounded } from '@mui/icons-material';
import React from 'react';
import useCustomer from '../Hooks/useCustomer';
import { Customer } from '../Hooks/useAPI';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Telephone } from '../InputMask/Telephone';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';

type Props = {
  onConfirm: (customer: Customer) => void;
  onClosed: () => void;
  members: Customer[];
  updateMemberEmail: string | null;
};

export const CreateMemberDialog: React.FC<Props> = (props) => {
  const customer = useCustomer('create', {
    isTelephoneNumberOptional: true, isPasswordOptional: !!props.updateMemberEmail, 
  }); // validation purpose
  const [ open, setOpen ] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (!open) {
      props.onClosed();
    }
  }, [open] );

  React.useEffect(() => {
    if (props.updateMemberEmail) {
      setOpen(true);
      customer.update('email', props.updateMemberEmail);
      customer.update('uuid', props.members.find((m) => m.email === props.updateMemberEmail)?.uuid ?? undefined);
      customer.update('phoneNumber', props.members.find((m) => m.email === props.updateMemberEmail)?.phoneNumber ?? '');
      customer.update('firstName', props.members.find((m) => m.email === props.updateMemberEmail)?.firstName ?? '');
      customer.update('lastName', props.members.find((m) => m.email === props.updateMemberEmail)?.lastName ?? '');
      customer.update('password', props.members.find((m) => m.email === props.updateMemberEmail)?.password ?? '');
    }
  }, [ props.updateMemberEmail ]);

  return <>
    <Button variant='contained' color='secondary' onClick={() => setOpen(true)} sx={{
      borderRadius: '90px !important',
      height:       isMobile ? 'inherit' : '50px',
      fontSize:     '18px !important',
    }}>Přidat <AddRounded sx={{
        fontSize: isMobile ? 'inherit' : '40px', ml: 2,
      }}/></Button>

    <Dialog open={open} fullWidth>
      <DialogTitle>
        {props.updateMemberEmail ? 'Upravit člena' : 'Vytvořit nového člena'}
      </DialogTitle>

      <DialogContent dividers>
        <TextField
          label="Email"
          variant='standard'
          required
          fullWidth
          sx={{ mb: 1 }}
          value={customer.data?.email ?? ''}
          {...customer.validate('email')}
          onChange={(ev) => customer.update('email', ev.currentTarget.value)}
        />
        <TextField
          label="Jméno"
          variant='standard'
          required
          fullWidth
          sx={{ mb: 1 }}
          value={customer.data?.firstName ?? ''}
          {...customer.validate('firstName')}
          onChange={(ev) => customer.update('firstName', ev.currentTarget.value)}
        />

        <TextField
          label="Příjmení"
          variant='standard'
          required
          fullWidth
          sx={{ mb: 1 }}
          value={customer.data?.lastName ?? ''}
          {...customer.validate('lastName')}
          onChange={(ev) => customer.update('lastName', ev.currentTarget.value)}
        />

        <TextField
          variant='standard'
          fullWidth
          sx={{ mb: 1 }}
          label="Telefon"
          value={customer.data?.phoneNumber ?? ''}
          {...customer.validate('phoneNumber')}
          onChange={(ev) => customer.update('phoneNumber', ev.currentTarget.value)}
          InputProps={{ inputComponent: Telephone as any }}
        />

        {!customer.data?.uuid && <TextField
          label="Heslo"
          variant='standard'
          required
          fullWidth
          sx={{ mb: 1 }}
          value={customer.data?.password ?? ''}
          type="password"
          error={customer.validate('password').error}
          onChange={(ev) => customer.update('password', ev.currentTarget.value)}
        />}

        {!customer.data?.uuid && <TextField
          label="Heslo (znovu)"
          variant='standard'
          required
          defaultValue={customer.data?.password ?? ''}
          fullWidth
          sx={{ mb: 1 }}
          type="password"
          {...customer.validate('password')}
          onChange={(ev) => customer.passwordTest(ev.currentTarget.value)}
        />}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => {
          customer.reset(); // set initial state
          setOpen(false);
        }}>Close</Button>
        <Button disabled={!customer.isValid} onClick={() => {
          if (customer.data && customer.dirtifyAndValidate()) {
            props.onConfirm(customer.data);
            customer.reset();
            setOpen(false);
          }
        }}>{props.updateMemberEmail ? 'Změnit': 'Přidat'}</Button>
      </DialogActions>
    </Dialog>
  </>;
};