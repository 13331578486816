import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, IconButtonProps } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';
import {
  useCallback, useEffect, useState,
} from 'react';

const CopyButton: React.FC<{
  color?: IconButtonProps['color'],
  text: string
}> = ({ color, text }) => {
  const [ copied, setCopied ] = useState(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
    copy(text, { format: 'text/plain' });
  }, [ text ]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [ copied ]);

  return <IconButton color={color} onClick={handleCopy}>
    { copied
      ? <CheckIcon/>
      : <ContentCopyIcon/>
    }

  </IconButton>;
};

export default CopyButton;