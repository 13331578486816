import axios from 'axios';
import { useCallback } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { bidProcessed } from '../store/apiSlice';

export type Nullable<T> = {
  [K in keyof T]: T[K] | null | undefined;
};
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_PROXY === 'true' ? 'http://localhost:3000/api' : process.env.REACT_APP_API_URL });

export interface SupplyLite {
  uuid?: string;
  uid?: string;
  title: string;
  organizationUuid: string;
  currentAuctionUuid: string;
  stateCode: string;
  provinceCode: string;
  routeCode: string;
  cityCode: string;
  streetCode: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  objectType: Form;
  contractType: Form;
  currency: Form;
  isPublic: boolean;
  previewMultimediaUuid: string | null;
}

export interface Supply extends SupplyLite {
  description: string;
  createTimestamp: string;
  updateTimestamp: string;
  multimediaUuids: string[] | null;
}
export interface CustomerLite {
  uuid?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Auction {
  uuid?: string;
  startingAmount: number;
  currentAmount: number;
  currentCustomerCount: number;
  startTimestamp: string;
  endTimestamp: string;
  createTimestamp: string;
  updateTimestamp: string;
  bids: AuctionBid[];
}

export interface AuctionBid {
  uuid: string;
  customerName: string;
  isSelf: boolean;
  amount: number;
  createTimestamp: string;
  updateTimestamp: string;
}

export interface Customer extends CustomerLite {
  createTimestamp: string;
  updateTimestamp: string;
  phoneNumber?: string;
  /**
   * Password attribute is available only in POST request on create
   */
  password?: string;

}

export type OrganizationOperator = Customer;
export interface OrganizationLite {
  uuid?: string;
  uid?: number;
  auctionCount: number;
  title: string;
  form: Form;
  stateCode: string;
  provinceCode: string;
  cityCode: string;
  streetCode: string;
  routeCode: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  logoMultimediaUuid: string;
  companyVatCode: string;
  email: string;
  url: string;
  phoneNumber: string;
}
export interface Organization extends OrganizationLite {
  name: string;
  description: string;
  companyCode: string;
  createTimestamp: string;
  updateTimestamp: string;
  operators: OrganizationOperator[];
}

export type Form = {id: number, label: string};

export type Paginator = number;

function generateRandomString() {
  const array = new Uint32Array(28); // this is of minimum required length for servers with PKCE-enabled
  crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    ''
  );
}
function _base64UrlEncode(str: ArrayBuffer) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  // https://stackoverflow.com/a/9458996
  let binary = '';
  const bytes = new Uint8Array(str);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return btoa(binary)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

const useAPI = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const removeOrganization = useCallback(async (uuid: string) => {
    if (!keycloak.authenticated) {
      return [];
    }
    await axiosInstance.delete(`/organization/${uuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return null;
  }, [ keycloak ]);

  const removeCustomer = useCallback(async (uuid: string) => {
    if (!keycloak.authenticated) {
      return [];
    }
    await axiosInstance.delete(`/customer/${uuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return null;
  }, [ keycloak ]);

  const getCustomers = async (pageSize = 100, page: null | number = null) => {
    let pageIndex = page ?? 0;
    const customers: CustomerLite[] = [];
    while(true) {
      const components = [
        `page-size=${pageSize}`,
        `page-index=${pageIndex}`,
      ].filter(Boolean).join('&');
      const res = await axiosInstance.get<{ customers: CustomerLite[], pageCount: number }>(`/customer/?${components}`,
        { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
      if (res.data.customers.length === 0) {
        break;
      }
      customers.push(...res.data.customers);
      pageIndex++;
    }
    return customers;
  };

  const getCustomersHaveNextPage = async (pageSize = 100, page: number) => {
    const components = [
      `page-size=${pageSize}`,
      `page-index=${page+1}`,
    ].filter(Boolean).join('&');
    const res = await axiosInstance.get<{ customers: CustomerLite[], pageCount: number }>(`/customer/?${components}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data.customers.length > 0;
  };

  const registerCustomer = useCallback(async (captcha: string, opts: Customer) => {
    const res = await axiosInstance.post(`/customer`, {
      token: captcha,
      ...opts,
    });
    return res.data;
  }, [  ]);

  const getCustomer = useCallback(async (uuid: string): Promise<Customer> => {
    if (keycloak.authenticated) {
      const res = await axiosInstance.get(`/customer/${uuid}`, { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
      return res.data;
    } else {
      throw new Error('User not authenticated');
    }
  }, [ keycloak.authenticated ]);

  const updateCustomer = useCallback(async (uuid: string | undefined, data: Partial<Customer>) => {
    if (keycloak.authenticated) {
      uuid ??= keycloak.idTokenParsed?.sub;
      try {
        await axiosInstance.put('/customer/', {
          uuid, ...data,
        }, { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
        return null;
      } catch (e) {
        throw e;
      }
    } else {
      throw new Error('User not authenticated');
    }
  }, [ keycloak ]);

  const PasswordChange = useCallback(async () => {
    const codeVerifier = generateRandomString();
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const codeChallenge = _base64UrlEncode(await crypto.subtle.digest('SHA-256', data));
    const state = generateRandomString();
    const path = `${process.env.REACT_APP_KEYCLOAK_INSTANCEURL ?? 'https://dev.inbido.expineer.com/auth'}/realms/${process.env.REACT_APP_KEYCLOAK_REALM ?? 'inbido-development'}/protocol/openid-connect/auth`
        + `?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENTID ?? 'public-web'}`
        + `&redirect_uri=${encodeURIComponent(window.location.href)}`
        + `&state=${state}`
        + `&kc_action=UPDATE_PASSWORD`
        + `&response_mode=fragment`
        + `&response_type=code`
        + `&scope=openid`
        + `&code_challenge=${encodeURIComponent(codeChallenge)}`
        + `&code_challenge_method=S256`;
    window.location.href = path;
  }, []);

  const getOrganization = async (uuid?: string): Promise<Organization> => {
    if (!uuid) {
      const response = await getOrganizations(1, 0, [`operator=${keycloak.tokenParsed?.sub}`]);
      if (response.organizations.length === 0) {
        throw new Error('No organization found');
      }
      uuid = response.organizations[0].uuid;
    }
    const res = await axiosInstance.get(`/organization/${uuid}`, { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const getOrganizations = async (pageSize = 100, page: null | number = null, filters: string[] = []) => {
    let pageIndex = page ?? 0;

    const organizations: OrganizationLite[] = [];
    let pageCount = 1;
    while(true) {
      const components = [
        `page-size=${pageSize}`,
        `page-index=${pageIndex}`,
        ...filters,
      ].filter(Boolean).join('&');
      const res = await axiosInstance.get<{ organizations: OrganizationLite[], pageCount: number }>(`/organization/?${components}`,
        { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
      pageCount = res.data.pageCount;
      organizations.push(...res.data.organizations);

      if(res.data.pageCount - 1 === pageIndex || pageIndex === page) {
        break;
      }
      pageIndex++;
    }
    return {
      organizations, pageCount,
    };
  };

  const getOrganizationForms = async () => {
    const res = await axiosInstance.get<Form[]>(`/organization/form`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const getAuction = async (uuid: string, currentAuctionUuid: string | null) => {
    if (!currentAuctionUuid) {
      return null;
    }
    const res = await axiosInstance.get<Auction>(`/supply/${uuid}/auction/${currentAuctionUuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    // Workaround for bids returning null if empty
    res.data.bids ??= [];
    return res.data;
  };

  const removeSupply = async (uuid: string) => {
    await axiosInstance.delete(`/supply/${uuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
  };

  const removeAuction = async (uuid: string, currentAuctionUuid: string) => {
    await axiosInstance.delete(`/supply/${uuid}/auction/${currentAuctionUuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
  };

  const getSupplies = async (pageSize = 100, page: null | number = null, filters: string[] = []) => {
    let pageIndex = page ?? 0;

    const supplies: SupplyLite[] = [];
    let pageCount = 0;
    let supplyCount = 0;
    while(true) {
      if (!filters.includes('public=all')) {
        // by default load only public accounts unless
        filters.push('public=true');
      }
      const components = [
        `page-size=${pageSize}`,
        `page-index=${pageIndex}`,
        ...filters,
      ].filter(Boolean).join('&');
      const res = await axiosInstance.get<{ supplies?: SupplyLite[], pageCount?: number, supplyCount: number }>(`/supply/?${components}`,
        { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
      supplyCount = res.data.supplyCount;
      supplies.push(...(res.data.supplies ?? []));
      pageCount = res.data.pageCount ?? 1;

      if(pageCount - 1 === pageIndex || pageIndex === page) {
        break;
      }
      pageIndex++;
    }
    return {
      supplies: supplies ?? [],
      pageCount,
      supplyCount,
    };
  };

  const getSupply = async (uuid: string) => {
    const res = await axiosInstance.get<Supply>(`/supply/${uuid}`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const updateSupply = async (uuid: string, data: Partial<Supply>) => {
    const res = await axiosInstance.put<Supply>(`/supply/`,
      {
        ...data, uuid,
      },
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const updateOrganization = async (uuid: string, data: Partial<Organization>) => {
    const res = await axiosInstance.put<Supply>(`/organization/`,
      {
        ...data, uuid,
      },
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const updateAuction = async (supplyId: string, uuid: string, data: Partial<Auction>) => {
    const res = await axiosInstance.put<Auction>(`/supply/${supplyId}/auction/`,
      {
        ...data, uuid,
      },
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const createAuction = async (supplyId: string, data: Auction) => {
    const createData = cloneDeep(data) as any;
    delete createData.previewMultimediaUuid;
    delete createData.currentAuctionUuid;
    const res = await axiosInstance.post<string>(`/supply/${supplyId}/auction/`,
      createData,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const createOrganization = async (data: Organization) => {
    const createData = cloneDeep(data) as any;
    delete createData.logoMultimediaUuid;
    const res = await axiosInstance.post<string>(`/organization/`,
      createData,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const createSupply = async (data: Supply) => {
    const createData = cloneDeep(data) as any;
    delete createData.previewMultimediaUuid;
    delete createData.currentAuctionUuid;
    const res = await axiosInstance.post<string>(`/supply/`,
      createData,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const getCurrencyTypes = async () => {
    const res = await axiosInstance.get<Form[]>(`/supply/currency/`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const getContractTypes = async () => {
    const res = await axiosInstance.get<Form[]>(`/supply/contract-type/`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const getObjectTypes = async () => {
    const res = await axiosInstance.get<Form[]>(`/supply/object-type/`,
      { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
    return res.data;
  };

  const bidAuction = async (supplyId: string, auctionId: string, value: number) => {
    await axiosInstance.post<string>(`/supply/${supplyId}/auction/${auctionId}/bid/`,
      value,
      {
        headers: {
          authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined, 'Content-Type': 'application/json',
        },
      });
    dispatch(bidProcessed());
  };

  const removeSupplyMultimedia = async(supplyId: string, multimediaUuid: string) => {
    await axiosInstance.delete(`/supply/${supplyId}/multimedia/${multimediaUuid}`, { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
  };
  const removeOrganizationMultimedia = async(organizationId: string, multimediaUuid: string) => {
    await axiosInstance.delete(`/organization/${organizationId}/multimedia/${multimediaUuid}`, { headers: { authorization: keycloak.token ? 'Bearer ' + keycloak.token : undefined } });
  };

  const uploadOrganizationMultimedia = async(organizationId: string, file: File) => {
    return new Promise<string>((resolve, reject) => {
    // Create a FileReader to read the file data as ArrayBuffer
      const reader = new FileReader();
      reader.onload = async function() {
      // Create an ArrayBuffer from the file data
        const arrayBuffer = reader.result;

        try {
          // Make an Axios POST request with the binary data
          const req = await axiosInstance.post<string>(`/organization/${organizationId}/multimedia/`, arrayBuffer, {
            headers: {
              'Content-Type': 'image/jpeg',
              authorization:  keycloak.token ? 'Bearer ' + keycloak.token : undefined,
            },
          });
          resolve(req.data);
        } catch (e) {
          reject(e);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadSupplyMultimedia = async(supplyId: string, file: File) => {
    return new Promise<string>((resolve, reject) => {
    // Create a FileReader to read the file data as ArrayBuffer
      const reader = new FileReader();
      reader.onload = async function() {
      // Create an ArrayBuffer from the file data
        const arrayBuffer = reader.result;

        try {
          // Make an Axios POST request with the binary data
          const req = await axiosInstance.post<string>(`/supply/${supplyId}/multimedia/`, arrayBuffer, {
            headers: {
              'Content-Type': 'image/jpeg',
              authorization:  keycloak.token ? 'Bearer ' + keycloak.token : undefined,
            },
          });
          resolve(req.data);
        } catch (e) {
          reject(e);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const getSupplyMultimedia = (supplyId: string, multimediaUuid: string) => {
    return `/api/supply/${supplyId}/multimedia/${multimediaUuid}`;
  };

  const getOrganizationMultimedia = (supplyId: string, multimediaUuid: string) => {
    return `/api/organization/${supplyId}/multimedia/${multimediaUuid}`;
  };

  return {
    getOrganization,
    getOrganizations,
    getOrganizationMultimedia,
    updateOrganization,
    uploadOrganizationMultimedia,
    removeOrganizationMultimedia,
    createOrganization,
    removeOrganization,

    getCustomer,
    updateCustomer,
    registerCustomer,
    getCustomers,
    removeCustomer,
    getCustomersHaveNextPage,

    getSupplies,
    getSupplyMultimedia,
    uploadSupplyMultimedia,
    removeSupplyMultimedia,
    getSupply,
    createSupply,
    updateSupply,
    updateAuction,
    removeSupply,
    getAuction,
    createAuction,
    removeAuction,
    bidAuction,

    getOrganizationForms,
    getContractTypes,
    getObjectTypes,
    getCurrencyTypes,

    PasswordChange,
  };
};

export default useAPI;