import {
  Box,
  Button,
  CircularProgress, Container,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link as RouterLink } from 'react-router-dom';

import React from 'react';
import theme from '../../../theme';
import { useOnWindowResize } from 'rooks';
import useSupplies from '../../../Hooks/useSupplies';
import AuctionCard from '../../../AuctionCard/AuctionCard';

export default function CurrentAuctions() {
  const suppliesProps = React.useMemo(() => ({
    pageSize: 15,
    page:     0,
    filters:  ['auction-status=2', 'contract-type=1', 'contract-type=2'], // running auction
  }), []);
  const { data: auctions, loading } = useSupplies(suppliesProps);
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [key, setKey] = React.useState(Date.now());
  useOnWindowResize(() => setKey(Date.now()));

  return (
    <Container key={key}>
      { !loading && auctions.length > 0 && <>
        <Typography variant="h2" component="h2">Právě probíhající aukce</Typography>

        {loading && <Box sx={{
          height:       '420px',
          display:      'flex',
          alignItems:   'center',
          placeContent: 'center',
        }}>
          <CircularProgress/>
        </Box>}

        {!loading && <Swiper
          spaceBetween={20}
          touchEventsTarget='container'
          loop={(isMobile && auctions.length >= 3) || auctions.length >= 5}
          slidesPerView={isMobileBreakpoint
            ? 3
            : isMobile
              ? 3
              : 5}
          slidesPerGroup={isMobileBreakpoint
            ? 1
            : isMobile
              ? 3
              : 5}
          modules={[Navigation]}
          centeredSlides={isMobileBreakpoint}
          navigation={!isMobileBreakpoint}
          style={{
            padding:       isMobileBreakpoint ? 'inherit' : '20px',
            paddingBottom: '40px',
            paddingTop:    '20px',
            margin:        isMobile ? undefined : '-20px',
            marginLeft:    isMobileBreakpoint ? '-55vw' : isMobile ? undefined : '-20px',
            width:         isMobileBreakpoint ? '200vw' : 'inherit',
          }}
        >
          {auctions.map((supply) =>
            <SwiperSlide key={supply.uuid} style={{ paddingTop: '30px' }}>
              <AuctionCard supply={supply} disableHover={isMobile}/>
            </SwiperSlide>
          )}
        </Swiper>
        }

        <Box sx={{
          textAlign: 'center', pt: 4,  pb: isMobileBreakpoint ? 4 : 'inherit',
        }}>
          <Link component={RouterLink} to="/vsechny-nabidky">
            <Button color='secondary' variant='contained' sx={{
              height: '50px', width: '150px', borderRadius: '90px !important',
            }}>Všechny nabídky</Button>
          </Link>
        </Box>
      </>}
    </Container>);
}