import useAPI, { Supply } from './useAPI';
import React from 'react';
import useContractTypes from './useContractTypes';
import useCurrencyTypes from './useCurrencyTypes';
import useObjectTypes from './useObjectTypes';
import {
  maxLength, minLength, required,
} from '../InputValidator';

const rules = {
  title:                 [minLength(3), maxLength(100)],
  organizationUuid:      [required, minLength(1)],
  previewMultimediaUuid: [required, minLength(1)],
  description:           [maxLength(4000)],
  zipCode:               [required, minLength(1)],
  cityCode:              [required, minLength(1)],
  stateCode:             [required, minLength(1)],
  routeCode:             [required, minLength(1)],
  streetCode:            [required, minLength(1)],
  provinceCode:          [required, minLength(1)],
} as { [x in keyof (Supply)]: (typeof required)[]};

const insolvencyRules = {
  title:                 [minLength(3), maxLength(100)],
  organizationUuid:      [required, minLength(1)],
  previewMultimediaUuid: [required, minLength(1)],
  description:           [maxLength(4000)],
} as { [x in keyof (Supply)]: (typeof required)[]};

const useSupply = (uuid: string | undefined) => {
  const { getSupply, createSupply, updateSupply } = useAPI();

  const contractTypes = useContractTypes();
  const currencyTypes = useCurrencyTypes();
  const objectTypes = useObjectTypes();

  const [ data, setSupply ] = React.useState<Supply | null>(null);
  const [ loaded, setLoaded ] = React.useState<boolean | null>(false);
  const [ dirty, setDirty ] = React.useState<string[]>([]);

  const dataRef = React.useRef(data);
  React.useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const update = <K extends keyof Supply>(field: K, value: Supply[K]) => {
    setDirty(v => Array.from(new Set([...v, field])));

    // if we are updating contractType, reset objectType
    if (field === 'contractType') {
      setDirty(v => Array.from(new Set([...v, 'objectType'])));
    }
    setSupply((d) => {
      if (d) {
        if (field === 'contractType') {
          setDirty(v => Array.from(new Set([...v, 'objectType'])));
          return {
            ...d, [field]: value, objectType: objectTypes.filter(o => (value as Supply['contractType']).id === 3 ? o.id >= 10 : o.id < 10)[0],
          };
        }

        return {
          ...d, [field]: value,
        };
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    if (
      !uuid
      || contractTypes.length === 0
      || currencyTypes.length === 0
      || objectTypes.length === 0
    ) {
      return;
    }

    if (uuid === 'create') {
      setSupply({
        cityCode:              '',
        contractType:          contractTypes[0],
        currency:              currencyTypes[0],
        objectType:            objectTypes[0],
        createTimestamp:       new Date().toISOString(),
        updateTimestamp:       new Date().toISOString(),
        currentAuctionUuid:    '',
        description:           '',
        isPublic:              true,
        latitude:              0,
        longitude:             0,
        organizationUuid:      '',
        previewMultimediaUuid: '',
        provinceCode:          '',
        stateCode:             '',
        streetCode:            '',
        routeCode:             '',
        title:                 '',
        zipCode:               '',
        multimediaUuids:       [],
      });
      setLoaded(true);
    } else {
      getSupply(uuid)
        .then(v => {
          setSupply(v);
          setLoaded(true);
        })
        .catch(() => setLoaded(null));
    }
  }, [uuid, contractTypes, currencyTypes, objectTypes]);

  const assignAuction = (auctionId: string) => {
    return new Promise<Required<Supply>>((resolve, reject) => {
      console.log('assignAuction', dataRef.current, dataRef.current?.uuid);
      if (!dataRef.current || !dataRef.current.uuid) {
        return reject('Unknown supply ID');
      }
      updateSupply(dataRef.current.uuid!, { currentAuctionUuid: auctionId })
        .then(() => {
          setSupply(val => val ? {
            ...val,  currentAuctionUuid: auctionId,
          } : null);
          resolve(dataRef.current as Required<Supply>);
        });
    });
  };

  const assingPreviewMultimedia = () => {
    return new Promise<Required<Supply>>((resolve, reject) => {
      console.log('assingPreviewMultimedia', dataRef.current, dataRef.current?.uuid);
      if (!dataRef.current || !dataRef.current.uuid) {
        return reject('Unknown supply ID');
      }
      updateSupply(dataRef.current.uuid!, { previewMultimediaUuid: dataRef.current?.previewMultimediaUuid })
        .then(() => {
          resolve(dataRef.current as Required<Supply>);
        });
    });
  };

  const updateImages = (multimediaUuids: string[]) => {
    return new Promise<Required<Supply>>((resolve, reject) => {
      console.log('updateImages', dataRef.current, dataRef.current?.uuid);
      if (!dataRef.current || !dataRef.current.uuid) {
        return reject('Unknown supply ID');
      }
      updateSupply(dataRef.current.uuid!, { multimediaUuids })
        .then(() => {
          setSupply(val => val ? {
            ...val,  multimediaUuids,
          } : null);
          resolve(dataRef.current as Required<Supply>);
        });
    });
  };

  const save = () => {
    return new Promise<Required<Supply>>((resolve, reject) => {
      if (!dataRef.current) {
        return reject();
      }
      if (uuid === 'create') {
        createSupply(dataRef.current)
          .then((id) => {
            setSupply((val) => {
              if (!val) {
                reject();
                return null;
              }

              const newVal = {
                ...val,
                uuid: id,
              };
              resolve(newVal as Required<Supply>);
              return newVal;
            });
          });
      } else {
        updateSupply(uuid!, dataRef.current)
          .then(() => {
            resolve(dataRef.current as Required<Supply>);
          });
      }
    });
  };

  const currentRulesSet = data?.contractType.id === 3 ? insolvencyRules : rules;

  const validate = React.useCallback((field: keyof Supply, all = false): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    if ((!all &&!dirty.includes(field)) || !data) {
      return {}; // untouched
    }
    const value = data[field];
    for (const rule of currentRulesSet[field] ?? []) {
      const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
      if (result === true) {
        continue;
      }
      console.error('Field', field, 'validation fail for value', value);
      return result;
    }

    return {};
  }, [ dirty, data ]);

  const isValid = Object.keys(data ?? {}).map(field => {
    return validate(field as keyof Supply).error ?? false;
  }).filter(value => value).length === 0;

  const dirtifyAndValidate = () => Object.keys(currentRulesSet ?? {}).map(field => {
    setDirty(v => Array.from(new Set([...v, field])));
    return validate(field as keyof Supply, true).error ?? false;
  }).filter(value => value).length === 0;

  const appendMultimedia = (multimediaUuid: string) => {
    update('multimediaUuids', [...dataRef.current?.multimediaUuids ?? [], multimediaUuid]);
  };

  const refresh = () => {
    if (!uuid) {
      return;
    }

    getSupply(uuid)
      .then(v => {
        setSupply(v);
        setLoaded(true);
      })
      .catch(() => setLoaded(null));
  };

  return {
    data,
    loaded,
    update,
    validate,
    isValid,
    dirtifyAndValidate,
    save,
    assignAuction,
    updateImages,
    appendMultimedia,
    assingPreviewMultimedia,
    refresh,
  };
};

export default useSupply;
