import {
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import CopyButton from '../../../CopyButton';
import styles from './List.module.css';
import React from 'react';
import theme from '../../../theme';

export default function List() {
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container sx={{ pt: 5 }}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.greyItem}>
            <Grid container alignItems='center'>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Typography variant="h2" component="h2" className={styles.header}><span>Právní služby</span></Typography>
              </Grid>
              <Grid item xs={6}>
                <ul className={styles.list}>
                  <li>Smlouvy o zprostředkování</li>
                  <li>Rezervační smlouvy</li>
                  <li>Smlouvy o smlouvě budoucí</li>
                  <li>Kupní smlouvy a smlouvy o nájmu</li>
                  <li>Hypoteční smlouvy</li>
                  <li>Zástavní smlouvy</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={styles.redItem}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <ul className={styles.list}>
                  <li>Vhodné pro rozvodové, dědické i jiné případy</li>
                  <li>Vypočítáno z velkého množství dat</li>
                  <li>Zdarma a rychle</li>
                  <li>Včetně možnosti následného prodeje</li>
                </ul>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Typography variant="h2" component="h2" className={styles.header}>Odhad ceny nemovitosti</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: isMobile ? 0 : 5 }}>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.greyItem}>
            <Grid container alignItems='center'>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Typography variant="h2" component="h2" className={styles.header}><span>Hypoteční poradenství</span></Typography>
              </Grid>
              <Grid item xs={6}>
                <ul className={styles.list}>
                  <li>Financování nemovitosti na míru</li>
                  <li>Srovnání nabídky hypoték a vyjednávání lepších podmínek</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={styles.redItem}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <ul className={styles.list}>
                  <li>Smlouva o úschově</li>
                  <li>Úschova kupní ceny u spolehlivých notářů a advokátních kanceláří</li>
                </ul>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Typography variant="h2" component="h2" className={styles.header}>Úschova
                  {' '}
                  {!isMobileBreakpoint && <br/>}
                  {' '}
                  kupní
                  {' '}
                  {!isMobileBreakpoint && <br/>}
                  {' '}
                  ceny</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{
        py: isMobile ? undefined : 30,
        pb: isMobile ? undefined : 20,
        pt: isMobile ? 10 : 20,
      }}>
        <Typography variant="h2" component="h2"><span>Máte zájem o uvedené služby?</span></Typography>
        <Typography variant="h5" component="h5" sx={{ pt: 3 }}>Napište nám na uvedený e-mail nebo zavolejte.</Typography>

        <Box sx={{ mx: isMobileBreakpoint ? '-5vw' : undefined }}>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} sx={{
              backgroundColor: '#ededed',
              borderRadius:    isMobileBreakpoint
                ? undefined
                : isMobile
                  ? '30px'
                  : '30px 0 0 30px',
              p:          2,
              marginLeft: '-1px',
              textAlign:  'right',
            }}>
              <Stack direction='row' spacing={4} justifyContent={isMobile ? 'center' : 'right'} alignItems='center'>
                <Typography variant="h5" component="h5">
                  <Link component='a' href="mailto:info@inbido.cz" sx={{
                    color: 'black', fontWeight: 'normal !important',
                  }}>info@inbido.cz</Link>
                </Typography>
                {!isMobile && <CopyButton text='info@inbido.cz'/>}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{
              backgroundColor: '#e02804',
              color:           'white',
              p:               2,
              borderRadius:    isMobileBreakpoint
                ? undefined
                : isMobile
                  ? '30px'
                  : '0 30px 30px 0',
            }}>
              <Stack direction='row' spacing={4} justifyContent={isMobile ? 'center' : 'left'} alignItems='center'>
                {!isMobile && <CopyButton text='+420 777 799 645' color='light'/>}
                <Typography variant="h5" component="h5">
                  <Link component='a' href="tel:+420 777 799 645" sx={{
                    color: 'white', fontWeight: 'normal !important',
                  }}>+420 777 799 645</Link>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>);
}