import {
  Box, CircularProgress, Container, useMediaQuery,
} from '@mui/material';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './index.module.css';

import Detail from './Detail/Detail';
import Filters from './Filters/Filters';
import useAPI, {
  Organization, Paginator, SupplyLite,
} from '../../Hooks/useAPI';
import { PaginationStyled } from '../../PaginationStyled';
import React from 'react';
import theme from '../../theme';
import { Helmet } from 'react-helmet';
import AuctionCard from '../../AuctionCard/AuctionCard';
import { prepareFilter } from '../../prepareFilter';

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getOrganization, getSupplies } = useAPI();

  let { partnerId } = useParams();
  partnerId = (partnerId ?? '').split('.')[0];

  const [ organization, setOrganization ] = useState<null | Organization>(null);
  const [ auctions, setAuctions ] = useState<null | SupplyLite[]>(null);

  const [ paginator, setPaginator ] = useState<null | Paginator>(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [ page, setPage ] = React.useState(parseInt(query.get('stranka') || '1', 10));

  const [ filter, setFilter ] = useState<Record<string, [] | string>>({});

  const handleFilterChange = useCallback((value: typeof filter) => {
    setFilter(value);
    setPage(1);
  }, [ setFilter ]);

  useEffect(() => {
    if (partnerId) {
      getSupplies(16, page - 1, [
        `organization=${partnerId}`,
        `auction-status=2`,
        `auction-status=1`,
        `auction-status=3`,
        ...prepareFilter(filter),
      ])
        .then(data => {
          setAuctions(data.supplies);
          setPaginator(Math.max(data.pageCount, 1));
        });
    }
  }, [ page, filter ]);

  useEffect(() => {
    setAuctions(null);
    if (partnerId) {
      getOrganization(partnerId as string)
        .then(data => {
          setOrganization(data);
        });
    }
  }, [ partnerId, page, filter ]);

  return (<>
    {!organization
      ? <Box sx={{
        height:       '420px',
        display:      'flex',
        alignItems:   'center',
        placeContent: 'center',
      }}>
        <CircularProgress/>
      </Box>
      : <Container sx={{
        marginTop:     isMobile ? 0 : 1,
        paddingBottom: 1,
      }}>
        <Helmet>
          <link rel='canonical' href={window.location.origin}/>
          <title>Partner - {organization.name} - { process.env.REACT_APP_TITLE }</title>
        </Helmet>
        <Detail company={organization}/>

        <Filters onFilterChange={handleFilterChange}/>

        {!auctions
          ? <Box sx={{
            height:       '420px',
            display:      'flex',
            alignItems:   'center',
            placeContent: 'center',
          }}>
            <CircularProgress/>
          </Box>
          : <main className={styles.grid}>
            {
              auctions.map(auction => <AuctionCard supply={auction}/>)
            }
          </main>
        }
        {paginator && <PaginationStyled page={page} setPage={setPage} paginator={paginator}/>}
      </Container>
    }
  </>
  );
}
