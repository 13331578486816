import React from 'react';
import Header from './Header/Header';
import List from './List/List';
import { Helmet } from 'react-helmet';

export default function PagesDoplnkoveSluzby() {
  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Doplňkové služby - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Header/>
    <List/>
  </>
  );
}
