import {
  Box, CircularProgress, Container, Unstable_Grid2 as Grid, Typography, useMediaQuery,
} from '@mui/material';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../ChciAukci/Header/Header.module.css';
import styles_index from '../DetailPartnera/index.module.css';

import Filters from '../DetailPartnera/Filters/Filters';
import useAPI, { Paginator, SupplyLite } from '../../Hooks/useAPI';
import { PaginationStyled } from '../../PaginationStyled';
import React from 'react';
import theme from '../../theme';
import { Helmet } from 'react-helmet';
import AuctionCard from '../../AuctionCard/AuctionCard';
import { prepareFilter } from '../../prepareFilter';

type Props = {
  insolvency?: boolean;
};

const PagesVsechnyNabidky: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getSupplies } = useAPI();

  const [ auctions, setAuctions ] = useState<null | SupplyLite[]>(null);

  const [ paginator, setPaginator ] = useState<null | Paginator>(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [ page, setPage ] = React.useState(parseInt(query.get('stranka') || '1', 10));

  const [ filter, setFilter ] = useState<Record<string, [] | string>>({});

  const handleFilterChange = useCallback((value: typeof filter) => {
    setFilter(value);
    setPage(1);
  }, [ setFilter ]);

  useEffect(() => {
    let contractType = [...(filter['contract-type'] ?? []) as string[]];
    if (contractType.length === 0) {
      if (props.insolvency) {
        contractType = ['3'];
      } else {
        // by default we don't want insolvency type
        contractType = ['1', '2'];
      }
    }
    getSupplies(16, page - 1, [
      `auction-status=2`,
      `auction-status=1`,
      `auction-status=3`,
      ...prepareFilter({
        ...filter,
        'contract-type': contractType,
      }),
    ])
      .then(data => {
        setAuctions(data.supplies);
        setPaginator(Math.max(data.pageCount, 1));
      });
  }, [ page, filter ]);

  useEffect(() => {
    setAuctions(null);
  }, [ page, filter ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
  }}>
    <Helmet>
      <link rel='canonical' href={window.location.origin}/>
      <title>Všechny aukce - { process.env.REACT_APP_TITLE }</title>
    </Helmet>

    <Container sx={{
      marginTop:     isMobile ? 0 : 1,
      paddingBottom: 1,
    }}>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid md={8} lg={6.7} textAlign={'justify'}>
          <Typography variant="h1" component="h1" className={styles.header}>Všechny { props.insolvency ? 'insolvenční aukce' : 'nabídky' }</Typography>
        </Grid>
      </Grid>
    </Container>

    <Filters onFilterChange={handleFilterChange} insolvency={props.insolvency}/>

    {!auctions
      ? <Box sx={{
        height:       '420px',
        display:      'flex',
        alignItems:   'center',
        placeContent: 'center',
      }}>
        <CircularProgress/>
      </Box>
      : <main className={styles_index.grid}>
        {
          auctions.map(auction => <AuctionCard supply={auction}/>)
        }
      </main>
    }
    {paginator && <PaginationStyled page={page} setPage={setPage} paginator={paginator}/>}
  </Container>
  );
};

export default PagesVsechnyNabidky;