import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';

type Props = {
  percentage: number,
};

export default function ProgressBar({ percentage }: Props) {
  const [ limits ] = useState([40, 70]);

  return (<>
    <Box sx={{
      width: '100%', mt: 4,
    }}>
      <Box sx={{
        backgroundColor: 'red', width: `${Math.min(limits[0], percentage)}%`, height: '8px', display: 'inline-block',
      }}/>

      { percentage > limits[0] && <Box sx={{
        backgroundColor: 'orange', width: `${Math.min(percentage - limits[0], limits[1] - limits[0])}%`, height: '8px', display: 'inline-block',
      }}/>}

      { percentage > limits[1] && <Box sx={{
        backgroundColor: 'green', width: `${percentage - limits[1]}%`, height: '8px', display: 'inline-block',
      }}/>}
    </Box></>);
}