import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {
  Column,
  IntegratedPaging,
  PagingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {
  Table as ResponsiveTable, Tbody, Td, Th, Thead, Tr,
} from 'react-super-responsive-table';
import { chunk } from 'lodash';

import { pagingPanelMessages, tableHeaderMessages } from '../Grid/Localization';
import theme from '../theme';
import { ActionFormatter } from '../Grid/MemberActionProvider';
import { OrganizationOperator } from '../Hooks/useAPI';

export const Members: React.FC<{ members: OrganizationOperator[],
  removeOperatorByEmail: (memberEmail: string) => void,
  editOperatorByEmail: (memberEmail: string) => void
}> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // mobile pagination
  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [pageSizes] = React.useState([5, 10, 15, 25, 50, 0]);

  const columns: Column[] = [
    {
      name:  'email',
      title: 'Email',
    }, {
      name:  'firstName',
      title: 'Jméno',
    }, {
      name:  'lastName',
      title: 'Příjmení',
    }, {
      name:  'phoneNumber',
      title: 'Telefon',
    }, {
      name:         'actions',
      title:        'Akce',
      getCellValue: (row: any) => <ActionFormatter value={row} editOperatorByEmail={props.editOperatorByEmail} removeOperatorByEmail={props.removeOperatorByEmail}/>,
    },
  ];

  const GenericFormatter = React.useCallback((props2: { attr: string, value: string | boolean | string[], item: any }) => {
    if (['actions'].includes(props2.attr)) {
      return <ActionFormatter value={props2.item} editOperatorByEmail={props.editOperatorByEmail} removeOperatorByEmail={props.removeOperatorByEmail}/>;
    }
    return <>{props2.value}</>;
  }, []);

  return isMobile
    ? <Box sx={{ '& .responsiveTable td.pivoted': { paddingLeft: 'calc(20% + 10px) !important' } }}>
      <ResponsiveTable key={columns.length}>
        <Thead>
          <Tr>
            {columns.map(o => <Th key={o.title}>{o.title}</Th>)}
          </Tr>
        </Thead>
        <Tbody>
          {chunk(props.members, 5)[page - 1]?.map(o => <Tr key={o.uuid}>
            {columns.map(c => <Td aria-label={c.title}>
              <GenericFormatter item={o} attr={c.name} value={o[c.name as keyof typeof o] ?? ''}/>
            </Td>)}
          </Tr>
          )}
        </Tbody>
      </ResponsiveTable>

      <Pagination sx={{
        pt:                    2,
        '& .MuiPagination-ul': {
          p: '0 !important', justifyContent: 'center',
        },
        '& .MuiPaginationItem-page': { fontSize: '3vw !important' },
      }}  siblingCount={1} count={Math.ceil(props.members.length / 5)} page={page} onChange={handleChange} />
    </Box>
    : <Grid
      key={columns.length /* to forcefully refresh whole table dom */}
      rows={props.members}
      columns={columns}
    >
      <PagingState
        defaultCurrentPage={0}
        defaultPageSize={10}
      />
      <IntegratedPaging />
      <Table
        messages={{ noData: 'Žádní členové' }}
        columnExtensions={columns.map(o => ({
          columnName:      o.name,
          wordWrapEnabled: true,
          width:           o.name === 'actions' ? 100 : undefined,
          align:           o.name === 'actions' ? 'center' : undefined,
        }))}/>
      <TableHeaderRow messages={tableHeaderMessages} />
      <PagingPanel
        messages={pagingPanelMessages}
        pageSizes={pageSizes}
      />
    </Grid>;
};