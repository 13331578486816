import {
  Box, Container, Grid,
} from '@mui/material';

import styles from './AuctionCount.module.css';
import House from './house.svg';
import React from 'react';

export default function AuctionCount() {
  return (
    <Box sx={{ backgroundColor: '#ededed' }}>
      <Container sx={{
        p:             '0 !important',
        paddingBottom: 2,
        my:            15,
      }}>

        <Grid container spacing={0} justifyContent='space-between' sx={{ px: 5 }}>
          <Grid item xs={4} md={4} sm={12} sx={{
            pt: 8, textAlign: 'center',
          }}>
            <img width="300" src={House} alt=""/>
          </Grid>
          <Grid item xs={8} sm={12} md={8} className={styles.count}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                realizovaných aukcí
              </Grid>
              <Grid item xs={6}>
                prodáno za
              </Grid>
            </Grid>
            <Grid container sx={{ py: 6 }}>
              <Grid item xs={6}>
                <strong>100+</strong>
              </Grid>
              <Grid item xs={6}>
                <strong>200+ <br/><small>mil. Kč</small></strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>);
}
