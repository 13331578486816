import {
  Container, Link, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Header.module.css';
import Klokan from './Klokan.jpg';
import React from 'react';
import theme from '../../../theme';

export default function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container sx={{
      marginTop:     isMobile ? 0 : 1,
      paddingBottom: 1,
    }}>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid md={8} lg={6.7} textAlign={'justify'}>
          <Typography variant="h1" component="h1" className={styles.header}>Přidejte vaši nemovitost do <span>aukce!</span></Typography>
          <Typography variant="body1" sx={{ pt: isMobile ? '25px' : '50px' }}>Chcete přidat inzerát na INBIDO, ale nejste makléř nebo realitní kancelář?</Typography>
          <Typography variant="body1" sx={{ pt: '20px' }}><strong>Vyplňte formulář</strong> níže a my Vám inzerát nastavíme prostřednictvím jedné z našich partnerských realitních kanceláří.</Typography>
          <Typography variant="body1" sx={{ pt: '20px' }}>Nebo jste realitní kancelář, makléř, obec nebo developer? Poté si <Link component={RouterLink} to='/registrace-realitky-developer-obec'>vytvořte účet</Link> a přidejte své nemovitosti do aukce!</Typography>
        </Grid>
        {!isMobile && <Grid xs={12} lg={4} sx={{
          pt: '10px', position: 'relative', textAlign: 'center',
        }}>
          <img width="430" src={Klokan} alt=""/>
        </Grid>}
      </Grid>
    </Container>);
}