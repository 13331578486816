import {
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { MuiColorInput } from 'mui-color-input';

export default function PagesAdministraceZadavatelePreview() {
  const [ bgColor, setBgColor ] = React.useState('#ffffff');
  const [ cardColor, setCardColor ] = React.useState('#dfdcdc');

  const { partnerId } = useParams();

  const generatedIframe = React.useMemo(() => {
    return `<iframe src="${location.origin}/detail-partnera/${partnerId}?iframe=1&bgcolor=${bgColor.replace('#', '')}&cardcolor=${cardColor.replace('#', '')}" frameborder="0" width="100%" height="100%">`;
  }, [partnerId, bgColor, cardColor]);

  return (<Container sx={{
    p: '30px !important', mt: 1,
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Administrace - vygenerovat náhled - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1" sx={{ pl: '60px' }}>Administrace - Vygenerovat <span>náhled</span></Typography>

    <Stack spacing={1} sx={{
      py: 5, width: '50%', margin: 'auto',
    }}>
      <MuiColorInput
        label="Barva pozadí"
        fullWidth
        isAlphaHidden
        format="hex"
        value={bgColor}
        onChange={(_, value) => setBgColor(value.hex)} />

      <MuiColorInput
        label="Barva karty"
        fullWidth
        isAlphaHidden
        format="hex"
        value={cardColor}
        onChange={(_, value) => setCardColor(value.hex)} />

      <TextField label="Vygenerovaný kód" value={generatedIframe} multiline InputProps={{ readOnly: true }}/>
    </Stack>
    <Box sx={{
      width: '100%', display: 'flex', placeContent: 'center', height: '500px',
    }}>
      <iframe src={`${location.origin}/detail-partnera/${partnerId}?iframe=1&bgcolor=${bgColor.replace('#', '')}&cardcolor=${cardColor.replace('#', '')}`} frameBorder={0} width="100%" height="100%"/>
    </Box>
  </Container>
  );
}