import React from 'react';
import FAQ from '../Index/FAQ/FAQ';
import Header from './Header/Header';
import AllAuctionsList from '../../AllAuctionsList/AllAuctionsList';
import { Helmet } from 'react-helmet';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function PagesChciKoupit() {
  const questions = [
    {
      q: 'Může prodávající zadat nemovitost přímo do aukce?',
      a: [
        <Typography>
          Vložení nemovitosti je možné pouze prostřednicvím některých z našich partnerských realitek. Stačí
          nás kontaktovat prostřednictvím <Link href="/chci-aukci">formuláře</Link> a rádi Vám kontakt
          zprostředkujeme.
        </Typography>,
      ],
    },
    {
      q: 'Jak lze prodávat nemovitost přes INBIDO?',
      a: [
        <Typography>
          Zadavatel může zadat nemovitosti do aukce buď sám vložením nemovitosti v rámci svého účtu nebo
          prostřednictvím týmu provozovatele INBIDO. Pokud neméte přistupové údaje a máta zájem o spolupráci,
          tak stačí zaslat požadavek na <Link href="/registrace-realitky-developer-obec">registraci</Link>
        </Typography>,
      ],
    },
    {
      q: 'Jak se registrovat a účastnit se aukce?',
      a: [
        <Typography>
          Zaregistrovat se na portálu prostřednictvím <Link href="/registrace-ucastnika-aukce">formuláře</Link>,
          aktivovat svůj účet a přihlásit se do aukce. Účastnit se může každá osoba způsobilá k nákupu nemovitostí
          ve smyslu občanského zákoníku. Nezbytnou podmínkou registrace je souhlas s obchodními podmínkami.
        </Typography>,
      ],
    },
    {
      q: 'Jak dá Zadavatel vědět zájemcům, že se mají účastnit aukce?',
      a: [
        'Postačí, když zájemcům (kteří se např. účastnili prohlídky nemovitosti) pošle Zadavatel e-mailem, sms, v chatu (messenger, whats app, viber apod.) odkaz na danou aukci s tím, aby se zaregistrovali a účastnili aukce.',
      ],
    },
    {
      q: 'Co vše lze zadat do aukce?',
      a: [
        'Do aukce lze zadat byty, domy, pozemky, bytové domy, developerské projekty, sklady, garáže, atd.',
      ],
    },
    {
      q: 'Kolik je minimální příhoz?',
      a: [
        'U prodeje nemovitostí je minimální výše příhozu 10 000,-Kč, a maximální 1 000 000,- Kč, vždy však zaokrouhleno na 1000 Kč.',
      ],
    },
  ];
  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Chci koupit - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Header/>
    <AllAuctionsList filters={['contract-type=1']}/>
    <FAQ sx={{ pt: 2 }} questions={questions}/>
  </>
  );
}
