import * as React from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { encode } from 'pluscodes';
import { places } from './PlaceIdToAddress';

(window as any).logInit = () => {
  console.log('Google API loaded.');
};

const autocompleteService = { current: null } as {current: null | google.maps.places.AutocompleteService};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  types: string[],
  place_id: string;
  structured_formatting: StructuredFormatting;
}

type Props = {
  value?: string;
  search?: boolean;
  label: string;
  error?: boolean;
  helperText?: string;
  types?: string[];
  color?: TextFieldProps['color'];
  onChange(placeId: string, plusCode: string, description: string, address: {
    street: string | undefined;
    street_address: string | undefined;
    city: string | undefined;
    province: string | undefined;
    zip: string | undefined;
    country: string | undefined;
  }): void;
};

export default function GoogleMapsInput({ value, onChange, label, error, helperText, color, types, search }: Props ) {
  const [valuePlace, setValuePlace] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (valuePlace) {
      const request = {
        placeId: valuePlace.place_id!, language: 'cs',
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(request, (data) => {
        if (!data) {
          return;
        }

        const lat = data.geometry?.location?.lat();
        const lng = data.geometry?.location?.lng();
        if (!lat || !lng) {
          return;
        }

        const geocoder = new google.maps.Geocoder();

        geocoder
          .geocode({
            location: {
              lat, lng,
            },
            language: 'cs',
          })
          .then(async ({ results }) => {
            // first we need to reverse array, so we have wide positions first
            results.reverse();

            const address: {
              street: string | undefined;
              street_address: string | undefined;
              city: string | undefined;
              province: string | undefined;
              zip: string | undefined;
              country: string | undefined;
            } = {
              street:         undefined,
              street_address: undefined,
              city:           undefined,
              province:       undefined,
              zip:            undefined,
              country:        undefined,
            };

            const isCountry = (val: string[]) => val.includes('country');
            const isProvince = (val: string[]) => val.includes('administrative_area_level_1');

            const city = results.find(o => (!isCountry(o.types) && !isProvince(o.types) && (o.types.includes('political') || (!o.types.includes('political') && o.types.includes('locality')))))?.place_id ?? '';
            const zip = results.find(o => o.types.includes('postal_code'))?.place_id;
            const streetFormattedAddress = results.find(o => o.types.includes('route'))?.formatted_address ?? '';
            let street = results.find(o => o.types.includes('route'))?.place_id ?? '';
            const country = results.find(o => o.types.includes('country'))?.place_id;
            const street_address = results.find(o => o.types.includes('street_address') && o.geometry.location_type === 'ROOFTOP')?.place_id;

            if(streetFormattedAddress.length > 0) {
              street = await new Promise(resolve => {
                autocompleteService.current?.getPlacePredictions({ input: streetFormattedAddress }).then(data2 => {
                  if (data2.predictions.length > 0) {
                    resolve(data2.predictions[0].place_id);
                  } else {
                    resolve(street);
                  }
                });
              });
            }

            const province = results.find(o => isProvince(o.types))?.place_id;

            console.debug('Place type:', valuePlace.types[0]);
            if (search) {
              if (valuePlace.types[0] === 'street_address') {
                address.street_address = street_address;
              }

              if (valuePlace.types[0] === 'country') {
                address.country = country;
              }

              if (valuePlace.types[0] === 'postal_code') {
                address.zip = zip;
              }

              if (valuePlace.types[0] === 'route') {
                address.street = street;
              }

              if (valuePlace.types[0] === 'locality' || valuePlace.types[0] === 'sublocality_level_1') {
              // CITY
                address.city = city;
              }

              if (valuePlace.types[0].includes('sublocality')) {
                address.city = city;
              }

              if (valuePlace.types[0].includes('administrative_area_level')) {
                address.province = province;
              }

            } else {
              address.street = street;
              address.street_address = street_address;
              address.city = city;
              address.province = province;
              address.zip = zip;
              address.country = country;
            }
            onChange(valuePlace.place_id!,
              encode({
                latitude:  lat,
                longitude: lng,
              })!,
              valuePlace.description,
              address,
            );
          })
          .catch((e) => {
            console.error('Geocoder failed due to: ' + e);
          });
      });
    }
  }, [valuePlace]);

  const getInitialData = () => {
    if (typeof window.google !== 'undefined') {
      const request = {
        placeId: value!, language: 'cs',
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(request, (data) => {
        if (data?.formatted_address) {
          setInputValue(data.formatted_address);
          setValuePlace({
            description:           data.formatted_address,
            place_id:              data.place_id!,
            types:                 [],
            structured_formatting: {
              main_text:                    '',
              secondary_text:               '',
              main_text_matched_substrings: [],
            },
          });
        }
      });
    } else {
      setTimeout(() => getInitialData(), 100);
    }
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (value && value.length > 0) {
      getInitialData();
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string, language: 'cs', types: string[] },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      places.then(val => {
        autocompleteService.current = new val.AutocompleteService();
      });
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(valuePlace ? [valuePlace] : []);
      return undefined;
    }

    fetch({
      input: inputValue, language: 'cs', types: types ?? ['address'],
    }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (valuePlace) {
          newOptions = [valuePlace];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [valuePlace, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      fullWidth
      color={color}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={valuePlace}
      noOptionsText="Nenalezena žádná adresa"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValuePlace(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} color={color} required fullWidth variant='standard' error={error} helperText={helperText} />
      )}
      renderOption={(props, option) => {
        const matches
          = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{
                display: 'flex', width: 44,
              }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{
                width: 'calc(100% - 44px)', wordWrap: 'break-word',
              }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}