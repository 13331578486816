import React from 'react';
import ObchodniPodminky from '../../ObchodniPodminky/ObchodniPodminky';
import { Helmet } from 'react-helmet';

export default function PagesObchodniPodminky() {
  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Obchodní podmínky - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <ObchodniPodminky/>
  </>
  );
}