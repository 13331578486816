import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HTMLReactParser from 'html-react-parser';
import { useState } from 'react';
import {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { colors } from '../../../Chips/Chip';
import useAPI, { Organization, Supply } from '../../../Hooks/useAPI';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../../theme';
import { useOnWindowResize } from 'rooks';
import { useQuery } from '../../../Hooks/useQuery';
import { PlaceIdToAddress } from '../../../PlaceIdToAddress';
import { PromiseValue } from '../../../PromiseValue';

type Props = {
  supply: Supply
  customer: Organization
};

export default function Nemovitost({ customer, supply }: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const params = useQuery();

  const { getSupplyMultimedia } = useAPI();

  const [key, setKey] = React.useState(Date.now());
  useOnWindowResize(() => setKey(Date.now()));

  const [ showDescription, setShowDescription ] = useState(true);

  return (<>
    <Box key={key} sx={{
      height:                         '100%',
      position:                       'relative',
      userSelect:                     'none !important',
      '& .swiper-pagination-bullets': {
        position: 'relative',
        top:      '0px',
      },
      '& .swiper-pagination-bullet': {
        backgroundColor: 'black', opacity: 1, transition: 'all 100ms', mx: '10px !important', transform: 'scale(1.2)',
      },
      '& .swiper-slide': {
        backgroundColor: 'white', alignSelf: 'center', display: 'flex', opacity: '0 !important',
      },
      '& .swiper-slide-active': { opacity: '1 !important' },
      '& .swiper-slide img':    {
        marginTop:    'auto',
        marginBottom: 'auto',
        display:      'block',
        width:        '100%',
        height:       '100%',
        objectFit:    'cover',
      },
      '& .swiper-pagination-bullet-active': { transform: 'scale(2.0)' },
      '& .swiper-3d':                       { overflow: 'inherit' },
      '& .swiper-button-prev::after':       {
        border:          '1px solid white',
        borderRadius:    '100px',
        backgroundColor: 'black',
        color:           'white',
        p:               1,
        px:              1.2,
        fontSize:        '10px !important',
        position:        'relative',
        left:            '12px',
      },
      '& .swiper-button-next::after': {
        border:          '1px solid white',
        borderRadius:    '100px',
        backgroundColor: 'black',
        color:           'white',
        p:               1,
        px:              1.2,
        fontSize:        '10px !important',
        position:        'relative',
        left:            '-12px',
      },
    }}>
      <Box sx={{
        pb: 4, pt: 1,
      }}>
        <Link component={RouterLink} to={`/detail-partnera/${customer.uuid!}`} target={params.get('iframe') ? '_blank' : undefined}>
          <Button variant='contained' size='large' sx={{ backgroundColor: colors[customer.form.label as keyof typeof colors] }}>{customer.name}<Box sx={{
            pl: 2, alignSelf: 'center', display: 'flex',
          }}><KeyboardDoubleArrowRightIcon/></Box></Button>
        </Link>
      </Box>

      <Swiper
        loop={true}
        autoplay={{
          delay: 100000, disableOnInteraction: false, pauseOnMouseEnter: false, waitForTransition: false,
        }}
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        effect={'fade'}
        pagination={{ clickable: true }}
        navigation={true}
      >
        {(supply.multimediaUuids ?? []).map((image, idx) => <SwiperSlide key={image}>
          <Box sx={{
            maxHeight: '350px', width: '100%', aspectRatio: '484/328',
          }}>
            <img style={{
              width: '100%', objectFit: 'contain',
            }} alt={`${supply.title} - image ${idx + 1}`} src={getSupplyMultimedia(supply.uuid!, image)} />
          </Box>
        </SwiperSlide>)}
      </Swiper>

      <Stack spacing={1} pt={2}>
        {supply.streetCode.length > 0 && <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Adresa:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>
            <PromiseValue formatter={(val) => val.split(',')[0]} promise={PlaceIdToAddress(supply.streetCode)} error='Došlo k chybě při překladu adresy'/>
            {', '}
            <PromiseValue formatter={(val) => val.split(',')[0]} promise={PlaceIdToAddress(supply.cityCode)} error='Došlo k chybě při překladu adresy'/>
          </Typography>
        </Stack>}

        {supply.streetCode.length > 0 && <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Kraj:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}><PromiseValue formatter={(val) => val.split(',')[0]} promise={PlaceIdToAddress(supply.provinceCode)} error='Došlo k chybě při překladu adresy'/></Typography>
        </Stack>}

        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Typ {supply.contractType.id === 3 ? 'majetku' : 'nemovitosti'}:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>{ supply.objectType.label }</Typography>
        </Stack>
      </Stack>

      <Collapse in={showDescription}>
        <Typography sx={{ fontWeight: 500 }} component='span'>{HTMLReactParser(supply.description)}</Typography>
      </Collapse>
    </Box>

    <Box sx={isMobile ? {} : {
      textAlign: 'center', transform: 'translateY(-4px)',
    }}>
      <Button variant='contained' color='secondary' onClick={() => setShowDescription(!showDescription)} sx={{ transition: 'all 100ms' }}>
        {showDescription ? 'Méně' : 'Více'} o {supply.contractType.id === 3 ? 'majetku' : 'nemovitosti'}</Button>
    </Box>
  </>);
}
