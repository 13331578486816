export const prepareFilter = (filter: Record<string, any>) => {
  return Object.keys(filter)
    .map(key => {
      const value = filter[key as keyof typeof filter];
      if (Array.isArray(value)) {
        return `${value.map(o => `${key}=${o}`).filter(o => !o.endsWith('=')).join('&')}`;
      } else {
        if (value === '') {
          return '';
        }
        return `${key}=${value}`;
      }
    });
};