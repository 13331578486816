import {
  Box, Button, Container, Link, Stack, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Icon from './Icon.png';
import styles from './WhyAuctionsBullets.module.css';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';

export default function WhyAuctionsBullets() {
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const items = [
    'Zdarma a online',
    'Bez jistiny',
    'Veřejné i neveřejné',
    'Prodej i nájem',
  ];

  return (
    <Container sx={{
      px: '0px !important', pb: 5,
    }}>
      {isMobile
        ? <Grid container>
          <Grid xs={12} md={9} mdOffset={1} sx={{
            mx: isMobile
              ? '30%'
              : undefined,
          }}>
            <Stack spacing="30px" justifyContent='center' sx={{ py: 5 }}>
              {items.map(item => <Stack key={item} direction='row' alignItems="end" spacing='12px'>
                <img src={Icon} width={38} alt=""/>
                <Typography className={styles.text}>{item}</Typography>
              </Stack>)}
            </Stack>
          </Grid>
        </Grid>
        : <Stack direction='row' spacing="60px" justifyContent='center' sx={{ py: 5 }}>
          {items.map(item => <Stack key={item} direction='row' alignItems="end" spacing='12px'>
            <img src={Icon} width={38} alt=""/>
            <Typography className={styles.text}>{item}</Typography>
          </Stack>)}
        </Stack>
      }

      <Box sx={{
        mt: isMobileBreakpoint ? '30px' : '60px', textAlign: 'center',
      }}>
        <Typography className={styles.title}>Najděte si aukci, registrujte se
          {' '}{ isMobileBreakpoint && <br/> } a jdeme na to!</Typography>
      </Box>

      <Box sx={{
        mt: '-25px', textAlign: 'center',
      }}>
        <Link component={RouterLink} to="/registrace-ucastnika-aukce">
          <Button variant="contained" color="secondary" sx={{
            width: '180px', height: isMobileBreakpoint ? '35px': '50px', borderRadius: '100px !important',
          }}>Registrujte se</Button>
        </Link>
      </Box>
    </Container>);
}