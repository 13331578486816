import { Container, useMediaQuery } from '@mui/material';

import TopBar from './TopBar';
import React from 'react';
import TopBarMobile from './TopBarMobile';
import theme from '../theme';

export default function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      { isMobile
        ? <TopBarMobile/>
        : <Container maxWidth={false} sx={{
          zIndex:          999,
          position:        'sticky',
          top:             0,
          backgroundColor: 'white',
          padding:         '0 !important',
          borderBottom:    '1px solid #e8ede5',
          width:           '100% !important',
          maxWidth:        '100% !important',
        }}>
          <TopBar/>
        </Container>
      }
    </>);
}