import useAPI, { Form } from './useAPI';
import React from 'react';

const useContractTypes = () => {
  const { getContractTypes } = useAPI();

  const [ contractTypes, setContractTypes ] = React.useState<Form[]>([]);

  React.useEffect(() => {
    const cache = sessionStorage.contractTypes;
    if (cache) {
      setContractTypes(JSON.parse(cache));
    } else {
      getContractTypes().then((data) => {
        setContractTypes(data);
        sessionStorage.contractTypes = JSON.stringify(data);
      });
    }
  }, []);

  return contractTypes;
};

export default useContractTypes;
