import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import makeStore from './store/store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import PagesIndex from './Pages/Index';
import PagesChciAukci from './Pages/ChciAukci/chci-aukci';
import Root from './Pages/Root/root';
import PagesChciKoupit from './Pages/ChciKoupit/chci-koupit';
import PagesChciNajem from './Pages/ChciNajem/chci-najem';
import PagesZadavatele from './Pages/Zadavatele/index';
import PagesDoplnkoveSluzby from './Pages/DoplnkoveSluzby/doplnkove-sluzby';
import PagesONas from './Pages/ONas/o-nas';
import PagesKontakty from './Pages/Kontakty/kontakty';
import PagesDetailAukce from './Pages/DetailAukce';
import PagesDetailPartnera from './Pages/DetailPartnera';
import PagesZmenaUdaju from './Pages/ZmenaUdaju';
import PagesCasteDotazy from './Pages/CasteDotazy/caste-dotazy';
import PagesRegistraceUcastnikaAukce from './Pages/RegistraceUcastnikaAukce';
import PagesRegistraceRealitkyDeveloperObec from './Pages/RegistraceRealitkyDeveloperObec';
import PagesAdministraceZadavateleEdit from './Pages/Administrace/Zadavatele/[partnerId]';
import PagesAdministraceAukce from './Pages/Administrace/Aukce';
import PagesAdministraceBids from './Pages/Administrace/Aukce/indexBids';
import PagesAdministraceAukceEdit from './Pages/Administrace/Aukce/indexAukce';
import PagesObchodniPodminky from './Pages/ObchodniPodminky/obchodni-podminky';
import PagesAdministracePrihazujici from './Pages/Administrace/Prihazujici';
import PagesAdministraceZadavatele from './Pages/Administrace/Zadavatele';
import PagesAdministraceZadavatelePreview from './Pages/Administrace/Zadavatele/preview';

import 'dayjs/locale/cs';
import './global.scss';
import PagesVsechnyNabidky from './Pages/VsechnyNabidky';

const store = makeStore();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

document.title = 'INBIDO.cz';

const router = createBrowserRouter([
  {
    path:     '/',
    element:  <Root/>,
    children: [
      {
        path: '/', element: <PagesIndex/>,
      }, {
        path:    '/chci-aukci',
        element: <PagesChciAukci/>,
      }, {
        path:    '/chci-koupit',
        element: <PagesChciKoupit/>,
      }, {
        path:    '/vsechny-nabidky',
        element: <PagesVsechnyNabidky/>,
      }, {
        path:    '/vsechny-insolvencni-aukce',
        element: <PagesVsechnyNabidky insolvency/>,
      }, {
        path:    '/chci-najem',
        element: <PagesChciNajem/>,
      }, {
        path:    '/zadavatele',
        element: <PagesZadavatele/>,
      }, {
        path:    '/doplnkove-sluzby',
        element: <PagesDoplnkoveSluzby/>,
      }, {
        path:    '/obchodni-podminky',
        element: <PagesObchodniPodminky/>,
      }, {
        path:    '/o-nas',
        element: <PagesONas/>,
      }, {
        path:    '/kontakty',
        element: <PagesKontakty/>,
      }, {
        path:    '/partner/:partnerId/aukce/:aukceId',
        element: <PagesDetailAukce/>,
      }, {
        // backward compatibility with old links
        path:    '/:partnerId/list/:aukceId',
        element: <PagesDetailAukce/>,
      }, {
        path:    '/detail-partnera/:partnerId',
        element: <PagesDetailPartnera/>,
      }, {
        path:    '/zmena-udaju',
        element: <PagesZmenaUdaju/>,
      }, {
        path:    '/caste-dotazy',
        element: <PagesCasteDotazy/>,
      }, {
        path:    '/registrace-ucastnika-aukce',
        element: <PagesRegistraceUcastnikaAukce/>,
      }, {
        path:    '/registrace-realitky-developer-obec',
        element: <PagesRegistraceRealitkyDeveloperObec/>,
      }, {
        path:    '/administrace/aukce',
        element: <PagesAdministraceAukce/>,
      }, {
        path:    '/administrace/aukce/:supplyId',
        element: <PagesAdministraceAukceEdit/>,
      }, {
        path:    '/administrace/aukce/:supplyId/bids',
        element: <PagesAdministraceBids/>,
      }, {
        path:    '/administrace/prihazujici',
        element: <PagesAdministracePrihazujici/>,
      }, {
        path:    '/administrace/prihazujici/:bidderId',
        element: <PagesZmenaUdaju/>,
      }, {
        path:    '/administrace/zadavatele',
        element: <PagesAdministraceZadavatele/>,
      }, {
        path:    '/administrace/zadavatele/:partnerId',
        element: <PagesAdministraceZadavateleEdit/>,
      }, {
        path:    '/administrace/zadavatele/:partnerId/preview',
        element: <PagesAdministraceZadavatelePreview/>,
      },
    ],
  },
]);

root.render(
  <StyledEngineProvider injectFirst>
    <ReactKeycloakProvider authClient={keycloak} initOptions={{ pkceMethod: 'S256' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />

              <RouterProvider router={router} />
            </ThemeProvider>
          </Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ReactKeycloakProvider>
  </StyledEngineProvider>,
);
