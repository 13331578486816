import {
  Container, Grid as Grid, Typography, useMediaQuery,
} from '@mui/material';

import styles from './ObchodniPodminky.module.css';
import React from 'react';
import theme from '../theme';

type Props = {
  dialog?: boolean
};

export default function ObchodniPodminky({ dialog } : Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (<><Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            dialog ? 'inherit' : '30px',
  }}>
    <Typography variant={ dialog ? 'h4' : 'h1'} component="h1" sx={{ fontWeight: 'bold' }}>Všeobecné obchodní podmínky aukčního nástroje INBIDO, platné od <span>1.11.2023</span>.</Typography>
  </Container>
  <Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <ol className={styles.list}>
      <li>
        <ol className={styles.main}>
          <li><strong>Úvodní ustanovení</strong>
            <ol>
              <li>Tyto Všeobecné obchodní podmínky upravují pravidla užívání aukčního systému společnosti INBIDO
                  s.r.o. na internetové adrese www.inbido.cz a zároveň také podmínky účasti jednotlivých účastníků na
                  elektronických aukcích konaných v aukčním systému.</li>
              <li>Označením pole „Souhlasím s Obchodními podmínkami“ osoba uděluje souhlas s následujícím zněním
Všeobecných obchodních podmínek, které jsou současně s registrací smlouvou o účasti v elektronické
aukci.</li>
            </ol>
          </li>
          <li><strong>Základní pojmy</strong>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Aukční systém“</Grid>
              <Grid xs={12} md={9}>systém umožňující aukce pod doménou www.inbido.cz</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Detail zadavatele“</Grid>
              <Grid xs={12} md={9}>podstránka aukčního systému, na které jsou identifikační údaje realitní kanceláře/developera/obce a aukcí, které realizuje.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Délka aukce“</Grid>
              <Grid xs={12} md={9}>doba od začátku aukce do jejího skončení a je dle volby zadavatele buď 1 / 3 / 7 / 14 / 30 dnů.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Detail aukce“</Grid>
              <Grid xs={12} md={9}>podstránka aukčního systému, na které jsou bližší podmínky konkrétní aukce a kde dochází k realizaci aukce.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Aukce“</Grid>
              <Grid xs={12} md={9}>elektronická aukce v podobě veřejné soutěž o nejvhodnější nabídku týkající se zájmu o jednání o koupi či nájmu nemovitosti, která je uskutečněna prostřednictvím aukčního portálu umístěného pod doménou www.inbido.cz.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Heslo“</Grid>
              <Grid xs={12} md={9}>unikátní ochranný prvek pro přístup uživatele a zadavatele do aukčního systému.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Maximální příhoz“</Grid>
              <Grid xs={12} md={9}>maximální finanční částka, o kterou je možné navýšení poslední platné nabídky nabídky (1 mil Kč u prodeje nemovitostí a 1000 Kč u nájmu nemovitostí, nebo přibližný ekvivalent v zahraniční měně).</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Minimální příhoz“</Grid>
              <Grid xs={12} md={9}>minimální finanční částka, o kterou je možné navýšení poslední platné nabídky (10 tis Kč u prodeje nemovitostí a 100 Kč u nájmu nemovitostí, nebo přibližný ekvivalent v zahraniční měně).</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Maximální příhoz v insolvenci“</Grid>
              <Grid xs={12} md={9}>maximální finanční částka, o kterou je možné navýšení poslední platné nabídky nabídky (1 mil Kč u prodeje Předmětu aukce kategorie A a 100 tis Kč u prodeje Předmětu aukce kategorie B, nebo přibližný ekvivalent v zahraniční měně).</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Minimální příhoz v insolvenci“</Grid>
              <Grid xs={12} md={9}>minimální finanční částka, o kterou je možné navýšení poslední platné nabídky (1 tis Kč u prodeje Předmětu aukce kategorie A a 100 Kč u prodeje Předmětu aukce kategorie B, nebo přibližný ekvivalent v zahraniční měně).</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Obchodní podmínky“</Grid>
              <Grid xs={12} md={9}>Všeobecné obchodní podmínky užívání aukčního systému.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Provozovatel“</Grid>
              <Grid xs={12} md={9}>společnost INBIDO s.r.o., se sídlem Jurečkova 1811/18, Moravská Ostrava, 702 00
Ostrava, zapsaná v obchodním rejstříku vedeného Krajským soudem v Ostravě, oddíl
C, vložka 85115, IČ: 10667172 poskytující aukční systém na internetové adrese
www.inbido.cz umožňující vyhlásit účastníkům aukce soutěž o nejvhodnější nabídku
na vyjednávání o koupi či nájmu nemovitosti se zadavatelem.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Předmět aukce“</Grid>
              <Grid xs={12} md={9}>možnost vyjednávání o koupi či nájmu nemovitosti se zadavatelem.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Zadavatel“</Grid>
              <Grid xs={12} md={9}>realitní kancelář/makléř/developer/obec/kraj/insolvenční správce, která má v úmyslu
vybrat nejvhodnějšího zájemce o prodej či nájem nemovitosti prostřednictvím aukce
a jež je oprávněn k jednání o prodeji či nájmu nemovitosti.
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Účastník aukce“</Grid>
              <Grid xs={12} md={9}>fyzická nebo právnická osoba, která se zaregistrovala do aukčního systému na
internetové adrese www.inbido.cz a která souhlasila s obchodními podmínkami.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Účet“</Grid>
              <Grid xs={12} md={9}>účet, který má zřízen účastník aukce i zadavatel po registraci a do něhož se přihlašují
emailem a heslem. Pouze po přihlášení je možné do aukčního systému zadávat detaily
aukce a účastnit se aukce. Nelze zřídit více účtů pod stejným emailem nebo
telefonním číslem.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>Veřejná aukce</Grid>
              <Grid xs={12} md={9}>Aukce, která probíhá veřejně na internetové adrese
          www.inbido.cz.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>Neveřejná aukce</Grid>
              <Grid xs={12} md={9}>Aukce, která probíhá neveřejně v rámci Aukčního systému a do které mají přístup
pouze Účastníci, kterým Zadavatel poslal odkaz.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Vítěz aukce“</Grid>
              <Grid xs={12} md={9}>účastník aukce, který učinil v dané aukci nejvyšší nabídku a která jej opravňuje
vyjednávat se zadavatelem o prodeji či nájmu nemovitosti.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„Vyvolávací cena“</Grid>
              <Grid xs={12} md={9}>základní finanční částka kupní ceny či výše nájmu, od které se postupuje v aukci
prostřednictvím jednotlivých příhozů.</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} md={3} sx={{ fontWeight: 'bold' }}>„TOP nabídka“</Grid>
              <Grid xs={12} md={9}>podstránka aukčního systému, na které jsou zobrazované zadavatelem označené
aukce.</Grid>
            </Grid>
          </li>
          <li><strong>Aukce</strong>
            <ol>
              <li>Na Aukci se nevztahují ustanovení zákona č. 26/2000 Sb., o veřejných dražbách.</li>
              <li>Aukce probíhá pod doménou www.inbido.cz.</li>
              <li>Aukce probíhá formou tzv. anglické aukce, tzn. od Vyvolávací ceny jednotlivými příhozy směrem nahoru.</li>
              <li>Složení jistoty se nevyžaduje.</li>
              <li>Zadavatel uzavírá smlouvu o účasti v Aukci tím, že stanoví všechny podmínky v Detailu aukce a Účastník tím, že učiní u konkrétní aukce příhoz.</li>
              <li>Účastník aukce se Aukce účastní v anonymizované podobě po přihlášení do svého Účtu, kdy je jemu Provozovatelem přiděleno identifikační číslo.</li>
              <li>Vítězem aukce se stane Účastník aukce, který nabídne nejvyšší cenu. Vítěz aukce je oprávněn vyjednávat se Zadavatelem o uzavření smlouvy o  smlouvě budoucí, kupní smlouvy či smlouvy o nájmu.</li>
              <li>Aukce je úspěšná, pokud došlo alespoň k jednomu příhozu. Pokud nedošlo k příhozu, pak může Zadavatel realizovat opakovanou Aukci s nižší vyvolávací cenou.</li>

              <li>Zadavatel v Detailu aukce stanoví zejména:
                <ul style={{ listStyleType: 'disc' }}>
                  <li>Dobu zahájení Aukce;</li>
                  <li>Dobu trvání Aukce;</li>
                  <li>Vyvolávací cenu;</li>
                  <li>Zda Aukce bude probíhat jako Veřejná aukce či Neveřejná;</li>
                  <li>Fotografie nemovitosti, typ nemovitosti a její popis, včetně adresy (min. kraj a obec).</li>
                </ul>
              </li>

              <li>Aukce je zahájena dle data, hodiny a minuty stanovených Zadavatelem v Detailu aukce. Od tohoto okamžiku je Účastník aukce oprávněn činit příhozy v rámci Aukce. Doba trvání aukce je 1 / 3 / 7 / 14 / 30 dnů.</li>
              <li>
                Aukce je ukončena uplynutím Délky aukce, kterou stanovil Zadavetal při zadání Aukce. Pokud došlo v posledních 20 vteřinách Aukce k příhozu, pak se prodlužuje Aukce o dalších 20 vteřin, a to i opakovaně.
                Rozhodující je čas, kdy došlo k příhozu v systému Provozovatele a nikoliv na zařízení (mobilní telefon, počítač atd.) Účastníka, a to vzhledem k tomu, že Provozovatel nemůže ovlivnit konektivitu a nastavení zařízení Účastníka.
              </li>
              <li>Provozovatel je oprávněn kdykoliv Aukci zrušit formou oznámení u Detailu Aukce, a to maximálně do doby zahájení Aukce. Aukce je zrušena okamžikem zveřejnění oznámení o zrušení Aukce.</li>
            </ol>
          </li>
          <li><strong>TOP nabídky a další služby Provozovatele</strong>
            <ol>
              <li>Provozovatel může poskytovat na svých stránkách i další služby, které souvisí s aukcemi, a to zejména uvedení se Zadavatelem dohodnutých nabídek jako TOP nabídky. Dále Provozovatel poskytuje po dohodě se Zadavatelem službu vkládání aukcí.</li>
              <li>Provozovatel zprostředkovává a může nabízet na svých stránkách další služby, jako j hypoték, úschova kupní ceny, právní služby, energetické  služby, hypotéční poradenství apod.</li>
            </ol>
          </li>
          <li><strong>Výsledky Aukce a možnost vyjednávání se Zadavatelem</strong>
            <ol>
              <li>Vítězem aukce se stane ten, kdo v Aukci nabídl nejvyšší cenu.</li>
              <li>Pořadník Účastníků aukce Provozovatel zveřejní v Detailu aukce v anonymizované podobě.</li>
              <li>Provozovatel emailem do 3 dnů od skončení Aukce oznámí Zadavateli Vítěze aukce (případně další Účastníky aukce dle pořadí) a jeho kontaktní údaje.</li>
              <li>Zadavatel je povinen po předání kontaktu na Vítěze Aukce či Účastníky aukce, kteří se umístili jako další v pořadí a projevili zájem o případné vyjednávání, začít bez zbytečného odkladu ve lhůtě 5 dnů od skončení Aukce vyjednávat o koupi či nájmu nemovitosti, která byla Předmětem aukce. Vítěz aukce je povinen ve lhůtě 5 dnů od skončení Aukce zahájit jednání se Zadavatelem ohledně koupě či nájmu nemovitosti, která byla Předmětem aukce. Účastník aukce bere na vědomí, že v důsledku účasti v Aukci může nést tzv. předsmluvní odpovědnost dle občanského zákoníku, pokud bezdůvodně odmítne vyjednávat o uzavření smlouvy se Zadavatelem. V případě porušení povinností Účastníka aukce dle tohoto odstavce, je povinen Účastník aukce Provozovateli uhradit smluvní pokutu ve výši 10 tis Kč. Smyslem této sankce je zajistit vážnost jednání Účastníků aukce, dobré jméno Provozovatele a vyhnout se spekulacím.</li>
              <li>Otatní účastníci aukce, kteří učinili příhoz, mohou prostřednictvím Aukčního systému projevit zájem o vyjednávání pro případ neúspěchu jednání s Vítězem aukce či Účastníkem aukce, který dosáhl vyšší ceny v Aukci.</li>
              <li>V případě, že jednání zadavatele s Vítězem aukce nebude úspěšné, pak je Zadavatel oprávněn vyjednávat s dalšími Účastníky aukce v pořadí dle výsledků Aukce.</li>
              <li>Vítěz Veřejné aukce je povinen zaslat Provozovateli prostřednictvím svého účtu v Aukčním systému fotku občanského průkazu, pokud toto bude požadovat Zadavatel. Stejně tak je povinen učinit i další Účastník aukce, pokud bude vyzván k jednání. V případě, že účastník nezašle na žádost Provozovatele fotku občanského průkazu, pak je Provozovatel oprávněn jemu zablokovat účet a neumožnit opětovnou registraci, a to z důvodu ochrany před falešnými účty.</li>
            </ol>
          </li>
          <li><strong>Provozovatel</strong>
            <ol>
              <li>Provozovatel Aukčního systému zajišťuje fungování Aukčního systému, propagaci jednotlivých Aukcí a je oprávněn si po dohodě se Zadavatelemi účtovat poplatky za umístění v kategorii TOP nabídky či za vytváření Detailů aukcí či jiné služby.</li>
              <li>Provozovatel je oprávněn si dle dohody se Zadavatelemi účtovat poplatky za Aukce.</li>
              <li>Provozovatel je oprávněn uveřejnit v rámci Detailu zadavatele základní údaje o Zadavatelem a v rámci Detailu aukce fotografie, popis nemovitostí a další údaje týkající se nabízené nemovitosti. K tomu jej zmocňuje Zadavatel a nese odpovědnost za to, že poskytl fotografie a  popis nemovitosti ke zveřejnění Provozovateli.</li>
              <li>Provozovatel pouze zprostředkovává prostřednictvím Aukčního systému možnost jednání se Zadavatelem a k tomu je oprávněn odsouhlasením těchto Obchodních podmínek jak Zadavatelem, tak Účastníkem aukce.</li>
              <li>Zjistí-li Provozovatel, že Účastník aukce má více účtů, je oprávněn tyto účty smazat či vyzvat k nápravě.</li>
              <li>Provozovatel nenese odpovědnost za pravdivost a úplnost jakýchkoliv informací zadaných Zadavatelem a Účastníkem aukce. Provozovatel nenese odpovědnost za jednání Účastníka aukce či zadavatele před Aukcí, během trvání Aukce nebo po jejím skončení. Veškeré jednání v rámci Aukce probíhá pouze mezi Zadavatelem a Účastníkem aukce.</li>
              <li>Provozovatel nese odpovědnost pouze za škodu, kterou zavinil úmyslně či z hrubé nedbalosti porušením svých povinností (v případě nefunkčnosti  Aukčního systému). Provozovatel nenese odpovědnost za to, že nedošlo k jednání či uzavření smlouvy mezi Účastníkem a Zadavatelem,  resp. že toto jednání nebylo úspěšné. Provozovatel je odpovědný za škodu v souhrnu maximálně do výše 10 tis. Kč.</li>

              <li>Provozovatel nenese odpovědnost za škodu způsobenou nepředvídatelnými okolnostmi nebo
              příčinami ležícími mimo jejich vliv v souladu s ustanovením § 2913 odst. 2 Občanského zákoníku.
              Za okolnost vylučující odpovědnost se považuje překážka, jež nastala nezávisle na vůli povinné
              strany a brání jí ve splnění její povinnosti, jestliže nelze rozumně předpokládat, že by povinná
              strana tuto překážku nebo její následky odvrátila. Provozovatel tak neodpovídá za škody vzniklé
              zejména v důsledku
              <ul style={{ listStyleType: 'disc' }}>
                <li>výpadků telekomunikačního a internetového připojení;</li>
                <li>živelné katastrofy;</li>
                <li>jednání Účastníka aukce či zadavatele.</li>
              </ul>
              </li>
              <li>Provozovatel není odpovědný rychlost připojení, přenosu dat, za rychlost příhozu a rychlost zobrazení změn v Aukčním systému.</li>
              <li>Účastník aukce a Zadavatel nejsou oprávněni v rámci Aukce používat jakékoli softwarové prostředky, které by mohly mít za následek jejich  neoprávněné zvýhodnění, popřípadě by mohly ovlivnit průběh Aukce dle těchto obchodních podmínek a dle zákona, či jakkoliv ovlivnit Aukční systém a jeho fungování.</li>
            </ol>
          </li>
          <li><strong>Zadavatel</strong>
            <ol>
              <li>Jako Zadavatel se může účastnit jakákoliv fyzická či právnická osoba v pozici realitní kanceláře, makléře, developera, realizátora insolvence nebo obce či kraje, a to zprostředkovávající či realizující prodej či nájem (podnájem) nemovitostí, která k tomu má příslušné oprávnění či zmocnění.</li>
              <li>Zadavatel se registruje pouze prostřednictvím Provozovatele, který Zadavateli vytvoří na základě smlouvy o spolupráci účet. Pro účely registrace je povinen Zadavatel uvést správně a pravdivě identifikační údaje, a to fyzická osoba jméno a příjmení, trvalé bydliště a datum narození, email (který je současně přihlašovacím jménem k Účtu) a telefon, právnická osoba sídlo, IČ, jméno a příjmení jednající osoby, email (který je současně přihlašovacím jménem k Účtu) a telefon.</li>
              <li>Zadavatel nese odpovědnost za škodu vzniklou uvedením nepravdivých identifikačních údajů.</li>
              <li>Zadavatel je oprávněna po zřízení Účtu zadávat jednotlivé Aukce, a to prostřednictvím formuláře a vytvářet tak Detaily Aukcí.</li>
              <li>Zadavatel má možnost požádat v rámci Účtu o jeho smazání, a to za předpokladu, že neporušila či neporušuje tyto Obchodní podmínky a nemá žádné závazky vůči Provozovateli.</li>
              <li>Podmínky za realizaci Aukce si Provozovatel dohodne samostatně se Zadavatelem.</li>
            </ol>
          </li>
          <li><strong>Účastník aukce</strong>
            <ol>
              <li>Aukce se může účastnit fyzická nebo právnická osoba, která je způsobilá k takovému jednání, má zřízený Účet a která souhlasila s těmito Obchodními podmínkami. Účastníkem aukce nemůže být Zadavatel, prodávající či pronajímatel.</li>
              <li>Účastník aukce je povinen při registraci uvést správně a pravdivě identifikační údaje, a to fyzická osoba jméno a příjmení, trvalé bydliště a datum narození, email (který je současně přihlašovacím jménem k Účtu) a telefon, právnická osoba sídlo, IČ, jméno a příjmení jednající osoby, email (který je současně přihlašovacím jménem k Účtu) a telefon.</li>
              <li>Účastník aukce nese odpovědnost za škodu vzniklou uvedením nepravdivých identifikačních údajů, a současně se zavazuje se zaplatit Provozovateli smluvní pokutu v případě vytvoření Účtu neexistujícího Účastníka aukce ve výši 10 tis Kč, přičemž nárok na náhradu vzniklé škody tím není dotčen.</li>
              <li>Účastník aukce je povinen zabezpečit přístupové údaje, zejména pak přihlašovací jméno a heslo, před přístupem třetích osob a nese odpovědnost za případné zneužití, zejména se nemůže dovolávat toho, že se Aukce nezúčastnil.</li>
              <li>Účastník aukce má možnost požádat v rámci Účtu o jeho smazání, a to za předpokladu, že neporušil či neporušuje tyto Obchodní podmínky a nemá žádné závazky vůči Provozovateli.</li>
            </ol>
          </li>
          <li><strong>Podmínky zpracování a užití osobních údajů</strong>
            <ol>
              <li>V návaznosti na čl. 28 Nařízení Evropského parlamentu a Rady (EU) 2016/679, ze dne 27. dubna 2016 o
          ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále
          jen <strong>„GDPR“</strong>) jsou si strany vědomy důležitosti ochrany osobních údajů.</li>
              <li>Provozovatel je povinen zpracovávat osobní údaje zadavatele a Účastníka aukce uvedené v bodě 9.4.
          (dále
          jen <strong>„Osobní údaje“</strong>) v souladu s GDPR a dále všechny strany jsou povinny zachovávat
          mlčenlivost o těchto vzájemně poskytnutých informacích, a to v rozsahu a za podmínek stanovených tímto
          článkem a právní úpravou.</li>
              <li>Zpracovatel prohlašuje, že získává, shromažďuje a dále zpracovává Osobní údaje v souladu s GDPR, jsou
          aktuální, přesné, pravdivé, odpovídají stanovenému účelu a jsou jím zpracovávané v rozsahu nezbytném pro
          naplnění stanoveného účelu.</li>
              <li>Účastník aukce a Zadavatel pověřují Provozovatele ke zpracování Osobních údajů v souvislosti s
          plněním povinností dle těchto Obchodních podmínek v rozsahu následujících Osobních údajů, které
          Provozovatel shromažďuje při poskytování služeb, spočívajících realizací Aukcí a následném předání
          kontaktních údajů
          (dále jen <strong>„Subjekty údajů“</strong>) a jedná se o následující Osobní údaje Subjektů údajů:
              <ul style={{ listStyleType: 'disc' }}>
                <li>lokační údaje;</li>
                <li>jméno, příjmení;</li>
                <li>evidenční číslo;</li>
                <li>e-mail;</li>
                <li>telefonní číslo;</li>
                <li>a případně další nezbytné osobní údaje poskytnuté Provozovateli (fotografie občanského průkazu či
              jiného osobního dokladu).</li>
              </ul>
              </li>
              <li>Účastník aukce za účelem vyjednávání opravňuje Provozovatele v případě, že je vítězem Aukce nebo má zájem vyjednávat se Zadavatelem, a to k předání jeho osobních údajů Zadavateli, jejíž Aukce se účastnil.</li>
              <li>Provozovatel je oprávněn Osobní údaje zpracovávat pouze za účelem plnění Obchodních podmínek.
              </li>
              <li>Provozovatel se zavazuje zpracovávat Osobní údaje, se kterými se dostane do styku výhradně osobně.
              </li>
              <li> Provozovatel je také tímto vázán povinností mlčenlivosti ohledně Osobních údajů a všech dalších
              informací, které se dověděl při výkonu činností dle těchto Obchodních podmínek, a musí
              poskytovat dostatečné záruky ochrany Osobních údajů odpovídající těm stanovených v tomto
              článku tak, aby byl vyloučen neoprávněný nebo nahodilý přístup ke zpracovávaným Osobním
              údajům, jejich zničení či ztráta, neoprávněný přenos, změna či zpracování, jakož i jiné zneužití
              zpracovávaných Osobních údajů. Provozovatel není oprávněn zapojit do zpracování žádnou jinou
              další osobu bez předchozího souhlasu Účastníka aukce či zadavatele.</li>
              <li>Provozovatel je vůči Účastníkovi aukce a Zadavateli odpovědný za prokazatelně vzniklou újmu
způsobenou Subjektu údajů či Objednateli zaviněným porušením povinností Poskytovatele
vyplývajících z právních předpisů České republiky, této Smlouvy.</li>
              <li>Provozovatel je povinen:
                <ul style={{ listStyleType: 'disc' }}>
                  <li>vést dokumentaci dokládající plnění jeho povinností ve vztahu k Osobním údajům, tj.
                    zejména zpracovat a dokumentovat přijatá a provedená opatření k zajištění ochrany
                    zpracovávaných Osobních údajů v souladu s GDPR a zároveň je povinen mít tuto
                    dokumentaci vždy připravenou k doložení Zadavateli, Účastníkovi aukce, Úřadu pro
                    ochranu osobních údajů, se sídlem Pplk. Sochora 27, 170 00 Praha (dále jen „Úřad“), a to
                    nejpozději do 30 dní ode dne doručení takové žádosti;</li>
                  <li>poskytnout nezbytnou součinnost a pomoc při zajišťování povinností, zajistit náležité zabezpečení
              Osobních údajů a dále taktéž při ohlašování případů porušení zabezpečení Osobních údajů Úřadu,
              oznamování případů porušení zabezpečení Objednateli, posouzení vlivu na ochranu Osobních údajů,
              případně při provádění tzv. předchozí konzultace s Úřadem;</li>
                  <li>poskytnout veškerou nutnou součinnost k zajištění zabezpečení zpracovávaných Osobních údajů a za
              účelem řádného plnění povinností vyplývajících z GDPR, mimo jiné povinnosti reagovat na žádosti o
              výkon práv Subjektu údajů, a to v rámci lhůty třiceti dnů.</li>
                  <li>přijmout veškerá vhodná organizační, bezpečnostní a technická opatření ve smyslu GDPR za účelem
              zajištění odpovídající úrovně ochrany zpracovávaných Osobních údajů, zejména se zavazuje přijmout
              taková opatření, aby bylo zamezeno neoprávněnému nebo nahodilému přístupu ke zpracovávaným Osobním
              údajům, jejich neúmyslná změna, zničení či ztráta, neoprávněný přenos, neoprávněné zpracování, jakož
              i jiné zneužití zpracovávaných Osobních údajů. Tato povinnost platí i po ukončení zpracování dle
              Smlouvy;</li>
                  <li>zachovávat mlčenlivost o zpracovávaných Osobních údajích a o bezpečnostních opatřeních přijatých k
              zabezpečení ochrany zpracovávaných Osobních údajů, a to i po skončení Smlouvy, a to zejména s
              ohledem na relevantní právní předpisy upravující ochranu osobních údajů. Poskytovatel se zavazuje,
              že zpracovávané Osobní údaje nepředá, nepřenechá, či k nim jakkoli neumožní přístup žádné třetí
              osobě s výjimkou těch orgánů státní správy, zdravotních pojišťoven a exekutorským úřadům, kterým je
              tyto osobní údaje povinen poskytnout ze zákona;</li>
                  <li>Poskytovatel se zavazuje při nakládání s Osobními údaji, a to v ústní, písemné, či elektronické podobě, které mu Zadavatel nebo Účastník aukce předal, či k nimž má přístup, respektovat a dodržovat jejich důvěrný charakter a zachovávat absolutní mlčenlivost, tzn. nebude je dále jakýmkoliv způsobem a formou rozšiřovat nebo reprodukovat, naopak vyvine veškeré možné úsilí k jejich ochraně.</li>
                </ul>
              </li>
              <li>Provozovatel zpracovává případně další osobní údaje dle zásad ochrany osobních údajů, s jejichž zněním
          se Provozovatel seznámil.</li>
            </ol>
          </li>
          <li><strong>Závěrečná ustanovení</strong>
            <ol>
              <li>Provozovatel je oprávněn tyto Obchodní podmínky kdykoliv měnit formou zveřejnění na internetové adrese www.inbido.cz. Účinnosti nabývají nové Všeobecné obchodní podmínky dnem jejich zveřejnění a přihlášením Účastníka aukce či zadavatele do Aukčního systému, pokud není stanoveno pozdější datum účinnosti. Na zahájené Aukce se použijí dosavadní Obchodní podmínky. </li>
              <li>Tyto Obchodní podmínky nabývají platnosti <strong>1.11.2023.</strong></li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
  </Container>
  </>);
}