import useAPI, { Form } from './useAPI';
import React from 'react';

const useOrganizationForms = () => {
  const { getOrganizationForms } = useAPI();

  const [ organizationForms, setOrganizationForms ] = React.useState<Form[]>([]);

  React.useEffect(() => {
    const cache = sessionStorage.organizationForms;
    if (cache) {
      setOrganizationForms(JSON.parse(cache));
    } else {
      getOrganizationForms().then((data) => {
        setOrganizationForms(data);
        sessionStorage.organizationForms = JSON.stringify(data);
      });
    }
  }, []);

  return organizationForms;
};

export default useOrganizationForms;
