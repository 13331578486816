import {
  Box, Button,
  CircularProgress, Container, Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link as RouterLink } from 'react-router-dom';

import useAPI, { OrganizationLite } from '../../../Hooks/useAPI';
import PartnerCard from '../../../PartnerCard/PartnerCard';
import React from 'react';
import theme from '../../../theme';
import { useOnWindowResize } from 'rooks';

export default function OurPartners() {
  const [ loading, setLoading ] = useState(true);
  const [ partners, setPartners ] = useState<OrganizationLite[]>([]);
  const { getOrganizations } = useAPI();
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [key, setKey] = React.useState(Date.now());
  useOnWindowResize(() => setKey(Date.now()));

  useEffect(() => {
    getOrganizations(
      15,
      0,
      ['form=1', 'form=2', 'form=3'],
    )
      .then(data => setPartners(data.organizations))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container key={key} sx={{ pt: '60px' }}>
      <Typography sx={{ pb: '20px' }} variant="h2" component="h2">Aukce zadavatelů</Typography>

      {loading && <Box sx={{
        height:       '420px',
        display:      'flex',
        alignItems:   'center',
        placeContent: 'center',
      }}>
        <CircularProgress/>
      </Box>}

      {!loading && <Swiper
        spaceBetween={20}
        touchEventsTarget='container'
        loop={(isMobile && partners.length >= 3) || partners.length >= 5}
        slidesPerView={isMobileBreakpoint
          ? 3
          : isMobile
            ? 3
            : 5}
        slidesPerGroup={isMobileBreakpoint
          ? 1
          : isMobile
            ? 3
            : 5}
        modules={[Navigation]}
        centeredSlides={isMobileBreakpoint}
        navigation={!isMobileBreakpoint}
        style={{
          padding:       isMobileBreakpoint ? 'inherit' : '20px',
          paddingBottom: '40px',
          paddingTop:    '20px',
          margin:        isMobile ? undefined : '-20px',
          marginLeft:    isMobileBreakpoint ? '-55vw' : isMobile ? undefined : '-20px',
          width:         isMobileBreakpoint ? '200vw' : 'inherit',
        }}
      >
        {partners.map((partner) =>
          <SwiperSlide key={partner.uuid} style={{ paddingTop: '30px' }}>
            <PartnerCard partner={partner}/>
          </SwiperSlide>
        )}
      </Swiper>
      }

      <Box sx={{
        textAlign: 'center', pt: isMobileBreakpoint ? '20px': '60px',
      }}>
        <Link component={RouterLink} to="/zadavatele">
          <Button variant="contained" color="secondary" sx={{
            width: '200px', height: isMobileBreakpoint ? '35px': '50px', borderRadius: '100px !important',
          }}>Seznam zadavatelů</Button>
        </Link>
      </Box>
    </Container>);
}