import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Link,
  Stack, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import HTMLReactParser from 'html-react-parser';

import useAPI, { Organization } from '../../../Hooks/useAPI';
import styles from './Detail.module.css';
import React from 'react';
import { useQuery } from '../../../Hooks/useQuery';
import Chip from '../../../Chips/Chip';
import { PromiseValue } from '../../../PromiseValue';
import { PlaceIdToAddress } from '../../../PlaceIdToAddress';

type Props = {
  company: Organization
};

export default function Detail({ company }: Props) {
  const param = useQuery();
  const { getOrganizationMultimedia } = useAPI();
  return (<>
    {!param.get('iframe') && <>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid item md={12} sx={{
          display: 'flex', alignItems: 'center',
        }}>
          <Typography variant="h1" component="h1" className={styles.header} sx={{ pr: 5 }}>{company.title}</Typography>
          <Chip label={company.form.label}/>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ pt: '30px' }}>
        <Grid item xs={12} md={3}>
          <Box sx={{
            borderRadius:   '40px',
            border:         '4px solid #dfdcdc',
            padding:        '10px',
            textAlign:      'center',
            justifyContent: 'center',
            minHeight:      '170px',
            display:        'flex',
          }}>
            {(company.logoMultimediaUuid && company.logoMultimediaUuid.length > 0) && <picture style={{ alignSelf: 'center' }}><img src={getOrganizationMultimedia(company.uuid!, company.logoMultimediaUuid)} alt={company.name} style={{
              maxHeight: '150px', maxWidth: '100%',
            }}/></picture> }
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={2} sx={{ px: 5 }}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <InfoIcon htmlColor='#e02804' fontSize='large'/>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                {company.name}
              </Typography>
            </Stack>

            {company.streetCode.length > 0 && <Stack direction='row' alignItems='center' spacing={1}>
              <HomeIcon htmlColor='#e02804' fontSize='large'/>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                <PromiseValue promise={PlaceIdToAddress(company.streetCode)} key={company.streetCode} error='Došlo k chybě při překladu adresy'/>
              </Typography>
            </Stack>}

            <Stack direction='row' alignItems='center' spacing={1}>
              <LocalPhoneIcon htmlColor='#e02804' fontSize='large'/>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                <Link component='a' underline='hover' href={`tel:${company.phoneNumber}`} sx={{
                  fontWeight: '500 !important', color: '#1c1c1e', cursor: 'pointer',
                }}>{company.phoneNumber}</Link>
              </Typography>
            </Stack>

            <Stack direction='row' alignItems='center' spacing={1}>
              <MailIcon htmlColor='#e02804' fontSize='large'/>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                <Link component='a' underline='hover' href={`mailto:${company.email}`} sx={{
                  fontWeight: '500 !important', color: '#1c1c1e', cursor: 'pointer',
                }}>{company.email}</Link>
              </Typography>
            </Stack>

            {company.url.length > 0 && <Stack direction='row' alignItems='center' spacing={1}>
              <LanguageIcon htmlColor='#e02804' fontSize='large'/>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                <Link component='a' underline='hover' target="_blank" href={company.url} sx={{
                  fontWeight: '500 !important', color: '#1c1c1e', cursor: 'pointer',
                }}>{company.url}</Link>
              </Typography>
            </Stack>}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography sx={{
            color: '#e02804 !important', textTransform: 'uppercase',
          }}><strong>O nás</strong></Typography>
          <Typography component={'span'}>{HTMLReactParser(company.description)}</Typography>
        </Grid>
      </Grid>
    </>
    }
  </>);
}