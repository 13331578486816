import useAPI, { Customer } from './useAPI';
import React from 'react';
import {
  errorAttributes,
  isEmail,
  isTelephoneNumber,
  maxLength, minLength, required,
} from '../InputValidator';
import { useKeycloak } from '@react-keycloak/web';

let passwordTestValue = '';
const passwordCheck = (v?: string | number) => {
  return passwordTestValue === (v ?? '') || errorAttributes('Hesla se musí shodovat');
};

const useCustomer = (uuid: string | undefined, opts?: { isTelephoneNumberOptional?: boolean, isPasswordOptional?: boolean } ) => {
  const { getCustomer, updateCustomer } = useAPI();
  const { keycloak } = useKeycloak();

  const rules = {
    email:       [required, isEmail],
    phoneNumber: [opts?.isTelephoneNumberOptional ? null : required, isTelephoneNumber].filter(Boolean),
    firstName:   [minLength(2), maxLength(100)],
    lastName:    [minLength(2), maxLength(100)],
    password:    opts?.isPasswordOptional ? [] : [required, passwordCheck],
  } as { [x in keyof (Customer)]: (typeof required)[]};

  const [ data, setCustomer ] = React.useState<Customer | null>(null);
  const [ loaded, setLoaded ] = React.useState<boolean | null>(false);
  const [ dirty, setDirty ] = React.useState<string[]>([]);

  const dataRef = React.useRef(data);
  React.useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const update = <K extends keyof Customer>(field: K, value: Customer[K]) => {
    setDirty(v => Array.from(new Set([...v, field])));
    setCustomer((d) => d ? ({
      ...d, [field]: value,
    }) : null);
  };

  const [timestamp, setTimestamp] = React.useState(Date.now());
  const reset = () => {
    setTimeout(() => {
      setTimestamp(Date.now());
    }, 100);
    setTimeout(() => {
      setDirty([]);
    }, 200);
  };
  React.useEffect(() => {
    uuid ??= keycloak.tokenParsed?.sub;

    if (!uuid) {
      return;
    }

    if (uuid === 'create') {
      setCustomer({
        email:           '',
        firstName:       '',
        createTimestamp: new Date().toISOString(),
        lastName:        '',
        updateTimestamp: new Date().toISOString(),
        password:        '',
        phoneNumber:     '',
      });
      setLoaded(true);
      return;
    }

    getCustomer(uuid)
      .then(v => {
        setCustomer(v);
        setLoaded(true);
      })
      .catch((e) => {
        console.error(e);
        setLoaded(null);
      });
  }, [uuid, keycloak.authenticated, timestamp]);

  const save = () => {
    return new Promise<Required<Customer>>((resolve, reject) => {
      if (!dataRef.current) {
        return reject();
      }
      updateCustomer(uuid!, dataRef.current)
        .then(() => {
          resolve(dataRef.current as Required<Customer>);
        });
    });
  };

  const validate = React.useCallback((field: keyof Customer, all = false): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    if ((!all &&!dirty.includes(field)) || !data) {
      return {}; // untouched
    }
    const value = data[field];

    for (const rule of rules[field]!) {
      const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
      if (result === true) {
        continue;
      }
      console.log('Invalid field', field, 'with value', value);
      return result;
    }

    return {};
  }, [ dirty, data ]);

  const isValid = Object.keys(rules ?? {}).map(field => {
    return validate(field as keyof Customer).error ?? false;
  }).filter(value => value).length === 0;

  const dirtifyAndValidate = () => Object.keys(rules ?? {}).map(field => {
    setDirty(v => Array.from(new Set([...v, field])));
    return validate(field as keyof Customer, true).error ?? false;
  }).filter(value => value).length === 0;

  const refresh = () => {
    if (!uuid) {
      return;
    }

    getCustomer(uuid)
      .then(v => {
        setCustomer(v);
        setLoaded(true);
      })
      .catch(() => setLoaded(null));
  };

  const passwordTest = (val: string) => {
    passwordTestValue = val;
    // forcing refresh of values to propagate
    setCustomer({ ...dataRef.current! });
  };

  return {
    data,
    loaded,
    update,
    validate,
    isValid,
    dirtifyAndValidate,
    save,
    refresh,
    passwordTest,
    reset,
  };
};

export default useCustomer;
