import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import React from 'react';
import useAPI, { Form } from '../../../Hooks/useAPI';
import { LinearProgress } from '@mui/material/';

type Props = {
  onFilterChange: (value: any) => void
};

export default function Filters({ onFilterChange }: Props) {
  const [ name, setName ] = React.useState('');
  const [ type, setType ] = React.useState<number[]>([]);

  const [ forms, setForms ] = React.useState<Form[]>([]);

  const { getOrganizationForms } = useAPI();

  React.useEffect(() => {
    getOrganizationForms().then(data => setForms(data));
  }, []);

  const onButtonClick = React.useCallback(() => {
    onFilterChange({
      'name': name.length > 0 ? '' : name,
      'form': type,
    });
  }, [ name, type, onFilterChange ]);

  return (<Paper elevation={0} sx={{
    borderRadius:    '35px',
    minHeight:       '300px',
    backgroundColor: '#ededed',
    p:               3,
    py:              4,
  }}>
    <Typography sx={{ pb: 2 }}><strong>Filtry</strong></Typography>

    <TextField
      label="Název"
      variant='filled'
      fullWidth
      value={name} onChange={(ev) => setName(ev.currentTarget.value)}
      InputProps={{
        endAdornment: <InputAdornment position='end'>
          <Fade in={name.length > 0}>
            <IconButton onClick={() => setName('')}><ClearIcon/></IconButton>
          </Fade>
        </InputAdornment>,
      }}
    />

    <FormControl fullWidth variant='filled' sx={{ position: 'relative' }}>
      <InputLabel id="demo-simple-select-label">Kategorie zadavatele</InputLabel>
      <Select
        value={type}
        onChange={(ev) => {
          const value = ev.target.value;
          if (Array.isArray(value)) {
            setType(value);
          }
        }}
        label="Kategorie zadavatele"
        multiple>
        {forms
          .filter(o => o.id !== 4)
          .map(o => <MenuItem value={o.id}>{o.label}</MenuItem>)}
      </Select>
      {forms.length === 0 && <LinearProgress sx={{
        position: 'absolute', width: '100%', bottom: 0,
      }}/>}
    </FormControl>

    <Button fullWidth variant='contained' sx={{ mt: 2 }} onClick={onButtonClick}>Hledat</Button>
  </Paper>);
}