import * as React from 'react';

const BulletSvg = (props: any) => (
  <svg
    width={500}
    viewBox="0 0 375 375"
    height={500}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M37.5 37.5h300v300h-300Zm0 0" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      style={{
        fill:        '#30ab4b',
        fillOpacity: 1,
      }}
    >
      <path
        fill="#fff"
        d="M337.5 187.5c0 82.813-67.188 150-150 150-82.813 0-150-67.188-150-150 0-82.813 67.188-150 150-150 82.813 0 150 67.188 150 150Zm0 0"
        fillRule="evenodd"
        style={{
          fill:        '#30ab4b',
          fillOpacity: 1,
        }}
      />
    </g>
    <path
      fill="#1c1c1e"
      d="m85.871 190.82 79.559 79.559 123.699-123.7-26.5-26.562-97.2 97.266-53.058-53.059Zm0 0"
      fillRule="evenodd"
    />
  </svg>
);

export default BulletSvg;
