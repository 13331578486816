import {
  Grid, Link, useMediaQuery,
} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import QuestionMark from '../Index/FAQ/QuestionMark.png';
import React from 'react';
import FAQ from '../Index/FAQ/FAQ';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

export default function PagesCasteDotazy() {
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const questions = [
    {
      header: 'Jak INBIDO funguje?',
      list:   [
        {
          q: 'Jak probíhá aukce?',
          a: [
            'Zadavatel či provozovatel za Zadavatele vloží nemovitost do aukce (fotky, popis, adresa) a nastaví její začátek a délku, vyvolávací cenu.',
            'Účastníci aukce neskládají žádnou jistotu.',
            'Aukce trvá buď 1 / 3 / 7 / 14 / 30 dnů, a to podle toho, jak si délku aukce nastaví Zadavatel. Pokud dojde v posledních 20 vteřinách k minimálně jednomu příhozu, pak se automaticky prodlužuje aukce o 20 vteřin, a to i opakovaně. Rozhodující je čas, kdy došlo k příhozu v systému Provozovatele a nikoliv na zařízení (mobilní telefon, počítač atd.) Účastníka, a to vzhledem k tomu, že Provozovatel nemůže ovlivnit konektivitu a nastavení zařízení Účastníka.',
            'Účastník aukce se zaregistruje a v rámci konkrétní aukce přihazuje. Počátkem aukce je umožněno účastníkům přihazovat k vyvolávací ceně, a to až do skončení aukce.',
            'Dosažená cena opravňuje účastníka vyjednávat se Zadavatelem o uzavření kupní smlouvy nebo smlouvy o nájmu. Zda dojde k uzavření smlouvy je již na Zadavatelem aukce a účastníkovi.',
            'Pokud není učiněn v aukci ani jeden příhoz, pak aukce končí neúspěšně. Zadavatel může vyvolávací cenu snížit a realizovat aukci znovu.',
            'Zadavatel může zrušit aukci pouze do začátku aukce.',
          ],
        },
        {
          q: 'Jak se účastnit aukce?',
          a: [<Typography key='1'>
            Zaregistrovat se na portálu prostřednictvím <Link component={RouterLink} to="/registrace-ucastnika-aukce">formuláře</Link>,
            aktivovat svůj účet a přihlásit se do aukce. Účastnit se může každá osoba způsobilá k nákupu nemovitostí ve
            smyslu občanského zákoníku. Nezbytnou podmínkou registrace je souhlas s obchodními podmínkami.
          </Typography>],
        },
        {
          q: 'Jak dlouho trvá aukce?',
          a: [
            'Aukce trvá buď 1 / 3 / 7 / 14 / 30 dnů, a to podle toho, jak si délku aukce nastaví Zadavatel.',
            'Pokud došlo v posledních 20 vteřinách Aukce k příhozu, pak se prodlužuje Aukce o dalších 20 vteřin, a to i opakovaně. Rozhodující je čas, kdy došlo k příhozu v systému Provozovatele a nikoliv na zařízení (mobilní telefon, počítač atd.) Účastníka, a to vzhledem k tomu, že Provozovatel nemůže ovlivnit konektivitu a nastavení zařízení Účastníka.',
          ],
        },
        {
          q: 'Jaké jsou cenové podmínky?',
          a: ['Cenové podmínky, které zahrnují celou řadu námi poskytovaných služeb, budou s Vámi dohodnuty před začátkem spolupráce.'],
        },
        {
          q: 'Probíhá aukce anonymně?',
          a: ['Ano, aukce probíhají zcela anonymně a ani provozovatel portálu INBIDO neví, kdo přihazuje u jednotlivých aukcí. K identifikaci účastníků dojde až po skončení aukce a pouze provozovatel má přístup k těmto informacím. Následně pošle identifikační údaje vítěze aukce Zadavateli. Se všemi identifikačními údaji je nakládáno v souladu s GDPR.'],
        },
        {
          q: 'Co se stane, když nedojde k příhozu?',
          a: ['Pokud není učiněn v aukci ani jeden příhoz, pak aukce končí neúspěšně. Zadavatel může po dohodě s prodávajícím vyvolávací cenu snížit a realizovat aukci znovu.'],
        },
        {
          q: 'Jak dá Zadavatel vědět zájemcům, že se mají účastnit aukce?',
          a: ['Postačí, když zájemcům (kteří se např. účastnili prohlídky nemovitosti) pošle Zadavatel e-mailem, sms, v chatu (messenger, whats app, viber apod.) odkaz na danou aukci s tím, aby se zaregistrovali a účastnili aukce.'],
        },
        {
          q: 'Je aukce zabezpečená proti falešným přihazujícím?',
          a: ['Falešné profily účastníků jsou eliminovány několika způsoby, vítěz aukce je povinen zaslat provozovateli prostřednictvím svého účtu v aukčním systému fotku občanského průkazu, pokud toto vyžaduje Zadavatel (obvykle nevyžaduje, pokud jej zná např. z prohlídky nemovitostí). Stejně tak je povinen učinit i další účastník aukce, pokud bude vyzván k jednání.',
            'Falešné přihazující lze eliminovat i vytvořením tzv. uzavřené aukce, která je určena pouze zájemcům, které vybral Zadavatel.',
            'Není možné založit více účtů se stejným telefonním číslem. Nezbytnou podmínkou registrace je souhlas s obchodními podmínkami.'],
        },
        {
          q: 'Kolik je minimální a maximální příhoz?',
          a: [
            'U prodeje nemovitostí je minimální výše příhozu 10 000,-Kč, a maximální 100 000,- Kč, vždy však zaokrouhleno na 1000 Kč.',
            'U nájmu nemovitostí je minimální výše příhozu 100,-Kč, a maximální 1 000 Kč, vždy však zaokrouhleno na 100 Kč.',
            'U prodeje Předmětu aukce – kategorie A je minimální výše příhozu 1 000,-Kč, a maximální výše 1 000 000,- Kč, vždy však zaokrouhleno na 1 000,- Kč.',
            'U prodeje Předmětu aukce – kategorie B je minimální výše příhozu 100,-Kč, a maximální výše 100 000,- Kč, vždy však zaokrouhleno na 100,- Kč.'],
        },
        {
          q: 'Jak se Zadavatel dozví o vítězi aukce?',
          a: ['Provozovatel INBIDO e-mailem nejpozději do 3 dnů od skončení aukce oznámí Zadavateli vítěze aukce a jeho kontaktní údaje (jméno a příjmení či název firmy, telefon a e-mail).'],
        },
        {
          q: 'Co znamená vyvolávací cena?',
          a: ['Vyvolávací cena je startovací cena v aukci, kterou stanoví Zadavatel. Doporučuje se, aby vyvolávací cena byla nastavena o něco níže (např. o 10 %), tak aby přilákala co největší počet zájemců, kteří budou přihazovat.'],
        },
        {
          q: 'Co se stane, když k uzavření smlouvy s výhercem nedojde?',
          a: ['Pokud by nedošlo k uzavření smlouvy s vítězem aukce, pak má Zadavatel právo na zaslání kontaktu na další účastníky v pořadí, kteří označili, že mají zájem vyjednávat.'],
        },
        {
          q: 'Může účastník aukce vyjednávat o smlouvě i když neuspěl?',
          a: ['Ostatní účastníci aukce, kteří učinili příhoz, mohou prostřednictvím aukčního systému projevit zájem o vyjednávání pro případ neúspěchu jednání s vítězem aukce či účastníkem aukce, který dosáhl vyšší ceny v aukci.'],
        },
        {
          q: 'Jak funguje INBIDO?',
          a: ['Jednoduše!',
            'Zadavatel se registruje a vloží nemovitost do aukce (adresu, popis, fotky). Nastaví vyvolávací cenu, počátek a délku aukce.',
            'Účastník aukce se zaregistruje a v rámci konkrétní aukce přihazuje. Dosažená cena opravňuje účastníka vyjednávat se Zadavatelem o uzavření kupní smlouvy nebo smlouvy o nájmu.'],
        },
        {
          q: 'Proč INBIDO?',
          a: [<>
            <ul>
              <li>
                <p>Prodávající a pronajímatel dosáhnou na co nejvyšší cenu!</p>
              </li>
              <li>
                <p>Zjistíte reálnou tržní hodnotu nemovitosti!</p>
              </li>
              <li>
                <p>Portál INBIDO je bezpečný a jednoduchý online nástroj ve světě realit!</p>
              </li>
            </ul>
          </>,
          'Portál INBIDO je nástrojem vyvinutým pro realitní kanceláře/developery/obce, který umožňuje velmi jednoduchým způsobem soutěžit mezi zájemci o nemovitost o to, kdo bude mít možnost vyjednávat o uzavření kupní smlouvy nebo smlouvy o nájmu.',
          'Nejde o dražbu nemovitostí dle zákona o dražbách, ale pouze o nástroj pro nalezení toho, kdo má největší zájem o konkrétní nemovitost.'],
        },
      ],
    },
    {
      header: 'Zadavatelé',
      list:   [
        {
          q: 'Jak se lze zaregistrovat na portál INBIDO?',
          a: [<Typography key='2'>
            Pouze prostřednictvím provozovatele na základě smlouvy o spolupráci a za dohodnutých podmínek.
            Postačí napsat na <Link href="mailto:info@inbido.cz" component='a'>info@inbido.cz</Link> nebo
            zavolat na <Link href="tel:+420 777 799 645" component='a'>+420 777 799 645</Link> a my Vás
            zaregistrujeme a sdělíme všechny potřebné informace.
          </Typography>,
          'Nezbytnou podmínkou registrace je souhlas s obchodními podmínkami.',
          ],
        },
        {
          q: 'Kdo může být Zadavatelem?',
          a: ['Pouze realitní kanceláře, makléři, developerské společnosti, developeři fyzické osoby, obce a kraje.'],
        },
        {
          q: 'Co a jak lze zadat do aukce?',
          a: [
            'Do aukce lze zadat byty, domy, pozemky, bytové domy, developerské projekty, sklady, garáže, atd.',
            'Zadavatel může zadat nemovitosti do aukce buď sám vložením nemovitosti v rámci svého účtu nebo prostřednictvím týmu provozovatele INBIDO.',
          ],
        },
        {
          q: 'Jak může Zadavatel zadat nemovitost do aukce?',
          a: ['Buď vložením nemovitosti do aukce v rámci svého účtu nebo prostřednictvím týmu provozovatele INBIDO.'],
        },
        {
          q: 'Je možná neveřejná aukce pouze pro zájemce Zadavatele?',
          a: ['INBIDO.cz umožňuje vytvořit tzv. uzavřenou aukci (neveřejnou), pouze pro zájemce, které vybere Zadavatel a kteří např. byli na prohlídce nemovitosti.'],
        },
        {
          q: 'Může Zadavatel zrušit aukci?',
          a: ['Pouze do začátku aukce.'],
        },
      ],
    },
    {
      header: 'Chci prodat nemovitost',
      list:   [
        {
          q: 'Může prodávající zadat napřímo nemovitost do aukce?',
          a: [<Typography key='3'>
            Nemůže, protože nechceme konkurovat realitkám a jsme výlučně jejich partnery.
            Nicméně prodávajícímu rádi zprostředkujeme některou z našich partnerských realitek. Stačí nás
            kontaktovat prostřednictvím <Link component={RouterLink} to='/chci-aukci'>formuláře</Link>.
          </Typography>],
        },
      ],
    },
  ];

  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Časté dotazy - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Container sx={{
      marginTop:     isMobileBreakpoint ? 0 : 1,
      paddingBottom: 1,
      pt:            '30px',
    }}>
      <Typography variant="h1" component="h1"><span>Časté dotazy</span></Typography>
    </Container>

    <Container>
      <Grid container>
        <Grid item xs={12} md={7}>
          { questions.map((val, idx) => <FAQ noQuestionMarkIcon hideQuestion={questions.length >= idx - 1} key={idx} questions={val.list} sx={{ px: '0 !important' }}>
            <Typography sx={{
              fontWeight: '900', pt: 2,
            }} variant="h6" component="h6">{val.header}</Typography>
          </FAQ>)}
        </Grid>
        {!isTabletBreakpoint && <Grid item md={5} sx={{
          textAlign: 'right', paddingRight: '100px', paddingTop: '500px',
        }}>
          <img src={QuestionMark} width={365} alt=""/>
        </Grid>}
      </Grid>
    </Container>
  </>
  );
}