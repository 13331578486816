import Box from '@mui/material/Box';
import React from 'react';
import copy from 'copy-to-clipboard';
import {
  FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import arrow from './arrow.svg';
import copyImage from './copy.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import telegram from './telegram.svg';
import whatsapp from './whatsapp.svg';
import {
  Collapse, Fade, Stack, Typography, useMediaQuery,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import theme from '../theme';

export const ShareButton: React.FC = () => {
  const [ open, setOpen ] = React.useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = React.useCallback(() => {
    enqueueSnackbar('Odkaz na aukci zkopírován do schránky.');
    copy(window.location.href, { format: 'text/plain' });
  }, []);

  return <>
    <Box sx={{
      width:           isTablet ? '100px' : '120px',
      minHeight:       isTablet ? 'inherit' : '40px',
      color:           '#1c1c1e',
      border:          isTablet ? 'inherit' : '4px solid #dfdcdc',
      borderRadius:    isTablet ? 'inherit' : '20px',
      padding:         isTablet ? 'inherit' : '6px',
      cursor:          'pointer',
      marginLeft:      isTablet ? 'inherit' : 'auto',
      position:        'absolute',
      backgroundColor: 'white',
      top:             isTablet ? 'inherit' : '15px',
      right:           isTablet ? 'inherit' : 'clamp(-100px, -5vw, 0px)',
      userSelect:      'none',
    }} onClick={() => setOpen(!open)}>
      <Stack
        direction='row'
        justifyContent={'space-evenly'}
        sx={{
          border:          !isTablet ? '0' : '4px solid #dfdcdc',
          borderRadius:    !isTablet ? '0' : '20px',
          backgroundColor: isTablet && open ? '#dfdcdc' : 'inherit',
          height:          isTablet ? '31px' : 'inherit',

        }}>
        <Typography sx={{
          fontWeight:    '900',
          textTransform: 'uppercase',
          fontSize:      isTablet ? '12px !important' : '14px !important',
          transform:     isTablet ? 'translateY(2px)' : 'inherit',
        }}>Sdílet</Typography>
        <img src={arrow} width={isTablet ? 12 : 24} style={{
          position: 'relative',
          top:      isTablet ? 'inherit' : '-2px',
        }}/>
      </Stack>

      { /* this code needs to be deduplicated, but got errors about scrollTop when using as own component */ }
      {isTablet
        ? <Fade in={open}>
          <Stack
            direction={isTablet ? 'row-reverse' : 'column'}
            spacing={'10px'}
            alignItems='center'
            sx={{ py: 1 }}>
            <button type='button' title='Zkopírovat do schránky' className='react-share__ShareButton'
              style={{
                backgroundColor: 'transparent',
                border:          'none',
                padding:         '0px',
                font:            'inherit',
                color:           'inherit',
                cursor:          'pointer',
              }}>
              <img src={copyImage} onClick={handleCopy} width={35}/>
            </button>
            <FacebookShareButton  url={window.location.href} hashtag='#inbido' >
              <img src={facebook} width={35}/>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} hashtags={['inbido']}>
              <img src={twitter} width={35}/>
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <img src={whatsapp} width={35}/>
            </WhatsappShareButton>
            <TelegramShareButton url={window.location.href}>
              <img src={telegram} width={35}/>
            </TelegramShareButton>
          </Stack>
        </Fade>
        : <Collapse in={open} collapsedSize={0}>
          <Stack
            direction={isTablet ? 'row-reverse' : 'column'}
            spacing={'10px'}
            alignItems='center'
            sx={{ py: 1 }}>
            <button type='button' title='Zkopírovat do schránky' className='react-share__ShareButton'
              style={{
                backgroundColor: 'transparent',
                border:          'none',
                padding:         '0px',
                font:            'inherit',
                color:           'inherit',
                cursor:          'pointer',
              }}>
              <img src={copyImage} onClick={handleCopy} width={35}/>
            </button>
            <FacebookShareButton  url={window.location.href} hashtag='#inbido' >
              <img src={facebook} width={35}/>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} hashtags={['inbido']}>
              <img src={twitter} width={35}/>
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <img src={whatsapp} width={35}/>
            </WhatsappShareButton>
            <TelegramShareButton url={window.location.href}>
              <img src={telegram} width={35}/>
            </TelegramShareButton>
          </Stack>
        </Collapse>}
    </Box>
  </>;
};