import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from './store';

// NOT USED, KEEPING AS EXAMPLE TO USE REDUX

// Type for our state
export interface ApiState {
  timestamp: number;
  isAuctionProcessing: boolean;
}

// Initial state
const initialState: ApiState = {
  timestamp: Date.now(), isAuctionProcessing: false,
};

// Actual Slice
export const apiSlice = createSlice({
  name:     'api',
  initialState,
  reducers: {
    bidProcessed(state) {
      state.timestamp = Date.now();
    },
    setAuctionProcessing(state) {
      state.isAuctionProcessing = true;
    },
    setAuctionProcessingDone(state) {
      state.isAuctionProcessing = false;
    },
  },
});

export const { bidProcessed, setAuctionProcessing, setAuctionProcessingDone } = apiSlice.actions;

export const selectBidProcessed = (state: AppState) => state.api.timestamp;
export const selectIsAuctionProcessing = (state: AppState) => state.api.isAuctionProcessing;

export default apiSlice.reducer;