import { Form } from './useAPI';
import React from 'react';

const useObjectTypes = () => {
  //const { getObjectTypes } = useAPI();

  const [ objectTypes, setObjectTypes ] = React.useState<Form[]>([]);

  React.useEffect(() => {

    /*
    const cache = sessionStorage.objectTypes;
    if (cache) {
      setObjectTypes(JSON.parse(cache));
    } else {
      getObjectTypes().then((data) => {
        setObjectTypes(data);
        sessionStorage.objectTypes = JSON.stringify(data);
      });
    }
    */

    const data = [
      {
        'id': 1,'label': 'Byt',
      },
      {
        'id': 2,'label': 'Dům',
      },
      {
        'id': 3,'label': 'Pozemek',
      },
      {
        'id': 4,'label': 'Nebytové prostory',
      },
      {
        'id': 5,'label': 'Podíl',
      },
      {
        'id': 9,'label': 'Jiné',
      },
      {
        'id': 11,'label': 'Movitý majetek kategorie A',
      },
      {
        'id': 12,'label': 'Movitý majetek kategorie B',
      },
    ];

    setObjectTypes(data);

    sessionStorage.objectTypes = JSON.stringify(data);

  }, []);

  return objectTypes;
};

export default useObjectTypes;
