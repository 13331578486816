import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export const PromiseValue: React.FC<{
  promise: Promise<string>,
  error: string,
  formatter?: (value: string) => string,
}> = (props) => {
  const [ value, setValue ] = React.useState('');
  const [ loading, setLoading ] = React.useState(true);
  const [ error, setError] = React.useState(false);

  React.useEffect(() => {
    props.promise
      .then(setValue)
      .catch(() => {
        setError(true);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);
  return <>
    { loading
      ? <LinearProgress/>
      : error
        ? props.error
        : props.formatter ? props.formatter(value) : value
    }
  </>;
};