import Pagination from '@mui/material/Pagination';
import Link from '@mui/material/Link';
import PaginationItem from '@mui/material/PaginationItem';
import { Dispatch, SetStateAction } from 'react';
import { Paginator } from './Hooks/useAPI';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from './Hooks/useQuery';

export const PaginationStyled = ({ paginator, page, setPage } : {paginator: Paginator, page: number, setPage: Dispatch<SetStateAction<number>>}) => {
  const location = useLocation();
  const params = useQuery();

  const generatedParams = React.useMemo(() => {
    const out: string[] = [];

    if (params.get('iframe')) {
      out.push('iframe=1');
    }
    if (params.get('bgcolor')) {
      out.push('bgcolor=' + params.get('bgcolor'));
    }
    if (params.get('cardcolor')) {
      out.push('cardcolor=' + params.get('cardcolor'));
    }

    if (out.length > 0) {
      return `&${out.join('&')}`;
    } else {
      return '';
    }
  }, [ params ]);

  const handlePageChange = (value: number) => {
    setPage(value);
    document.documentElement.scrollTo({
      top:  0,
      left: 0,
    });
  };

  return <Pagination
    onChange={(_, value) => handlePageChange(value)}
    renderItem={(item) => (
      <PaginationItem
        component={Link}
        href={`${location.pathname}${item.page === 1
          ? generatedParams.length === 0 ? '' : `?${generatedParams}`
          : `?stranka=${item.page}${generatedParams}`}`}
        {...item}
      />
    )}
    count={paginator ?? 1}
    page={page}
    sx={{
      'ul':     { placeContent: 'center' },
      'button': {
        fontWeight: 'bold', fontSize: '20px',
      },
      '.Mui-selected': {
        fontSize: '30px', color: '#e02804',  backgroundColor: 'inherit !important',
      },
      pb: 5,
      pt: 8,
    }}/>;
};