import * as React from 'react';
import Header from './Header/Header';
import WhyAuctions from './WhyAuctions/WhyAuctions';
import WhyAuctionsBullets from './WhyAuctionsBullets/WhyAuctionsBullets';
import OurPartners from './OurPartners/OurPartners';
import FAQ from './FAQ/FAQ';
import { Helmet } from 'react-helmet';
import CurrentAuctions from './CurrentAuctions/CurrentAuctions';

export default function PagesIndex() {
  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Nabídky - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Header/>
    <CurrentAuctions/>
    <WhyAuctions/>
    <WhyAuctionsBullets/>
    <OurPartners/>
    <FAQ/>
  </>);
}
