import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import {
  ReactNode, useCallback, useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import { Delete, Edit } from '@mui/icons-material';

import useAPI, { Customer, CustomerLite } from '../Hooks/useAPI';
import React from 'react';
import { ConfirmDialog } from '../Dialog/Confirm';

export const ActionFormatter = ({ value }: { value: Customer | CustomerLite }) => {
  const { removeCustomer } = useAPI();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteItem = useCallback(() => {
    removeCustomer(value.uuid!).then(() => window.location.reload());
    setShowDeleteConfirm(false);
  }, [ value, removeCustomer ]);

  return <>
    <ConfirmDialog
      text='Opravdu si přejete smazat tohoto přihazujícího?'
      yes='Ano, smazat'
      onClose={() => setShowDeleteConfirm(false)}
      open={showDeleteConfirm}
      onSubmit={deleteItem}/>
    { isMobile
      ? <>
        <IconButton href={`/administrace/prihazujici/${value.uuid}`}><Edit/></IconButton>
        <IconButton onClick={() => setShowDeleteConfirm(true)}><Delete/></IconButton>
      </>
      : <>
        <Tooltip title="Akce">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon/>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx:        {
              overflow:            'visible',
              filter:              'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt:                  1.5,
              '& .MuiAvatar-root': {
                width:  32,
                height: 32,
                ml:     -0.5,
                mr:     1,
              },
              '&:before': {
                content:   '""',
                display:   'block',
                position:  'absolute',
                top:       0,
                right:     14,
                width:     10,
                height:    10,
                bgcolor:   'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex:    0,
              },
            },
          }}
          transformOrigin={{
            horizontal: 'right', vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right', vertical: 'bottom',
          }}
        >
          <Link component={RouterLink} to={`/administrace/prihazujici/${value.uuid}`}>
            <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }}>
              Editovat
            </MenuItem>
          </Link>
          <MenuItem sx={{ color: 'rgba(0, 0, 0, 0.87) !important;' }} onClick={() => setShowDeleteConfirm(true)}>
            Smazat
          </MenuItem>
        </Menu>
      </>}
  </>;
};
export const CustomerActionProvider = (props: JSX.IntrinsicAttributes & DataTypeProviderProps & { children?: ReactNode; }) => (
  <DataTypeProvider
    formatterComponent={ActionFormatter}
    {...props}
  />
);