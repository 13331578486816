import { Alert, LoadingButton } from '@mui/lab';
import {
  Container, Link,
  Stack, TextField, Typography, useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink } from 'react-router-dom';

import useAPI, { Customer, Nullable } from '../../Hooks/useAPI';
import { Telephone } from '../../InputMask/Telephone';
import {
  errorAttributes,
  isEmail,
  isTelephoneNumber,
  minLength, required, ToSAgreed,
} from '../../InputValidator';
import ObchodniPodminkyDialog from '../../ObchodniPodminky/ObchodniPodminkyDialog';
import Klokan from './Klokan.jpg';
import { useKeycloak } from '@react-keycloak/web';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

let passwordTestValue = '';
const passwordCheck = (v?: string | number) => {
  return passwordTestValue === v || errorAttributes('Hesla se musí shodovat');
};
const rules = {
  firstName:   [required, minLength(3)],
  lastName:    [required, minLength(3)],
  email:       [required, isEmail],
  phoneNumber: [required, isTelephoneNumber],
  password:    [passwordCheck],
  ToS:         [ToSAgreed],
} as { [x in keyof FormData]: (typeof required)[]};

type FormData = Nullable<Partial<Customer & {
  'ToS': boolean;
}>>;

export default function PagesResistraceUcastnikaAukce() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { registerCustomer } = useAPI();
  const { keycloak } = useKeycloak();

  const [ error, setError ] = React.useState<null | string>(null);
  const [ captcha, setCaptcha ] = React.useState('');
  const [ data, setData ] = React.useState<FormData>({
    firstName:   null,
    lastName:    null,
    email:       null,
    phoneNumber: null,
    password:    null,
    ToS:         null,
  });
  const [ loading, setLoading ] = React.useState(false);

  const onInputChange = <K extends keyof FormData>(field: K, value: FormData[K]) => setData((d) => ({
    ...d, [field]: value,
  }));

  const validateField = React.useCallback((field: keyof FormData): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    const value = data[field];
    if (value === null) {
      return {}; // untouched
    } else {
      for (const rule of rules[field] ?? []) {
        const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
        if (result === true) {
          continue;
        }
        return result;
      }
    }

    return {};
  }, [ data ]);

  const isFormValid = React.useCallback(() => {
    for (const [field, validators] of Object.entries(rules)) {
      const value = data[field as keyof FormData];
      if (value === null) {
        continue; // skip non dirty
      }
      for (const rule of validators) {
        const result = rule(typeof value === 'string' ? value : JSON.stringify(value));
        if (result === true) {
          continue;
        }
        console.error(`Rule for ${field} failed with value ${value}`);
        return false;
      }
    }
    return true;
  }, [ data ]);

  const dirtifyAllInputs = React.useCallback(() => {
    setData(v => {
      for (const [attr, value] of Object.entries(v)) {
        if (value === null) {
          if (attr === 'ToS') {
            v.ToS = false;
          } else {
            (v[attr as Exclude<keyof FormData, 'ToS'>] as any) = '';
          }
        }
      }
      return { ...v };
    });
  }, []);

  const registerUser = React.useCallback(() => {
    // dirtify
    dirtifyAllInputs();

    // recheck validation
    if (isFormValid()) {
      setLoading(true);

      registerCustomer(captcha, data as Customer)
        .then(() => {
          history.pushState({}, '', new URL(window.location.href));
          keycloak.login({ redirectUri: window.location.origin });
          setError(null);
        })
        .catch(e => {
          setError(e.response.data.error);
        })
        .finally(() => setLoading(false));
    }
  }, [ isFormValid, data, dirtifyAllInputs, registerCustomer, captcha ]);

  return (<Container sx={{
    p: '30px !important', mt: 1,
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Registrace účastníka aukce - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1">Registrace <strong>účastníka</strong> aukce.</Typography>
    <Typography sx={{ py: 3 }}>
        Pokud jste realitka, developer nebo obec, registrujte se prosím{' '}
      <Link component={RouterLink} to='/registrace-realitky-developer-obec'>
          ZDE
      </Link>.
    </Typography>

    {error && <Alert severity='warning' sx={{ mb: 2 }}>
      { error === 'captcha'
        ? <Typography>Chyba v reCAPTCHA, prosím zkuste znovu.</Typography>
        : <Typography>Email byl již zaregistrován. Prosím
          <Typography component='span' sx={{
            fontWeight: 700, color: '#e62f0f !important', cursor: 'pointer',
          }} onClick={() => {
            history.pushState({}, '', new URL(window.location.href));
            keycloak.login();
          }} > přihlaste se</Typography>.
        </Typography>
      }
    </Alert>}

    <Stack spacing={1} component='form'>
      <TextField
        variant='filled'
        required
        fullWidth
        label="Jméno"
        {...validateField('firstName')}
        onChange={(ev) => onInputChange('firstName', ev.currentTarget.value)}
        autoComplete='given-name'
      />

      <TextField
        variant='filled'
        required
        fullWidth
        label="Příjmení"
        {...validateField('lastName')}
        onChange={(ev) => onInputChange('lastName', ev.currentTarget.value)}
        autoComplete='family-name'
      />

      <TextField
        label="E-mail"
        variant='filled'
        required
        fullWidth
        type='email'
        autoComplete='email'
        {...validateField('email')}
        onChange={(ev) => onInputChange('email', ev.currentTarget.value)}
      />

      <TextField
        variant='filled'
        required
        value={data.phoneNumber || ''}
        fullWidth
        label='Telefon'
        type='tel'
        autoComplete='tel'
        {...validateField('phoneNumber')}
        onChange={(ev) => onInputChange('phoneNumber', ev.currentTarget.value)}
        InputProps={{ inputComponent: Telephone as any }}
      />

      <TextField
        label="Heslo"
        variant='filled'
        type='password'
        autoComplete="new-password"
        required
        fullWidth
        error={validateField('password').error}
        onChange={(ev) => onInputChange('password', ev.currentTarget.value)}
      />

      <TextField
        label="Heslo (znovu)"
        variant='filled'
        type='password'
        autoComplete="new-password"
        required
        fullWidth
        {...validateField('password')}
        onChange={(ev) => {
          passwordTestValue = ev.currentTarget.value ?? '';
          setData({ ...data });
        }}
      />

      <FormControl
        required
        {...validateField('ToS')}
        component="fieldset"
        sx={{
          m: 3, py: 3,
        }}
        variant="standard"
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={data.ToS ?? false} onChange={(_, checked) => onInputChange('ToS', checked)} name="ToS" />
            }
            label={<Typography>
              Souhlasím s <ObchodniPodminkyDialog>obchodními podmínkami</ObchodniPodminkyDialog> *
            </Typography>}
          />
        </FormGroup>
        { ('helperText' in (validateField('ToS') ?? {})) && <FormHelperText>{validateField('ToS')!.helperText}</FormHelperText>}
      </FormControl>
    </Stack>

    {process.env.REACT_APP_RECAPTCHA &&  <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA}
      onChange={(value: any) => setCaptcha(value ?? '')}
    />}

    <Box sx={{
      py: 2, textAlign: 'center',
    }}>
      <LoadingButton loading={loading} disabled={!isFormValid()} onClick={registerUser} variant='contained' sx={{ height: '50px' }}>Zaregistrovat se</LoadingButton>
    </Box>

    {!isMobile && <Box sx={{
      position: 'absolute', right: '15%', bottom: '235px', zIndex: -1,
    }}>
      <img width="250" src={Klokan} alt=""/>
    </Box>}
  </Container>
  );
}
