import * as React from 'react';
import { InputAttributes, NumericFormat } from 'react-number-format';

interface CustomProps {
  onChange: (event: { currentTarget: { name: string; value: string } }) => void;
  name: string;
}

export const Currency = React.forwardRef<
InputAttributes,
CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator=" "
      allowLeadingZeros={false}
      onValueChange={(values) => {
        onChange({
          currentTarget: {
            name:  props.name,
            value: values.value,
          },
        });
      }}/>
  );
});