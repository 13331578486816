import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useOnWindowResize } from 'rooks';
import useSupplies from '../Hooks/useSupplies';
import AuctionCard from '../AuctionCard/AuctionCard';
import { PaginationStyled } from '../PaginationStyled';
import CardMedia from '@mui/material/CardMedia';
import useAPI from '../Hooks/useAPI';

type Props = {
  filters?: string[];
};

const AllAuctionsList: React.FC<Props> = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [ page, setPage ] = React.useState(parseInt(query.get('stranka') || '1', 10));
  const { getSupplyMultimedia } = useAPI();

  const suppliesProps = React.useMemo(() => ({
    pageSize: 15,
    page:     page - 1,
    filters:  ['auction-status=2', ...(props.filters ?? [])], // running auction
  }), [page]);
  const { data: auctions, loading, total } = useSupplies(suppliesProps);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [ hover, setHover ] = React.useState('');
  const [ width, setWidth ] = React.useState(0);

  const [key, setKey] = React.useState(Date.now());
  useOnWindowResize(() => setKey(Date.now()));

  React.useEffect(() => {
    if (hover.length > 0) {
      const el = document.getElementById(`card-${hover}`);
      setWidth(el?.clientWidth ?? 0);
    }
  }, [ hover ]);

  return <Container key={key}>
    { !loading && auctions.length > 0 && <>
      <Typography variant="h2" component="h2">Právě probíhající aukce</Typography>
      {loading
        ? <Box sx={{
          height:       '420px',
          display:      'flex',
          alignItems:   'center',
          placeContent: 'center',
        }}>
          <CircularProgress/>
        </Box>
        : isTablet
          ? <Swiper
            spaceBetween={20}
            touchEventsTarget='container'
            loop={(isTablet && auctions.length > 3) || (isMobile && auctions.length > 1)}
            slidesPerView={3}
            slidesPerGroup={isMobile ? 1 : 3}
            modules={[Navigation]}
            centeredSlides={isMobile}
            navigation={!isMobile}
            style={{
              padding:       isMobile ? 'inherit' : '20px',
              paddingBottom: '40px',
              paddingTop:    '20px',
              margin:        isMobile ? undefined : '-20px',
              marginLeft:    isMobile ? '-55vw' : isTablet ? undefined : '-20px',
              width:         isMobile ? '200vw' : 'inherit',
            }}
          >
            {auctions.map((auction) =>
              <SwiperSlide key={auction.uuid} style={{ paddingTop: '30px' }}>
                <AuctionCard supply={auction} disableHover={isTablet}/>
              </SwiperSlide>
            )}
          </Swiper>
          : <>
            <Grid container spacing={2} sx={{ pt: 4 }}>
              { auctions.map((auction, idx) => (idx >= ((page - 1) * 15) && idx < ((page ) * 15)) && <Grid item xs={12/5} key={auction.uuid} sx={{ position: 'relative' }}>
                <Card variant="outlined" onMouseLeave={() => setHover('')} onMouseEnter={() => setHover(auction.uuid!)} id={`card-${auction.uuid}`}
                  sx={{
                    border:               0,
                    p:                    0,
                    m:                    0,
                    height:               '150px',
                    borderTopLeftRadius:  '35px',
                    borderTopRightRadius: '35px',
                    maxHeight:            '150px',
                    overflow:             'hidden',
                  }}
                >
                  <CardMedia
                    sx={{
                      borderBottomLeftRadius:  '35px',
                      borderBottomRightRadius: '35px',
                      maxHeight:               '150px',
                    }}
                    component="img"
                    image={
                      auction.previewMultimediaUuid && auction.previewMultimediaUuid.length > 0
                        ? getSupplyMultimedia(auction.uuid!, auction.previewMultimediaUuid)
                        : 'https://placehold.co/600x400?text=Obr%C3%A1zek+nenalezen'}
                    alt=""
                  />
                </Card>
                { hover === auction.uuid && <Box sx={{
                  position: 'absolute', width: `${width}px`, top: '14px', left: '14px',
                }} onMouseLeave={() => setHover('')} onMouseEnter={() => setHover(auction.uuid!)}>
                  <AuctionCard supply={auction} disableHover hideRemainingTime/>
                </Box>
                }
              </Grid>)}
            </Grid>
            <PaginationStyled page={page} setPage={setPage} paginator={Math.ceil(total / 15)}/>
          </>
      }

      <Box sx={{
        textAlign: 'center', pb: 2,
      }}>
        <Link component={RouterLink} to="/vsechny-nabidky">
          <Button color='secondary' variant='contained' sx={{
            height: '50px', width: '150px', borderRadius: '90px !important',
          }}>Všechny nabídky</Button>
        </Link>
      </Box>
    </>}
  </Container>;
};

export default AllAuctionsList;