import {
  Action, configureStore, ThunkAction,
} from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const makeStore = () =>
  configureStore({
    reducer:  { [apiSlice.name]: apiSlice.reducer },
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
AppState,
unknown,
Action
>;

export default makeStore;