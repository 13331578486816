import {
  AppBar, Box, Divider, IconButton, Link, Menu, MenuItem, MenuList, Toolbar, Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { useCallback } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';

import Logo from './Logo.svg';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import useAPI from '../Hooks/useAPI';

export default function TopBarMobile() {
  const { keycloak } = useKeycloak();
  const { PasswordChange, getOrganization } = useAPI();

  const triggerChange = useCallback(async () => {
    PasswordChange();
  }, [PasswordChange]);

  const [ partnerId, setPartnerId ] = React.useState<null | string>(null);

  React.useEffect(() => {
    if (keycloak.authenticated && keycloak.hasResourceRole('showOrganizationOperator')) {
      getOrganization().then(data => setPartnerId(data.uuid!));
    }
  }, [ keycloak.authenticated ]);

  return <AppBar position="sticky" color='light' sx={{
    boxShadow: 'none', borderBottom: '1px solid #e8ede5',
  }}>
    <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
      <Link component={NavLink} to="/">
        <img width="100" src={Logo} alt="INBIDO logo"/>
      </Link>
      <Box sx={{ textAlign: 'right' }}>
        {keycloak.authenticated && <Typography component='span' sx={{
          fontFamily: 'Montserrat', fontWeight: '600', pr: '2.5vw', fontSize: '14px !important',
        }}>Vítejte, {(keycloak.idTokenParsed as any)?.name}</Typography>}
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton color="primary" aria-label="menu" {...bindTrigger(popupState)}>
                <MenuIcon/>
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuList dense>
                  {!keycloak.authenticated
                    ? [
                      <MenuItem key='menu-item-login' onClick={() => {
                        history.pushState({}, '', new URL(window.location.href));
                        keycloak.login();
                      }} >Přihlášení</MenuItem>,
                      <MenuItem key='menu-item-reg' component={NavLink} to="/registrace-ucastnika-aukce" onClick={popupState.close}>Registrace</MenuItem>,
                    ]
                    : [
                      keycloak.hasResourceRole('showCustomer') && <MenuItem key='menu-item-change' component={NavLink} to="/zmena-udaju" onClick={popupState.close}>Změna údajů</MenuItem>,
                      keycloak.hasResourceRole('showOrganizationOperator') && [
                        <MenuItem key='menu-item-aukce' component={NavLink} to="/administrace/aukce" onClick={popupState.close}>Aukce</MenuItem>,
                        <MenuItem key='menu-item-pwd' onClick={triggerChange}>Změna hesla</MenuItem>,
                        <MenuItem key='menu-item-pri' component={NavLink} to={`/administrace/zadavatele/${partnerId}/preview`} onClick={popupState.close}>Externí náhled</MenuItem>,
                      ],
                      keycloak.hasResourceRole('showAdministrator') && [
                        <MenuItem key='menu-item-aukce' component={NavLink} to="/administrace/aukce" onClick={popupState.close}>Aukce</MenuItem>,
                        <MenuItem key='menu-item-zad' component={NavLink} to="/administrace/zadavatele" onClick={popupState.close}>Zadavatelé</MenuItem>,
                        <MenuItem key='menu-item-pri' component={NavLink} to="/administrace/prihazujici" onClick={popupState.close}>Přihazující</MenuItem>,
                      ],
                      <MenuItem key='menu-item-logout' onClick={() => keycloak.logout()}>Odhlásit</MenuItem>,
                    ]}
                  <Divider/>
                  <MenuItem component={NavLink} to="/chci-aukci" onClick={popupState.close}>Chci aukci</MenuItem>
                  <MenuItem component={NavLink} to="/chci-koupit" onClick={popupState.close}>Chci koupit</MenuItem>
                  <MenuItem component={NavLink} to="/chci-najem" onClick={popupState.close}>Chci nájem</MenuItem>
                  <MenuItem component={NavLink} to="/zadavatele" onClick={popupState.close}>Zadavatelé</MenuItem>
                  <MenuItem component={NavLink} to="/vsechny-insolvencni-aukce" onClick={popupState.close}>Insolvenční a průmyslové aukce</MenuItem>
                  <MenuItem component={NavLink} to="/doplnkove-sluzby" onClick={popupState.close}>Doplňkové služby</MenuItem>
                  <Divider/>
                  <MenuItem component={NavLink} to="/o-nas" onClick={popupState.close}>O nás</MenuItem>
                  <MenuItem component={NavLink} to="https://blog.inbido.cz/" onClick={popupState.close}>Blog</MenuItem>
                  <MenuItem component={NavLink} to="/kontakty" onClick={popupState.close}>Kontakty</MenuItem>
                  <MenuItem component={NavLink} to="/caste-dotazy" onClick={popupState.close}>Časté dotazy</MenuItem>
                  <MenuItem component={NavLink} to="/obchodni-podminky" onClick={popupState.close}>Obchodní podmínky</MenuItem>

                </MenuList>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Box>
    </Toolbar>
  </AppBar>;
}