import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

import Logo from '../Pages/Index/WhyAuctionsBullets/Icon.png';
import React from 'react';

export default function FooterMobile() {
  return ( <AppBar position="static" color="light" sx={{
    boxShadow: 'none', borderTop: '1px solid #e8ede5', mt: '10px',
  }}>
    <Toolbar sx={{
      justifyContent: 'space-between', alignItems: 'center',
    }}>
      <Box sx={{
        width: '100%', textAlign: 'left',
      }}>
        <Typography><Link href='mailto:info@inbido.cz' component='a' sx={{
          color: 'black', fontWeight: 'normal !important',
        }}>info@inbido.cz</Link></Typography>
      </Box>
      <Link href="/">
        <Box sx={{
          width: '80px', textAlign: 'center', position: 'relative', top: '4px',
        }}>
          <img width="40px" src={Logo} alt="INBIDO logo"/>
        </Box>
      </Link>
      <Box sx={{
        width: '100%', textAlign: 'right',
      }}>
        <Typography><Link href='tel:+420 777 799 645' component='a' sx={{
          color: 'black', fontWeight: 'normal !important',
        }}>+420 777 799 645</Link></Typography>
      </Box>
    </Toolbar>
  </AppBar>);
}