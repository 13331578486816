import {
  Box,
  Card, CardActionArea,
  CardMedia,
  LinearProgress,
  Link, Typography,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import Chip from '../Chips/Chip';
import useAPI, { OrganizationLite } from '../Hooks/useAPI';
import styles from './PartnerCard.module.css';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import useSupplies from '../Hooks/useSupplies';

const slugify = (str?: string ) =>
  String(str)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const PartnerCard: React.FC<{
  partner: OrganizationLite,
  chip?: boolean,
  minHeight?: number,
  imageGutter?: boolean,
}> = ({ partner, chip, minHeight, imageGutter }) => {
  const { getOrganizationMultimedia } = useAPI();

  const suppliesProps = React.useMemo(() => ({
    page:     1,
    pageSize: 0,
    filters:  [
      `organization=${partner.uuid!}`,
    ],
  }), [ partner.uuid ]);
  const { total, loading } = useSupplies(suppliesProps);

  return <Card variant="outlined" sx={{
    '&:hover':       { borderColor: '#d6d3dc' },
    border:          imageGutter ? '4px solid #dfdcdc' : '0',
    borderRadius:    '35px',
    backgroundColor: '#dfdcdc',
    zIndex:          2,
    textAlign:       'center',
    height:          '100%',
    transition:      '200ms all',
    minHeight:       `${minHeight}px` ?? 'inherit',
  }}>
    <Link component={RouterLink} to={`/detail-partnera/${partner.uuid}.${slugify(partner.title)}`}>
      <CardActionArea sx={{
        paddingBottom: '55px',
        height:        '100%',
        position:      'relative',
      }}>
        <Box sx={{
          backgroundColor:         partner.logoMultimediaUuid ? '#ffffff' : '#dfdcdc',
          borderBottomLeftRadius:  '35px',
          borderBottomRightRadius: '35px',
          height:                  '112px',
          position:                'absolute',
          display:                 'flex',
          padding:                 '5px',
          top:                     0,
          width:                   '100%',
        }}>
          <CardMedia
            component="img"
            className={styles.image}
            image={partner.logoMultimediaUuid && partner.logoMultimediaUuid.length > 0
              ? getOrganizationMultimedia(partner.uuid!, partner.logoMultimediaUuid)
              : 'https://placehold.co/600x400?text=Obr%C3%A1zek+nenalezen'}
            alt=""
          />
        </Box>

        <Typography sx={{
          p: 1, pt: 0,
        }} className={styles.name}>{ partner.title}</Typography>

        {chip && <Box sx={{
          pb: 0, position: 'relative', top: '-20px',
        }}>
          <Chip label={partner.form.label}/>
        </Box>}

        <Box className={styles.bottom}>
          <Typography sx={{
            px: 1, pt: 0,
          }} className={styles.offerCountText}>Celkem v nabídce</Typography>

          <Typography sx={{
            p: 1, pt: 0,
          }} className={styles.offerCount}>
            { loading ? <LinearProgress sx={{
              width: '10%', margin: 'auto', my: '7px',
            }}/> : total }
          </Typography>
        </Box>
      </CardActionArea>
    </Link>
  </Card>;
};

export default PartnerCard;