import {
  isEmail, isTelephoneNumber, isURLOptional, maxLength, minLength, required,
} from '../InputValidator';
import useAPI, { Customer, Organization } from './useAPI';
import React from 'react';
import useOrganizationForms from './useOrganizationForms';
import { cloneDeep } from 'lodash';

const rules = {
  description:    [minLength(0), maxLength(500)],
  email:          [required, isEmail],
  companyVatCode: [required],
  companyCode:    [required],
  name:           [required, minLength(3)],
  title:          [required, minLength(3)],
  phoneNumber:    [required, isTelephoneNumber],
  url:            [isURLOptional],
  zipCode:        [required, minLength(1)],
  cityCode:       [required, minLength(1)],
  stateCode:      [required, minLength(1)],
  routeCode:      [required, minLength(1)],
  streetCode:     [required, minLength(1)],
  provinceCode:   [required, minLength(1)],
  operators:      [minLength(1)],
} as { [x in keyof (Organization)]: (typeof required)[]};

const useOrganization = (organizationId: string | undefined) => {
  const { getOrganization, updateOrganization, createOrganization } = useAPI();

  const forms = useOrganizationForms();

  const [ data, setOrganization ] = React.useState<Organization | null>(null);
  const [ loaded, setLoaded ] = React.useState<boolean | null>(false);
  const [ dirty, setDirty ] = React.useState<string[]>([]);

  const dataRef = React.useRef(data);
  React.useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const update = <K extends keyof Organization>(field: K, value: Organization[K]) => {
    setDirty(v => Array.from(new Set([...v, field])));
    setOrganization((d) => d ? ({
      ...d, [field]: value,
    }) : null);
  };

  React.useEffect(() => {
    if (!organizationId || forms.length == 0) {
      return;
    }

    if (organizationId === 'create') {
      setOrganization({
        auctionCount:       0,
        cityCode:           '',
        companyCode:        '',
        companyVatCode:     '',
        createTimestamp:    '',
        description:        '',
        email:              '',
        form:               forms[0],
        latitude:           0,
        logoMultimediaUuid: '',
        longitude:          0,
        name:               '',
        operators:          [],
        phoneNumber:        '',
        provinceCode:       '',
        routeCode:          '',
        stateCode:          '',
        streetCode:         '',
        title:              '',
        updateTimestamp:    '',
        url:                '',
        zipCode:            '',
      });
      setLoaded(true);
    } else {
      getOrganization(organizationId)
        .then(v => {
          setOrganization(v);
          setLoaded(true);
        })
        .catch(() => setLoaded(null));
    }
  }, [organizationId, forms]);

  const validate = React.useCallback((field: keyof Organization, all = false): Record<string, never> | {
    error: boolean;
    helperText: string;
  } => {
    if ((!all &&!dirty.includes(field)) || !data) {
      return {}; // untouched
    }
    const value = data[field];
    for (const rule of rules[field] ?? []) {
      const result = rule(typeof value === 'string' || Array.isArray(value) ? value : JSON.stringify(value));
      if (result === true) {
        continue;
      }
      return result;
    }

    return {};
  }, [ dirty, data ]);

  const isValid = Object.keys(data ?? {}).map(field => {
    return validate(field as keyof Organization).error ?? false;
  }).filter(value => value).length === 0;

  const dirtifyAndValidate = () => Object.keys(rules ?? {}).map(field => {
    setDirty(v => Array.from(new Set([...v, field])));
    return validate(field as keyof Organization, true).error ?? false;
  }).filter(value => value).length === 0;

  const save = React.useCallback(() => {
    return new Promise<Required<Organization>>((resolve, reject) => {
      if (!dataRef.current) {
        return reject();
      }
      if (organizationId === 'create' || !organizationId) {
        createOrganization(dataRef.current!)
          .then((id) => {
            setOrganization((val) => {
              if (!val) {
                reject();
                return null;
              }

              const newVal = {
                ...val,
                uuid: id,
              };
              resolve(newVal as Required<Organization>);
              return newVal;
            });
          });
      } else {
        updateOrganization(organizationId, dataRef.current)
          .then(() => {
            resolve(dataRef.current as Required<Organization>);
          });
      }
    });
  }, [ data, organizationId ]);

  const updateImage = (logoMultimediaUuid: string) => {
    return new Promise<Required<Organization>>((resolve, reject) => {
      console.log('updateImage', dataRef.current, dataRef.current?.uuid);
      if (!dataRef.current || !dataRef.current.uuid) {
        return reject('Unknown organization ID');
      }
      updateOrganization(dataRef.current.uuid!, { logoMultimediaUuid })
        .then(() => {
          setOrganization(val => val ? {
            ...val,  logoMultimediaUuid,
          } : null);
          resolve(dataRef.current as Required<Organization>);
        });
    });
  };

  const assignOperator = (customer: Customer) => {
    if (data && data.operators) {
      const operators = cloneDeep(data.operators);

      // check if operator already exists by uuid or by email
      if (operators.find(o => o.uuid === customer.uuid || o.email === customer.email)) {
        // replace operator with new data
        operators.splice(operators.findIndex(o => o.uuid === customer.uuid || o.email === customer.email), 1, customer);
        update('operators', operators);
      } else {
        // add new operator
        update('operators', [...data.operators, customer]);
      }
    } else {
      update('operators', [customer]);
    }
  };

  // removing by email, because uuid might not be available and email should be unique
  const removeOperatorByEmail = (operatorEmail: string) => {
    if (data && data.operators) {
      update('operators', data.operators.filter(o => o.email !== operatorEmail));
    }
  };

  return {
    data, loaded, update, validate, isValid, dirtifyAndValidate, save, updateImage, assignOperator, removeOperatorByEmail,
  };
};

export default useOrganization;
