import {
  Button, Container, Link, Typography, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Header.module.css';
import Klokan from './Klokan.jpg';
import React from 'react';
import theme from '../../../theme';

export default function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container sx={{
      marginTop:     isMobile ? 0 : 1,
      paddingBottom: 1,
    }}>
      <Grid container spacing={0} justifyContent='space-between' sx={{ pt: '30px' }}>
        <Grid item sm={7} xs={12} sx={{ zIndex: 2 }}>
          <Typography variant="h1" component="h1" className={styles.header}>Vyhrajte v <span>aukci</span> své vysněné
            {' '}
            {isMobile && <br/>}
            bydlení nebo podíl na nemovitosti!</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent='space-between' >
        <Grid item sm={5.6} xs={6} zIndex={2} sx={{
          textAlign: 'justify', zIndex: 2,
        }}>
          <Typography variant="body1" sx={{ pt: isMobile ? '20px' : '40px' }}>Hledáte ke koupi svou vysněnou nemovitost nebo jste spíše fanoušek podílů? Pro vás je náš aukční portál jako stvořený. <strong>Registrujte se</strong> a přihazujte ještě dnes!</Typography>

          <Link component={RouterLink} to={`/registrace-ucastnika-aukce`}>
            <Button variant="contained" color="secondary" sx={{
              width: '125px', height: isMobile ? '30px' : '40px',  mt: isMobile ? 3 : 5,
            }}>Registrace</Button>
          </Link>
        </Grid>
        <Grid item sm={4} sx={{ position: 'relative' }}>
          <img src={Klokan} alt="" className={styles.klokan}/>
        </Grid>
      </Grid>
    </Container>);
}