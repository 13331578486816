import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container, Grid, Link, Stack, SxProps, Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './FAQ.module.css';
import Ikonka from './Ikonka.png';
import QuestionMark from './QuestionMark.png';
import theme from '../../../theme';

type Props = {
  hideQuestion?: boolean,
  noQuestionMarkIcon? : boolean,
  children?: ReactElement<any, any>,
  sx?: SxProps<Theme>,
  questions?: {
    q: string;
    a: (string | ReactElement<any, any>)[];
  }[]
};

export default function FAQ({ questions, children, sx, hideQuestion, noQuestionMarkIcon }: Props) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  questions ??= [
    {
      q: 'Může prodávající či pronajímatel zadat nemovitost přímo do aukce?',
      a: [<Typography>
        Vložení nemovitosti je možné pouze prostřednicvím některých z našich partnerských realitek.
        Stačí nás kontaktovat prostřednictvím <Link href='/chci-aukci#formular'>formuláře</Link> a rádi Vám kontakt
        zprostředkujeme.
      </Typography>],
    },
    {
      q: 'Jak lze prodávat či pronajímat nemovitost přes INBIDO?',
      a: ['Realitka, devoloper nebo obec může zadat nemovitosti do aukce buď sám vložením nemovitosti v rámci svého účtu nebo prostřednictvím týmu provozovatele INBIDO.'],
    },
    {
      q: 'Co vše lze zadat do aukce?',
      a: ['Do aukce lze zadat byty, domy, pozemky, bytové domy, developerské projekty, sklady, garáže, atd.'],
    },
    {
      q: 'Jak se registrovat a účastnit se aukce?',
      a: [
        <Typography>
          Zaregistrovat se na portálu prostřednictvím <Link href='/registrace-ucastnika-aukce'>formuláře</Link>, aktivovat svůj účet a přihlásit se do aukce.
          Účastnit se může každá osoba způsobilá k nákupu nemovitostí ve smyslu občanského zákoníku.
        </Typography>,
        'Nezbytnou podmínkou registrace je souhlas s obchodními podmínkami.',
      ],
    },
    {
      q: 'Jak vložit nemovitost do aukce?',
      a: [
        <Typography>
          Vložení nemovitosti je možné pouze prostřednicvím některých z našich partnerských realitek.
          Stačí nás kontaktovat prostřednictvím <Link href='/chci-aukci#formular'>formuláře</Link> a rádi Vám kontakt zprostředkujeme.
        </Typography>,
      ],
    },
  ];

  const handleChange
    = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Container sx={sx ?? { pt: '60px' }}>
      { children || <Typography sx={{ color: '#e02804 !important' }} variant="h2" component="h2">Časté dotazy</Typography> }

      <Grid container justifyContent='space-between'>
        <Grid item className={styles.list} xs={noQuestionMarkIcon || isTablet ? 12 : 7}>
          {questions.map((item, idx) => <Accordion
            key={`FAQ-${idx}`}
            disableGutters
            elevation={0}
            square
            expanded={expanded === item.q}
            onChange={handleChange(item.q)}
            sx={{
              '&:not(:last-child)': { borderBottom: 0 },
              '&:before':           { display: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.7rem' }} />}
              aria-controls={`${item.q}-content`}
              id={`${item.q}-header`}
              sx={{
                flexDirection:                                           'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper':              { marginRight: '15px' },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
                '& .MuiAccordionSummary-content':                        {
                  margin: '0 !important',paddingRight: isMobile ? '20px' : undefined,
                },
                minHeight: isMobile ? '30px !important' : undefined,

              }}
            >
              <Typography className={styles.text}>{item.q}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ 'p + p': { pt: 2 } }} className={styles.text}>
              {item.a.map((text, idxText) => typeof text === 'string'
                ? <Typography key={`FAQ-${text}-${idxText}`}>{text}</Typography>
                : <span key={`FAQ-${text}-${idxText}`}>{text}</span>)}
            </AccordionDetails>
          </Accordion>)}

          {!hideQuestion && <Stack direction='row' alignItems={'center'} className={styles.faqlink} alignSelf='center'>
            <img src={Ikonka} width={40} alt=""/>
            <Typography className={styles.muted}>
              Nenašli jste odpověď na Váš dotaz? Otevřít <Link component={RouterLink} to='/caste-dotazy'>Časté dotazy</Link>.
            </Typography>
          </Stack>}
        </Grid>
        {(!isTablet && !noQuestionMarkIcon) && <Grid item xs={5} sx={{
          textAlign: 'right', paddingRight: '100px',
        }}>
          <img src={QuestionMark} width={365} alt=""/>
        </Grid>}
      </Grid>
    </Container>);
}