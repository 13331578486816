import {
  Box, CircularProgress, Container, Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';

import useAPI, {
  OrganizationLite,
  Paginator,
} from '../../Hooks/useAPI';
import React from 'react';
import { PaginationStyled } from '../../PaginationStyled';
import Filters from './Filters/Filters';
import theme from '../../theme';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PartnerCard from '../../PartnerCard/PartnerCard';
import { prepareFilter } from '../../prepareFilter';

export default function List() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getOrganizations } = useAPI();

  const [ companies, setCompanies ] = useState<null | OrganizationLite[]>(null);

  const [ paginator /*, setPaginator */ ] = useState<null | Paginator>(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [ page, setPage ] = React.useState(parseInt(query.get('stranka') || '1', 10));

  const [ filter, setFilter ] = useState<Record<string, [] | string>>({});

  const handleFilterChange = (value: typeof filter) => {
    setFilter(value);
    // reset paging and remove search 'stranka' param
    setPage(1);
    window.history.pushState({ path: location.pathname }, '', location.pathname);
  };

  useEffect(() => {
    setCompanies(null);

    console.log({ filter });
    let form = [...(filter.form ?? []) as string[]];
    if (form.length === 0) {
      // by default we don't want insolvency type
      form = ['1', '2', '3'];
    }
    getOrganizations(15, page - 1, [
      ...prepareFilter({
        ...filter,
        'form': form,
      }),
    ])
      .then(data => {
        setCompanies(data.organizations);
      });
  }, [ page, filter ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
    px:            '30px',
  }}>
    <Helmet>
      <link rel='canonical' href={window.location.origin}/>
      <title>Zadavatelé - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h2" component="h2" sx={{ pb: 4 }}>Seznam zadavatelů</Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={3} sx={{ margin: 'auto' }}>
        <Filters onFilterChange={handleFilterChange}/>
      </Grid>
      <Grid item xs={12} sm={12}  md={12} lg={9} sx={{ margin: 'auto' }}>
        {!companies
          ? <Box sx={{
            height:       '420px',
            display:      'flex',
            alignItems:   'center',
            placeContent: 'center',
          }}>
            <CircularProgress/>
          </Box>
          : <Grid container spacing={3}>
            {
              companies.map(partner => <Grid item xs={12} sm={6} md={3} key={partner.uuid}>
                <PartnerCard partner={partner} chip minHeight={320} imageGutter/>
              </Grid>
              )
            }
          </Grid>
        }
      </Grid>
    </Grid>

    {paginator && <PaginationStyled page={page} setPage={setPage} paginator={paginator}/>}
  </Container>
  );
}
