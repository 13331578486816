import { Typography } from '@mui/material';
import { useCallback } from 'react';

// import useAPI from '../Hooks/useAPI';
import styles from './TopMenu.module.css';
import React from 'react';
import useAPI from '../Hooks/useAPI';

export default function PasswordButton() {
  const { PasswordChange } = useAPI();
  const triggerChange = useCallback(async () => {
    PasswordChange();
  }, [PasswordChange]);
  return <Typography className={`${styles.button}`} onClick={triggerChange}>Změna hesla</Typography>;
}