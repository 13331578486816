import getSymbolFromCurrency from 'currency-symbol-map';

export const parseCurrencyAmount = (value: number, currency: string) => {
  const formatter = new Intl.NumberFormat('cs', {
    style:                 'currency',
    currency,
    minimumFractionDigits: 0,
  });
  return formatter
    .format(value)
    .replace('PLN', getSymbolFromCurrency('PLN') ?? ''); // PLN is being shown
};