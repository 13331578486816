import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import React from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    dark: Palette['primary'];
    light: Palette['primary'];
    light2: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
    light: PaletteOptions['primary'];
    light2: PaletteOptions['primary'];
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    dark: true;
    light: true;
    light2: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
    light2: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    dark: true;
    light: true;
    light2: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    light: true;
  }
}

declare module '@mui/material/' {
  interface CheckboxPropsColorOverrides {
    light: true;
  }
}

const LinkBehavior = React.forwardRef<
HTMLAnchorElement,
Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// Create a theme instance.
const theme = createTheme({
  components: {
    MuiLink:       { defaultProps: { component: LinkBehavior } as LinkProps },
    MuiButtonBase: { defaultProps: { LinkComponent: LinkBehavior } },
    MuiIconButton: { defaultProps: { LinkComponent: LinkBehavior } },
    MuiButton:     { defaultProps: { disableElevation: true } },
    MuiMenuItem:   { defaultProps: { LinkComponent: LinkBehavior } },
    MuiTextField:  {
      defaultProps: {
        variant: 'standard', fullWidth: true, 
      }, 
    },
  },
  palette: {
    dark: {
      main:         'rgb(28,28,30)',
      contrastText: 'white',
    },
    light: {
      main:         'rgb(255,255,255)',
      contrastText: 'black',
    },
    light2: {
      main:         'rgb(200,200,200)',
      contrastText: 'black',
    },
    primary: {
      main:         '#e02804',
      contrastText: 'white',
    },
    secondary: {
      main:         '#30ab4b',
      contrastText: 'white',
    },
    error: { main: red.A400 },
  },
  typography: { fontFamily: 'Montserrat' },
});

export default theme;
