import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { CustomerLite } from '../Hooks/useAPI';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import { Delete, Edit } from '@mui/icons-material';
import Box from '@mui/material/Box';

export const ActionFormatter = (props: {
  value: CustomerLite,
  removeOperatorByEmail: (memberEmail: string) => void,
  editOperatorByEmail: (memberEmail: string) => void,
}) => {
  const { value, removeOperatorByEmail, editOperatorByEmail } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    { isMobile
      ? <>
        <IconButton onClick={() => editOperatorByEmail(value.email)} ><Edit/></IconButton>
        <IconButton onClick={() => removeOperatorByEmail(value.email)} color='error'>
          <Delete/>
        </IconButton>
      </>
      : <Box sx={{ textAlign: 'right' }}>
        <Tooltip title="Editace člena">
          <IconButton onClick={() => editOperatorByEmail(value.email)} ><Edit/></IconButton>
        </Tooltip>

        <Tooltip title="Smazat člena">
          <IconButton onClick={() => removeOperatorByEmail(value.email)} color='error'>
            <Delete/>
          </IconButton>
        </Tooltip>
      </Box>}
  </>;
};