import React from 'react';
import FAQ from '../Index/FAQ/FAQ';
import AuctionCount from './AuctionCount/AuctionCount';
import Header from './Header/Header';
import JakNaTo from './JakNaTo/JakNaTo';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';
import { Helmet } from 'react-helmet';

export default function PagesChciAukci() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (<>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Chci aukci - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Header/>
    <JakNaTo/>
    {!isMobile && <AuctionCount/>}
    <FAQ sx={{ pt: 2 }}/>
  </>
  );
}
